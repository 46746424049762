import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AffiliateLink from './affiliateLink.js'

const CompareFunctionality = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnfamily
          vpnaff
          vpnid
          functionalityscore
          splittunnelingwindows
          splittunnelingmac
          splittunnelingandroid
          userinterface
          onboarding
          servers
          splittunnelingios
          netflixscore
          bbciplayer
          countries
          connections
          
          extras
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getfunctionalityscore(aff) {
    switch (aff) {
      case "pia": return pia.functionalityscore
      case "ivacy": return ivacy.functionalityscore
      case "namecheap": return namecheap.functionalityscore
      case "vypr": return vypr.functionalityscore
      case "pure": return pure.functionalityscore
      case "nord": return nord.functionalityscore
      case "surfshark": return surfshark.functionalityscore
      case "cyberghost": return cyberghost.functionalityscore
      case "windscribe": return windscribe.functionalityscore
      case "torguard": return torguard.functionalityscore
      case "mullvad": return mullvad.functionalityscore
      case "express": return express.functionalityscore
      case "proton": return proton.functionalityscore
      default: return pia.functionalityscore
    }
  }

  function getsplittunnelingwindows(aff) {
    switch (aff) {
      case "pia": return pia.splittunnelingwindows
      case "ivacy": return ivacy.splittunnelingwindows
      case "namecheap": return namecheap.splittunnelingwindows
      case "vypr": return vypr.splittunnelingwindows
      case "pure": return pure.splittunnelingwindows
      case "nord": return nord.splittunnelingwindows
      case "surfshark": return surfshark.splittunnelingwindows
      case "cyberghost": return cyberghost.splittunnelingwindows
      case "windscribe": return windscribe.splittunnelingwindows
      case "torguard": return torguard.splittunnelingwindows
      case "mullvad": return mullvad.splittunnelingwindows
      case "express": return express.splittunnelingwindows
      case "proton": return proton.splittunnelingwindows
      default: return pia.splittunnelingwindows
    }
  }

  function getsplittunnelingmac(aff) {
    switch (aff) {
      case "pia": return pia.splittunnelingmac
      case "ivacy": return ivacy.splittunnelingmac
      case "namecheap": return namecheap.splittunnelingmac
      case "vypr": return vypr.splittunnelingmac
      case "pure": return pure.splittunnelingmac
      case "nord": return nord.splittunnelingmac
      case "surfshark": return surfshark.splittunnelingmac
      case "cyberghost": return cyberghost.splittunnelingmac
      case "windscribe": return windscribe.splittunnelingmac
      case "torguard": return torguard.splittunnelingmac
      case "mullvad": return mullvad.splittunnelingmac
      case "express": return express.splittunnelingmac
      case "proton": return proton.splittunnelingmac
      default: return pia.splittunnelingmac
    }
  }

  function getsplittunnelingandroid(aff) {
    switch (aff) {
      case "pia": return pia.splittunnelingandroid
      case "ivacy": return ivacy.splittunnelingandroid
      case "namecheap": return namecheap.splittunnelingandroid
      case "vypr": return vypr.splittunnelingandroid
      case "pure": return pure.splittunnelingandroid
      case "nord": return nord.splittunnelingandroid
      case "surfshark": return surfshark.splittunnelingandroid
      case "cyberghost": return cyberghost.splittunnelingandroid
      case "windscribe": return windscribe.splittunnelingandroid
      case "torguard": return torguard.splittunnelingandroid
      case "mullvad": return mullvad.splittunnelingandroid
      case "express": return express.splittunnelingandroid
      case "proton": return proton.splittunnelingandroid
      default: return pia.splittunnelingandroid
    }
  }

  function getuserinterface(aff) {
    switch (aff) {
      case "pia": return pia.userinterface
      case "ivacy": return ivacy.userinterface
      case "namecheap": return namecheap.userinterface
      case "vypr": return vypr.userinterface
      case "pure": return pure.userinterface
      case "nord": return nord.userinterface
      case "surfshark": return surfshark.userinterface
      case "cyberghost": return cyberghost.userinterface
      case "windscribe": return windscribe.userinterface
      case "torguard": return torguard.userinterface
      case "mullvad": return mullvad.userinterface
      case "express": return express.userinterface
      case "proton": return proton.userinterface
      default: return pia.userinterface
    }
  }

  function getonboarding(aff) {
    switch (aff) {
      case "pia": return pia.onboarding
      case "ivacy": return ivacy.onboarding
      case "namecheap": return namecheap.onboarding
      case "vypr": return vypr.onboarding
      case "pure": return pure.onboarding
      case "nord": return nord.onboarding
      case "surfshark": return surfshark.onboarding
      case "cyberghost": return cyberghost.onboarding
      case "windscribe": return windscribe.onboarding
      case "torguard": return torguard.onboarding
      case "mullvad": return mullvad.onboarding
      case "express": return express.onboarding
      case "proton": return proton.onboarding
      default: return pia.onboarding
    }
  }

  function getservers(aff) {
    switch (aff) {
      case "pia": return pia.servers
      case "ivacy": return ivacy.servers
      case "namecheap": return namecheap.servers
      case "vypr": return vypr.servers
      case "pure": return pure.servers
      case "nord": return nord.servers
      case "surfshark": return surfshark.servers
      case "cyberghost": return cyberghost.servers
      case "windscribe": return windscribe.servers
      case "torguard": return torguard.servers
      case "mullvad": return mullvad.servers
      case "express": return express.servers
      case "proton": return proton.servers
      default: return pia.servers
    }
  }

  function getsplittunnelingios(aff) {
    switch (aff) {
      case "pia": return pia.splittunnelingios
      case "ivacy": return ivacy.splittunnelingios
      case "namecheap": return namecheap.splittunnelingios
      case "vypr": return vypr.splittunnelingios
      case "pure": return pure.splittunnelingios
      case "nord": return nord.splittunnelingios
      case "surfshark": return surfshark.splittunnelingios
      case "cyberghost": return cyberghost.splittunnelingios
      case "windscribe": return windscribe.splittunnelingios
      case "torguard": return torguard.splittunnelingios
      case "mullvad": return mullvad.splittunnelingios
      case "express": return express.splittunnelingios
      case "proton": return proton.splittunnelingios
      default: return pia.splittunnelingios
    }
  }

  function getnetflixscore(aff) {
    switch (aff) {
      case "pia": return pia.netflixscore
      case "ivacy": return ivacy.netflixscore
      case "namecheap": return namecheap.netflixscore
      case "vypr": return vypr.netflixscore
      case "pure": return pure.netflixscore
      case "nord": return nord.netflixscore
      case "surfshark": return surfshark.netflixscore
      case "cyberghost": return cyberghost.netflixscore
      case "windscribe": return windscribe.netflixscore
      case "torguard": return torguard.netflixscore
      case "mullvad": return mullvad.netflixscore
      case "express": return express.netflixscore
      case "proton": return proton.netflixscore
      default: return pia.netflixscore
    }
  }

  function getbbciplayer(aff) {
    switch (aff) {
      case "pia": return pia.bbciplayer
      case "ivacy": return ivacy.bbciplayer
      case "namecheap": return namecheap.bbciplayer
      case "vypr": return vypr.bbciplayer
      case "pure": return pure.bbciplayer
      case "nord": return nord.bbciplayer
      case "surfshark": return surfshark.bbciplayer
      case "cyberghost": return cyberghost.bbciplayer
      case "windscribe": return windscribe.bbciplayer
      case "torguard": return torguard.bbciplayer
      case "mullvad": return mullvad.bbciplayer
      case "express": return express.bbciplayer
      case "proton": return proton.bbciplayer
      default: return pia.bbciplayer
    }
  }

  function getcountries(aff) {
    switch (aff) {
      case "pia": return pia.countries
      case "ivacy": return ivacy.countries
      case "namecheap": return namecheap.countries
      case "vypr": return vypr.countries
      case "pure": return pure.countries
      case "nord": return nord.countries
      case "surfshark": return surfshark.countries
      case "cyberghost": return cyberghost.countries
      case "windscribe": return windscribe.countries
      case "torguard": return torguard.countries
      case "mullvad": return mullvad.countries
      case "express": return express.countries
      case "proton": return proton.countries
      default: return pia.countries
    }
  }

  function getconnections(aff) {
    switch (aff) {
      case "pia": return pia.connections
      case "ivacy": return ivacy.connections
      case "namecheap": return namecheap.connections
      case "vypr": return vypr.connections
      case "pure": return pure.connections
      case "nord": return nord.connections
      case "surfshark": return surfshark.connections
      case "cyberghost": return cyberghost.connections
      case "windscribe": return windscribe.connections
      case "torguard": return torguard.connections
      case "mullvad": return mullvad.connections
      case "express": return express.connections
      case "proton": return proton.connections
      default: return pia.connections
    }
  }

  function getextras(aff) {
    switch (aff) {
      case "pia": return pia.extras
      case "ivacy": return ivacy.extras
      case "namecheap": return namecheap.extras
      case "vypr": return vypr.extras
      case "pure": return pure.extras
      case "nord": return nord.extras
      case "surfshark": return surfshark.extras
      case "cyberghost": return cyberghost.extras
      case "windscribe": return windscribe.extras
      case "torguard": return torguard.extras
      case "mullvad": return mullvad.extras
      case "express": return express.extras
      case "proton": return proton.extras
      default: return pia.extras
    }
  }

  function getColors(result) {
    switch (result) {
      case 'Success': return `green4`;
      case 'Pass': return `green4`;
      case 'Yes': return `green4`;
      case 'Fail': return `red4`;
      case 'No': return `red4`;
      case 'No access': return `blue4`;
      default: return `indigo4`;
    }
  }

  function getScoreInWords(score) {
    switch (true) {
      case (score >= 0.0 && score < 4.0): return `Poor`;
      case (score >= 4.0 && score < 6.0): return `Average`;
      case (score >= 6.0 && score < 7.0): return `Good`;
      case (score >= 7.0 && score < 8.0): return `Very Good`;
      case (score >= 8.0 && score < 9.0): return `Great`;
      case (score >= 9.0 && score < 10.1): return `Excellent`;
      default: return `Perfect`;
    }
  }

  function getUnlimited(number) {
    if (number === 15) {
      return "Unlimited"
    } else {
      return number
    }
  }

  const colors = ["red4", "orange4", "purple4", "indigo4", "blue4"]

  const vpn = {
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
  }

  function scoreTimesTen(score) {
    return score * 10;
  }

  function getWinner(first, first_score, second, second_score) {
    if (first_score > second_score) {
      return displayWinner(first)
    } else {
      return displayWinner(second)
    }
  }

  function displayWinner(vpnaff) {
    return (
      <AffiliateLink type="naked" affiliate={vpnaff}>
        <div class="great v-mid w-100" styles="min-width:50%;">
          <p class="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">Winner</p>
          <p class="mv0 avenir fw6 f4 tc white pb3">{getName(vpnaff)}</p>
        </div>
      </AffiliateLink>
    )
  }

  return (
    <>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class={`bg-${colors[2]} br0 br--top primary0 mv0 pa3 f5 ttu tracked`}>{vpn.criteria[2]} Comparison</h4>

        <div className="bt b--gray2">
          <div className="flex flex-column flex-row-ns">
            <div className="pa3 w-100 w-50-ns pr3-ns">
              <h5 className={"f5 mv0 " + colors[2]}>{vpn.criteria[2]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[2]}>({getfunctionalityscore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[2]} style={{ width: scoreTimesTen(getfunctionalityscore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[2]}>({getfunctionalityscore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[2]} style={{ width: scoreTimesTen(getfunctionalityscore(props.second)) + "%" }}></div>
                </div>
              </div>
            </div>
            <div class="v-mid pa3-ns pa4-l order-1 order-2-ns mb0 w-100 w-50-ns">
              {getWinner(props.first, getfunctionalityscore(props.first), props.second, getfunctionalityscore(props.second))}
            </div>
          </div>

          <div class="overflow-auto">
            <table class="f6 w-100 mw8 center" cellspacing="0">
              <thead>
                <tr>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">Consideration</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.first)}</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.second)}</th>
                </tr>
              </thead>
              <tbody class="lh-copy">
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">UX / Ease of Use</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getuserinterface(props.first))}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getuserinterface(props.second))}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Installation / Onboarding</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getonboarding(props.first))}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getonboarding(props.second))}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Split Tunneling Windows</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingwindows(props.first))}`}>{getsplittunnelingwindows(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingwindows(props.second))}`}>{getsplittunnelingwindows(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Split Tunneling Mac</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingmac(props.first))}`}>{getsplittunnelingmac(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingmac(props.second))}`}>{getsplittunnelingmac(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Split Tunneling Android</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingandroid(props.first))}`}>{getsplittunnelingandroid(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingandroid(props.second))}`}>{getsplittunnelingandroid(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Split Tunneling iOS</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingios(props.second))}`}>{getsplittunnelingios(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getsplittunnelingios(props.second))}`}>{getsplittunnelingios(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Unblocks Netflix Score</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getnetflixscore(props.first))}`}>{getnetflixscore(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getnetflixscore(props.second))}`}>{getnetflixscore(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Unblocks BBC iPlayer</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getbbciplayer(props.first))}`}>{getbbciplayer(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getbbciplayer(props.second))}`}>{getbbciplayer(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Extras</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getextras(props.first)}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getextras(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Number of Servers</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getservers(props.first)} +</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getservers(props.second)} +</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Number of Countries with Servers</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getcountries(props.first)}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getcountries(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Maximum Number of Connections</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getUnlimited(getconnections(props.first))}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getUnlimited(getconnections(props.second))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <div>
        {props.children}
      </div>
    </>
  )
}

export default CompareFunctionality