import React from "react"

const Hero = (props) => {
  return (
    <header className="sans-serif bg-primary7">
      <div className="tc-l pv4 pv5-m pv6-l mh4">
        <h1 className="avenir white f2 f1-l fw3 mb0 lh-title">
          {props.heading}
        </h1>
        <p className="avenir primary3 fw2 f3 fw3-ns mt3 mb4">
          {props.byline}
        </p>
      </div>
    </header>
  )
}

export default Hero