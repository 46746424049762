import React from "react"

const TestProgressBar = (props) => {

  const vpns = {
    scores: props.vpn_scores,
    names: props.vpn_names
  }
  const no_vpn_score = parseFloat(props.no_vpn_score, 10);

  const showVPNScores = vpns.names
    .map((name, idx) => {
      return (
        <>
          <div class="flex justify-between">
            <div class="w-75"><h5 class="mb1 green4">{name}</h5></div>
            <div class="w-25"><h5 class="mb1 tr green4">{vpns.scores[idx]} {props.measurement}</h5></div>
          </div>
          <div class="bg-gray1 br-pill h025 overflow-y-hidden">
            <div class="br-pill h025 shadow-1 bg-green4" style={{ width: scoreAsPercentage(no_vpn_score, vpns.scores[idx], props.win_result) + "%" }}></div>
          </div>
        </>
      )
    })

  function scoreAsPercentage(no_vpn_score, vpn_score, win_result) {
    if (win_result === 'higher') {
      return (((((no_vpn_score - vpn_score) / no_vpn_score) * 100) - 100) * -1)
    } else {
      return (((((vpn_score - no_vpn_score) / vpn_score) * 100) - 100) * -1)
    }
  }

  function getWinnerName(win_result) {
    if (win_result === "higher") {
      let i = vpns.scores.indexOf(Math.max(...vpns.scores))
      return (
        vpns.names[i]
      )
    } else if (win_result === "lower") {
      let i = vpns.scores.indexOf(Math.min(...vpns.scores))
      return (
        vpns.names[i]
      )
    } else {
      return "Tie"
    }
  }

  return (
    <section class="center br0 hidden ba b--gray2 mv4">
      <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">{props.test_name}</h4>
      <div class="bt b--gray2">
        <div class="flex flex-column flex-row-ns">
          <div class="pa3 w-100 w-60-ns pr3-ns order-2 order-1-ns">
            <p className="f7 lh-copy mv0">* A {props.win_result} score is better.</p>
            <div class="flex justify-between">
              <div class="w-75"><h5 class="mb1 indigo4">No VPN</h5></div>
              <div class="w-25"><h5 class="mb1 tr indigo4">{props.no_vpn_score} {props.measurement}</h5></div>
            </div>
            <div class="bg-gray1 br-pill h025 overflow-y-hidden"><div class="br-pill h025 shadow-1 bg-indigo4" styles="width: 100%;"></div></div>
            {showVPNScores}
          </div>
          <div class="v-mid pa3-ns pa4-l order-1 order-2-ns mb0 w-100 w-40-ns">
            <div class="great v-mid w-100" styles="min-width:50%;">
              <p class="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">Winner</p>
              <p class="mv0 avenir fw6 f4 tc white pb3">{getWinnerName(props.win_result)}</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TestProgressBar