import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReviewClickPia from './reviewClickPia.js'
import ReviewClickIvacy from './reviewClickIvacy.js'
import ReviewClickNamecheap from './reviewClickNamecheap.js'
import ReviewClickVypr from './reviewClickVypr.js'
import ReviewClickPure from './reviewClickPure.js'
import ReviewClickNord from './reviewClickNord.js'
import ReviewClickSurfshark from './reviewClickSurfshark.js'
import ReviewClickCyberghost from './reviewClickCyberghost.js'
import ReviewClickWindscribe from './reviewClickWindscribe.js'
import ReviewClickTorguard from './reviewClickTorguard.js'
import ReviewClickMullvad from './reviewClickMullvad.js'
import ReviewClickExpress from './reviewClickExpress.js'
import ReviewClickProton from './reviewClickProton.js'

const NetflixVpnWinner = () => {

  const data = useStaticQuery(
    graphql`
      query {
        allGoogleSheetResultsRow(filter: {netflixau: {ne: "skip"}}, sort: {fields: netflixpercentage, order: DESC}) {
          nodes {
            netflixscore
            vpnfamily
            vpnaff
            vpnid
            netflixpercentage
            netflixint
            netflixcountries
          }
        }
      }
    `
  )

  const vpns = data.allGoogleSheetResultsRow.nodes

  const winner = vpns[0].vpnaff
  const score = vpns[0].netflixint
  const total = vpns[0].netflixcountries
  const second = vpns[1].vpnaff
  const second_score = vpns[1].netflixint
  const second_total = vpns[1].netflixcountries
  const third = vpns[2].vpnaff
  const third_score = vpns[2].netflixint
  const third_total = vpns[2].netflixcountries

  function getWinner(vpn, score, total, text) {
    switch (vpn) {
      case 'pia': return (
        <ReviewClickPia text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'ivacy': return (
        <ReviewClickIvacy text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'namecheap': return (
        <ReviewClickNamecheap text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'vypr': return (
        <ReviewClickVypr text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'pure': return (
        <ReviewClickPure text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'nord': return (
        <ReviewClickNord text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'surfshark': return (
        <ReviewClickSurfshark text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'cyberghost': return (
        <ReviewClickCyberghost text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'windscribe': return (
        <ReviewClickWindscribe text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'torguard': return (
        <ReviewClickTorguard text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'mullvad': return (
        <ReviewClickMullvad text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'express': return (
        <ReviewClickExpress text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      case 'proton': return (
        <ReviewClickProton text={`${text} - ${score} out of ${total} countries unblocked`} />
      )
      default: return (
        <ReviewClickPia text={`${text} - ${score} out of ${total} countries unblocked`} />
      );
    }
  }

  return (
    <>
      <h4>1st Place</h4>
      {getWinner(winner, score, total, "Best for watching Netflix")}
      <h4>2nd Place</h4>
      {getWinner(second, second_score, second_total, "Recommended")}
      <h4>3rd Place</h4>
      {getWinner(third, third_score, third_total, "Recommended")}
    </>
  )
}

export default NetflixVpnWinner