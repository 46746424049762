import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const CaseTest = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          downloadspeed
          fileavgspeed
          fileavgtime
          latency
          maxdl
          vpnname
          uploadspeed
          notests
          mindltime
          vpnfamily
          protocol
          latencymedian
          latencymin
          maxul
          mediandl
          medianul
          filemediantime
          torrent1Speed
          torrent1Time
          torrent2Speed
          torrent2Time
          torrent3Speed
          torrent3Time
          torrentavgspeed
          vpnaff
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          downloadspeed
          fileavgspeed
          fileavgtime
          latency
          maxdl
          vpnname
          uploadspeed
          notests
          mindltime
          vpnfamily
          protocol
          latencymedian
          latencymin
          maxul
          mediandl
          medianul
          filemediantime
          torrent1Speed
          torrent1Time
          torrent2Speed
          torrent2Time
          torrent3Speed
          torrent3Time
          torrentavgspeed
          vpnaff
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          id
          downloadspeed
          fileavgspeed
          fileavgtime
          latency
          maxdl
          vpnname
          uploadspeed
          notests
          mindltime
          vpnfamily
          protocol
          latencymedian
          latencymin
          maxul
          mediandl
          medianul
          filemediantime
          torrent1Speed
          torrent1Time
          torrent2Speed
          torrent2Time
          torrent3Speed
          torrent3Time
          torrentavgspeed
          vpnaff
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const vypr = data.vypr

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "vypr": return vypr.vpnfamily
      default: return pia.vpnfamily
    } 
  }



  return (
    <>
      {getName(props.first)} and {getName(props.second)}
    </>
  )
}

export default CaseTest