import React from "react"

const ThemeReviewBox = (props) => {

  const scores = props.scores
  const vpnname = props.vpnname

  let total_score = scores[scores.length - 1]
  // let total_score = (((scores.reduce((a, b) => a + b, 0)) * 2) / 10).toFixed(1)

  function getGrade(score) {
    switch(true) {
      case (score >= 0.0 && score < 4.0) : return `Poor`;
      case (score >= 4.0 && score < 6.0) : return `Average`;
      case (score >= 6.0 && score < 7.0) : return `Good`;
      case (score >= 7.0 && score < 8.0) : return `Very Good`;
      case (score >= 8.0 && score < 9.0) : return `Great`;
      case (score >= 9.0 && score < 10.0) : return `Excellent`;
      default: return `Perfect`;
    }
  }

  let get_grade = getGrade(total_score)

  let get_grade_css = get_grade.replace(/\s+/g, '-').toLowerCase()

  function scoreTimesTen(score) {
    return score * 10;
  }

  const colors = ["red4", "orange4", "purple4", "indigo4", "blue4"]

  const vpn = {
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
  }

  const showVPNInfo = colors
    .map((color, idx) => {
      return (
        <>
          <div className="flex justify-between">
            <div className="w-75"><h5 className={"mb1 " + color}>{vpn.criteria[idx]}</h5></div>
            <div className="w-25"><h5 className={"mb1 tr " + color}>({scores[idx]})</h5></div>
          </div>
          <div className="bg-gray1 br-pill h025 overflow-y-hidden">
            <div className={"br-pill h025 shadow-1 bg-" + color} style={{ width: scoreTimesTen(scores[idx]) + "%" }}></div>
          </div>
        </>
      )
    })

  function getCriteria(type) {
    switch (type) {
      case 'vpn': return showVPNInfo;
      default: return showVPNInfo;
    }
  }

  return (
    <section className="center br0 hidden ba b--gray2 mv4">
  <h4 className="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">{vpnname} Verdict</h4>
      <div className="bt b--gray2">
        <div className="flex flex-column flex-row-ns items-center">
          <div className="pa3 w-100 w-60-ns pr3-ns order-2 order-1-ns">
            {getCriteria(props.type)}
          </div>
          <div className="v-mid pa3-ns pa4-l order-1 order-2-ns mb0 w-100 w-40-ns">
            <div className={get_grade_css + ` v-mid w-100`} styles="min-width:50%;">
              <p className="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">{get_grade}</p>
              <p className="mv0 avenir fw6 f1 tc white">{total_score}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bt b--gray2">
        <div className="flex flex-column flex-row-ns flex-wrap">
          {props.children}
        </div>
        <div className="pa3 tc bt b--gray2 flex justify-center">
          {props.aff}

          {props.demo &&
            <span className="ml5">{props.demo}</span>
          }
        </div>
      </div>
    </section>
  )
}

export default ThemeReviewBox