import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AffiliateLink from './affiliateLink.js'

const CompareTimePeriod = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          enddate
          startdate
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getstartdate(aff) {
    switch (aff) {
      case "pia": return pia.startdate
      case "ivacy": return ivacy.startdate
      case "namecheap": return namecheap.startdate
      case "vypr": return vypr.startdate
      case "pure": return pure.startdate
      case "nord": return nord.startdate
      case "surfshark": return surfshark.startdate
      case "cyberghost": return cyberghost.startdate
      case "windscribe": return windscribe.startdate
      case "torguard": return torguard.startdate
      case "mullvad": return mullvad.startdate
      case "express": return express.startdate
      case "proton": return proton.startdate
      default: return pia.startdate
    }
  }

  function getenddate(aff) {
    switch (aff) {
      case "pia": return pia.enddate
      case "ivacy": return ivacy.enddate
      case "namecheap": return namecheap.enddate
      case "vypr": return vypr.enddate
      case "pure": return pure.enddate
      case "nord": return nord.enddate
      case "surfshark": return surfshark.enddate
      case "cyberghost": return cyberghost.enddate
      case "windscribe": return windscribe.enddate
      case "torguard": return torguard.enddate
      case "mullvad": return mullvad.enddate
      case "express": return express.enddate
      case "proton": return proton.enddate
      default: return pia.enddate
    }
  }

  function getMonth(month) {
    switch (month) {
      case "January": return "01"
      case "February": return "02"
      case "March": return "03"
      case "April": return "04"
      case "May": return "05"
      case "June": return "06"
      case "July": return "07"
      case "August": return "08"
      case "September": return "09"
      case "October": return "10"
      case "November": return "11"
      case "December": return "12"
      default: return "01"
    }
  }

  function getEarlier(date1,date2) {
    let first_date_calc = date1.split(" ")
    let first_date = `${first_date_calc[2]}/${getMonth(first_date_calc[1])}/${first_date_calc[0]}`
    let second_date_calc = date2.split(" ")
    let second_date = `${second_date_calc[2]}/${getMonth(second_date_calc[1])}/${second_date_calc[0]}`
    let first = new Date(first_date)
    let second = new Date(second_date)
    if (first < second) {
      return `${first_date_calc[0]} ${first_date_calc[1]}`
    } else {
      return `${second_date_calc[0]} ${second_date_calc[1]}`
    }
  }

  function getLater(date1,date2) {
    let first_date_calc = date1.split(" ")
    let first_date = `${first_date_calc[2]}/${getMonth(first_date_calc[1])}/${first_date_calc[0]}`
    let second_date_calc = date2.split(" ")
    let second_date = `${second_date_calc[2]}/${getMonth(second_date_calc[1])}/${second_date_calc[0]}`
    let first = new Date(first_date)
    let second = new Date(second_date)
    if (first > second) {
      return date1
    } else {
      return date2
    }
  }

  return (
    <>
      <p>This comparison between <AffiliateLink type="link" affiliate={props.first}>{getName(props.first)}</AffiliateLink> and <AffiliateLink type="link" affiliate={props.second}>{getName(props.second)}</AffiliateLink> includes test data that was undertaken between {getEarlier(getstartdate(props.first), getstartdate(props.second))} and {getLater(getenddate(props.first), getenddate(props.second))}.</p>
    </>
  )
}

export default CompareTimePeriod