import React from 'react'
import { graphql } from 'gatsby'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Img from "gatsby-image"

const AboutPage = ({ data }) => {
  return (
    <Layout>
      <SEO 
        title="About"
        description="Learn a little bit about the VPN Review site, 083.org"
      />
      <Hero
        heading="About"
        byline="About 083.org"
      />
      <section className="bg-white bb b--gray2">
        <div className="mw8 pv4-l center flex flex-wrap items-center-ns">
          <div className="pa4 w-100 w-60-ns w-50-l">
            <p>Hi, I’m James. I live in Melbourne, and I spend a lot of time testing out VPNs.</p>
            <p>I got my first VPN, Private Internet Access, back in 2012. At the time I lived overseas and I used it to unblock some news and sports sites that didn’t work outside of Australia.</p>
            <p>I’ve spent a lot of time learning about internet security and testing out the most popular VPNs on the market.</p>
            <p>My goal is to give you the full story in a clear, concise, and transparent manner: ranging from how VPN technology works, to which ones perform the best based on tests, and most importantly, who may or may not be suited to a particular VPN.</p>
            <p>This is a living, breathing, website that will be continuously updated every month and enhanced over time. I hope you find it useful for your circumstances.</p>
            <p>Feel free to reach out to me at: <a href="mailto:hello@083.org" className="primary4 link hover-primary6 no-underline underline-hover">hello@083.org</a></p>
          </div>
          <div className="ph4 w-100 w-40-ns w-50-l tl">
            <Img fluid={data.file.childImageSharp.fluid} alt="A drawing of James using a computer" />
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default AboutPage

export const pageQuery = graphql`
  query {
    file(relativePath: { eq: "assets/first-vpn.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality:90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`