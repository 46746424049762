import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import ReviewBox from './reviewBox.js'
import AffiliateLink from './affiliateLink.js'

const GetScoreBox = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
        celo: googleSheetResultsRow(vpnid: {eq: 28}) {
          privacyscore
          speedscore
          functionalityscore
          reputationscore
          pricescore
          totalscore
          vpnfamily
          vpnaff
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton
  const celo = data.celo

  function getVpnName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      case "celo": return celo.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getScoreArray(aff) {
    switch (aff) {
      case "pia": return [pia.privacyscore, pia.speedscore, pia.functionalityscore, pia.reputationscore, pia.pricescore, pia.totalscore]
      case "ivacy": return [ivacy.privacyscore, ivacy.speedscore, ivacy.functionalityscore, ivacy.reputationscore, ivacy.pricescore, ivacy.totalscore]
      case "namecheap": return [namecheap.privacyscore, namecheap.speedscore, namecheap.functionalityscore, namecheap.reputationscore, namecheap.pricescore, namecheap.totalscore]
      case "vypr": return [vypr.privacyscore, vypr.speedscore, vypr.functionalityscore, vypr.reputationscore, vypr.pricescore, vypr.totalscore]
      case "pure": return [pure.privacyscore, pure.speedscore, pure.functionalityscore, pure.reputationscore, pure.pricescore, pure.totalscore]
      case "nord": return [nord.privacyscore, nord.speedscore, nord.functionalityscore, nord.reputationscore, nord.pricescore, nord.totalscore]
      case "surfshark": return [surfshark.privacyscore, surfshark.speedscore, surfshark.functionalityscore, surfshark.reputationscore, surfshark.pricescore, surfshark.totalscore]
      case "cyberghost": return [cyberghost.privacyscore, cyberghost.speedscore, cyberghost.functionalityscore, cyberghost.reputationscore, cyberghost.pricescore, cyberghost.totalscore]
      case "windscribe": return [windscribe.privacyscore, windscribe.speedscore, windscribe.functionalityscore, windscribe.reputationscore, windscribe.pricescore, windscribe.totalscore]
      case "torguard": return [torguard.privacyscore, torguard.speedscore, torguard.functionalityscore, torguard.reputationscore, torguard.pricescore, torguard.totalscore]
      case "mullvad": return [mullvad.privacyscore, mullvad.speedscore, mullvad.functionalityscore, mullvad.reputationscore, mullvad.pricescore, mullvad.totalscore]
      case "express": return [express.privacyscore, express.speedscore, express.functionalityscore, express.reputationscore, express.pricescore, express.totalscore]
      case "proton": return [proton.privacyscore, proton.speedscore, proton.functionalityscore, proton.reputationscore, proton.pricescore, proton.totalscore]
      case "celo": return [celo.privacyscore, celo.speedscore, celo.functionalityscore, celo.reputationscore, celo.pricescore, celo.totalscore]
      default: return [pia.privacyscore, pia.speedscore, pia.functionalityscore, pia.reputationscore, pia.pricescore, pia.totalscore]
    }
  }

  // const scorearray = [vpn.privacyscore, vpn.speedscore, vpn.functionalityscore, vpn.reputationscore, vpn.pricescore, vpn.totalscore]

  return (
    <>
      <ReviewBox
        vpnname={getVpnName(props.aff)}
        scores={getScoreArray(props.aff)}
        aff={
          <AffiliateLink type="button" affiliate={props.aff}>
            Get {getVpnName(props.aff)}
          </AffiliateLink>
        }
      >
        {props.children}
      </ReviewBox>
    </>
  )
}

export default GetScoreBox