import React from "react"

const ThemeReviewCriteria = (props) => {

  const colors = ["red4", "orange4", "purple4", "indigo4", "blue4"]

  let name = props.name

  const vpn = {
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
    description: [
      `Does ${getDiff3(name)} leak any data while in use? Do they collect or store logs? Do they allow you to anonymously purchase the service? Do they employ a variety of security protocols to keep you anonymous online?`,
      `Does ${getDiff3(name)} offer fast speeds (on par with browsing without a VPN connection)? Are these speeds consistent? Or do they drop off or fluctuate too much?`,
      `Does ${getDiff3(name)} work well? Is it easy to install and use? Does it unblock geo-restricted content? Can it unblock Netflix and BBC iPlayer?`,
      `Is ${getDiff4(name)} a reputable seller with a strong track record of happy customers and good reviews? Does it provide outstanding customer and tech support? Does ${getDiff4(name)} and their parent company have a track record of honesty, transparency, and its customers' best interests at heart?`,
      `Does the price of the service match (or exceed) the quality of experience? Does ${getDiff3(name)} represent value for money?`
    ]
  }

  const showVPNInfo = colors
    .map((color, idx) => {
      return (
        <li className="ph3 pv1 bt b--gray2">
          <h5 className={color}>{vpn.criteria[idx]}</h5>
          <p className="mid-gray">{vpn.description[idx]}</p>
        </li>
      )
    }) 

  function showVPNName(name) {
    switch (name) {
      case 'pia': return `Private Internet Access`;
      case 'ivacy': return `Ivacy VPN`;
      case 'namecheap': return `Namecheap VPN`;
      case 'vypr': return `VyprVPN`;
      case 'pure': return `PureVPN`;
      case 'nord': return `NordVPN`;
      case 'surfshark': return `SurfShark`;
      case 'cyberghost': return `CyberGhost VPN`;
      case 'windscribe': return `Windscribe`;
      case 'torguard': return `TorGuard`;
      case 'mullvad': return `Mullvad`;
      case 'express': return `ExpressVPN`;
      default: return "the VPN";
    }
  }
    
  function getDiff1(name) {
    if (name) {
      return showVPNName(name);
    } else {
      return "these vpns";
    }
  }

  function getDiff2(name) {
    if (name) {
      return "good understanding of the service";
    } else {
      return "hand-picked list";
    }
  }

  function getDiff3(name) {
    if (name) {
      return showVPNName(name);
    } else {
      return "the VPN";
    }
  }

  function getDiff4(name) {
    if (name) {
      return showVPNName(name);
    } else {
      return "the provider";
    }
  }

  return (
    <div className="bg-gray0 pa4-ns pa2 avenir mv1-ns ba b--gray2">
      <div className="center ph4-ns ph2">
        <h3 className="f5 ttu tracked dark-gray">Review Criteria</h3>
        <p className="mid-gray">
          I've rated {getDiff1(props.name)} on multiple elements across five categories, and analysed the results to give you a {getDiff2(props.name)}.
        </p>
        <ol className="list pl0 ml0 center mw0 br2">
          {showVPNInfo}
        </ol>
      </div>
    </div>
  )
}

export default ThemeReviewCriteria