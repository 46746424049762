import { Link } from 'gatsby'
import React from 'react'

const MenuItems = ({ linkto, text, description }) => (
  <div className="ma4">
    <Link to={linkto} className="f4 fw5 avenir mt0 lh-title primary5 hover-primary9 glow no-underline underline-hover">{text}</Link>
    <p className="pb3 mt1 f6 fw5 gray lh-copy athelas bb b--black-10 f5-ns">{description}</p>
  </div>
)

const Navbar = () => {

  function openNav() {
    document.getElementById("mySidenav").style.width = "20rem";
  }

  function closeNav() {
    document.getElementById("mySidenav").style.width = "0";
  }

  return (
    <>
      <div class="pa2 v-mid">
        <button
          className="clear-button cursor tr f6 fw4 f5-l avenir mt0 lh-title primary5 hover-primary7 no-underline underline-hover"
          title="View menu"
          onClick={openNav}
        >
          <span className="v-mid box-shadow-menu"></span>Menu
        </button>
      </div>

      <div id="mySidenav" class="sidenav">
        <nav>
          <button className="clear-button v-mid closebtn primary8 hover-primary7 no-underline underline-hover" onClick={closeNav}>
            <span className="f2 no-underline">&times;</span> 
            <span className="pl2 v-mid cursor tr f6 fw4 f5-l avenir mt0 lh-title">
              Close
            </span>
          </button>
          <MenuItems linkto="/learn/" text="Learn" description="VPN articles, tips, and advice." />
          <MenuItems linkto="/reviews/" text="Reviews" description="Find the best VPN for you." />
          <MenuItems linkto="/comparisons/" text="Comparisons" description="Side by side VPN comparisons." />
        </nav>
      </div>
    </>
  )
}

export default Navbar