import React from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"

const ContactPage = () => {
  return (
    <Layout>
      <SEO 
        title="Contact"
        description="Contact information for 083.org" 
      />
      <Hero
        heading="Contact"
        byline="Contact me anytime."
      />
      <main class="pa4 ph7-l mw9-l center" id="post">
        <p>Feel free to contact me with any questions or comments VPN or technology related.</p>
        <p>I'll do my best to respond to you as soon as I can.</p>
        <p>Send me an email at <a href="mailto:hello@083.org" className="primary4 link hover-primary6 no-underline underline-hover">hello@083.org</a></p>
      </main>
    </Layout>
  )
}

export default ContactPage