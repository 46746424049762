import React from "react"
import AffiliateLink from './affiliateLink.js'
import { Link } from "gatsby";

const ReviewClick = (props) => {

  const type = props.type

  function getType(type) {
    switch(type){
      case 'vpn': return `Get this VPN`;
      default: return `Get this VPN`;
    }
  }

  function showReviewLink(type) {
    switch (type) {
      case 'otherPage': return (
        <div className="mt2">
          <span className="f7 avenir">Read my full </span>
          <Link
            className="f7 avenir primary5 no-underline underline-hover hover-primary7"
            to={`/reviews/${props.ourReviewSlug}/`}
          >
            {props.themeName} Review
          </Link>
        </div>
      );
      case 'thisPage': return (
        <a href={getAnchor(props.themeName)} className="f7 avenir primary5 no-underline underline-hover hover-primary7">
          Read review
        </a>
      );
      default: return ``;
    }
  }

  function getAnchor(themeName) {
    return `#` + themeName.replace(/\s+/g, '-').toLowerCase();
  }

  return (
    <div class="flex justify-between items-center flex-wrap mv3 ba b--gray2 cb">
      <div class="w-25-ns w-100 pb0 pb3-ns pt3 ph3">
        <AffiliateLink url={props.url} affiliate={props.affiliate}>
          {props.children}
        </AffiliateLink>
      </div>
      <div class="w-50-ns w-100 pa3">
        <h4 className="mt0 mb1 mt3-ns primary3">{props.recommendation}</h4>
        <h3 className="mv2 dark-gray">{props.themeName}</h3>
        {showReviewLink(props.linkType)}
      </div>
      <div class="w-25-ns w-100 pa3 tr-l tc">
        <AffiliateLink url={props.url} type="button" affiliate={props.affiliate}>
          {getType(type)}
        </AffiliateLink>
      </div>
    </div>
  )
}

export default ReviewClick