import React from "react"
import { Link } from 'gatsby'

const Breadcrumb = (props) => {

  function getCategoryAnchor(category) {
    switch (category) {
      case 'Reviews': return "VPN Reviews";
      case 'Comparisons': return "VPN Comparisons";
      case 'Learn': return "Learn About VPNs";
      default: return category;
    }
  }

  return (
    <nav className="pr2 mt0 mb4 db">
      <ol className="lh-title pl0 avenir silver breadcrumb list di" itemscope="itemscope" itemtype="https://schema.org/BreadcrumbList">
        <li className="di" itemprop="itemListElement" itemscope="itemscope" itemtype="https://schema.org/ListItem">
          <Link to="/" itemprop="item" className="avenir primary5 no-underline underline-hover hover-primary7">
            <span itemprop="name">Home</span>
          </Link>
          <meta itemprop="position" content="1" />
        </li>
        <li className="di" itemprop="itemListElement" itemscope="itemscope" itemtype="http://schema.org/ListItem">
          <Link to={`/${props.slug}/`} itemprop="item" className="avenir primary5 no-underline underline-hover hover-primary7">
            <span itemprop="name">{getCategoryAnchor(props.category)}</span>
          </Link>
          <meta itemprop="position" content="2" />
        </li>
        <li className="di" itemprop="itemListElement" itemscope="itemscope" itemtype="http://schema.org/ListItem">
          <span itemprop="name">{props.heading}</span>
          <meta itemprop="position" content="3" />
        </li>
      </ol> 
    </nav>
  )
}

export default Breadcrumb