import React from "react"
import { graphql } from "gatsby"

import Article from "../components/article"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Img from "gatsby-image"

import Bio from "../components/bio.js"
import AffiliateDisclaimer from "../components/affiliateDisclosure.js"

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata.title
  const posts = data.allMdx.edges
  

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Compare VPN Providers | Best VPNs 2020" />
      <Hero
        heading="Need a VPN Recommendation?"
        byline="Advice, reviews, and comparisons to help you find the right VPN for you."
      />
      <section className="bg-primary0 bb b--gray2">
        <div className="mw8 pv4-l center flex flex-wrap items-center-ns">
          <div className="pa4 w-100 w-60-ns w-50-l">
            <h2>
              Unbiased VPN Information and Reviews
            </h2>
            <p className="f5 lh-copy mt0 athelas">Hi! I'm James.</p>
            <p className="f5 lh-copy mt0 athelas">
              I spend hours testing and trying out different VPNs... so you don't have to.
            </p>
            <p className="f5 lh-copy mt0 athelas">
              I buy them with my own money and regularly test them for things like speed, performance, security, whether they unblock Netflix, etc.
            </p>
            <p className="f5 lh-copy mt0 athelas">
              I really hope that you find using this website valuable. Hopefully you can learn all about VPNs, see if you need one, and if so, find the right one for your circumstances.
            </p>
          </div>
          <div className="ph4 w-100 w-40-ns w-50-l tl">    
            <Img fluid={data.file.childImageSharp.fluid} alt="A drawing of James using a computer" />
          </div>
        </div>
      </section>
      <section className="center mw8-l">
        <div className="pv4 ph4 mv0">
          <h2 className="avenir">Latest Articles</h2>
        </div>
        {posts.map(({ node }) => {
          return (
            <Article 
              key={node.fields.slug} 
              title={node.frontmatter.heading1} 
              link={node.fields.slug} 
              excerpt={node.frontmatter.excerpt}
              last_modified={node.frontmatter.last_modified_date}
              image={node.frontmatter.image.childImageSharp.fluid}
              time_to_read={node.fields.readingTime.text}
              alt={node.frontmatter.alt}
            />
          )
        })}
      </section>
      <Bio />
      <AffiliateDisclaimer />
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    file(relativePath: { eq: "assets/jamesVPN.png" }) {
      childImageSharp {
        fluid(maxWidth: 700, quality:90) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    allMdx(filter: {frontmatter: {status: {eq: "active"}}}, sort: {fields: frontmatter___last_modified_date, order: DESC}, limit: 8) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            published_date(formatString: "MMMM DD, YYYY")
            last_modified_date(formatString: "MMMM DD, YYYY")
            title
            heading1
            description
            excerpt
            status
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
