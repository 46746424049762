import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

const Footer = ({ siteTitle }) => {
  return (
    <footer className="bg-black pv3 bt b--black-10 white avenir">
      <div class="center mw8 cf ph2-ns">
        <div class="w-100 w-third-ns ph4 ph0-l pv2 dib v-mid tc">
          <a href="mailto:hello@083.org" className="primary4 link hover-primary6 no-underline underline-hover">hello@083.org</a>
        </div>
        <div class="w-100 w-third-ns ph4 pv2 dib v-mid tc">
          <p className="f6 avenir">© 2020 <Link to="/" className="primary4 link hover-primary6 no-underline underline-hover">{siteTitle}</Link></p>
          <p className="f6 avenir">All Rights Reserved</p>
        </div>
        <div class="w-100 w-third-ns ph4 ph0-l pv2 dib v-mid tc">
          <nav className="">
            <Link to="/about/" title="About" className="f6 dib pr3 primary4 link hover-primary6 no-underline underline-hover">About</Link>
            <Link to="/contact/" title="Contact" className="f6 dib pr3 primary4 link hover-primary6 no-underline underline-hover">Contact</Link>
            <Link to="/terms/" title="Terms" className="f6 dib pr3 primary4 link hover-primary6 no-underline underline-hover">Terms of Use</Link>
            <Link to="/privacy/" title="Privacy" className="f6 dib primary4 link hover-primary6 no-underline underline-hover">Privacy</Link>
          </nav>
        </div>
      </div>
    </footer>
  )
}

Footer.propTypes = {
  siteTitle: PropTypes.string,
}

Footer.defaultProps = {
  siteTitle: '',
}

export default Footer