import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import SpeedTest from './speedTest.js'
import SummarySnippet from "./summarySnippet.js"

const PureSpeedScores = () => {

  function getColors(vpnaff) {
    switch (vpnaff) {
      case 'zeroeightthree': return `indigo4`;
      // case 'ivacy': return `red4`;
      // case 'nord': return `blue4`;
      default: return `green4`;
    }
  }

  function scoreAsPercentageLower(score, min_score) {
    return (((((score - min_score) / score) * 100) - 100) * -1)
  }
  function scoreAsPercentageHigher(score, max_score) {
    return (((((max_score - score) / max_score) * 100) - 100) * -1)
  }

  function percentageChange(final, initial) {
    const result = ((((final - initial) / initial) * 100).toFixed(2))
    if (result > 0) {
      return result
    }
    else {
      return result * -1
    }
  }

  function showDataLower(id, vpnaff, vpnname, node_score, max_score, measurement) {
    return (
      <>
        <div class="flex justify-between">
          <div class="w-70"><h5 key={id} class={`mb1 ${getColors(vpnaff)}`}>{vpnname}</h5></div>
          <div class="w-30"><h5 class={`mb1 tr ${getColors(vpnaff)}`}>{node_score} {measurement}</h5></div>
        </div>
        <div class="bg-gray1 br-pill h025 overflow-y-hidden"><div class={`br-pill h025 shadow-1 bg-${getColors(vpnaff)}`} style={{ width: scoreAsPercentageLower(node_score, max_score) + "%" }}></div></div>
      </>
    )
  }

  function showDataHigher(id, vpnaff, vpnname, node_score, max_score, measurement) {
    return (
      <>
        <div class="flex justify-between">
          <div class="w-70"><h5 key={id} class={`mb1 ${getColors(vpnaff)}`}>{vpnname}</h5></div>
          <div class="w-30"><h5 class={`mb1 tr ${getColors(vpnaff)}`}>{node_score} {measurement}</h5></div>
        </div>
        <div class="bg-gray1 br-pill h025 overflow-y-hidden"><div class={`br-pill h025 shadow-1 bg-${getColors(vpnaff)}`} style={{ width: scoreAsPercentageHigher(node_score, max_score) + "%" }}></div></div>
      </>
    )
  }


  const data = useStaticQuery(
    graphql`
      query {
        vpn: googleSheetResultsRow(vpnid: {eq: 8}) {
            id
            downloadspeed
            fileavgspeed
            fileavgtime
            latency
            maxdl
            vpnname
            uploadspeed
            notests
            mindltime
            vpnfamily
            protocol
            latencymedian
            latencymin
            maxul
            mediandl
            medianul
            filemediantime
            torrent1Speed
            torrent1Time
            torrent2Speed
            torrent2Time
            torrent3Speed
            torrent3Time
            torrentavgspeed
            vpnaff
          }
          vpn2: googleSheetResultsRow(vpnid: {eq: 9}) {
            id
            downloadspeed
            fileavgspeed
            fileavgtime
            latency
            maxdl
            vpnname
            uploadspeed
            notests
            mindltime
            vpnfamily
            protocol
            latencymedian
            latencymin
            maxul
            mediandl
            medianul
            filemediantime
            torrent1Speed
            torrent1Time
            torrent2Speed
            torrent2Time
            torrent3Speed
            torrent3Time
            torrentavgspeed
            vpnaff
          }
          novpn: googleSheetResultsRow(vpnid: {eq: 0}) {
            id
            id
            downloadspeed
            fileavgspeed
            fileavgtime
            latency
            maxdl
            vpnname
            uploadspeed
            notests
            mindltime
            vpnfamily
            protocol
            latencymedian
            latencymin
            maxul
            mediandl
            medianul
            filemediantime
            torrent1Speed
            torrent1Time
            torrent2Speed
            torrent2Time
            torrent3Speed
            torrent3Time
            torrentavgspeed
            vpnaff
          }
        }
    `
  )

  const pia_udp = data.vpn
  const pia_wireguard = data.vpn2
  const no_vpn = data.novpn

  const array = [no_vpn, pia_udp, pia_wireguard]

  const latency = Math.min(array[0].latency, array[1].latency, array[2].latency)
  const latencymedian = Math.min(array[0].latencymedian, array[1].latencymedian, array[2].latencymedian)
  const latencymin = Math.min(array[0].latencymin, array[1].latencymin, array[2].latencymin)
  const downloadspeed = Math.max(array[0].downloadspeed, array[1].downloadspeed, array[2].downloadspeed)
  const mediandl = Math.max(array[0].mediandl, array[1].mediandl, array[2].mediandl)
  const maxdl = Math.max(array[0].maxdl, array[1].maxdl, array[2].maxdl)
  const uploadspeed = Math.max(array[0].uploadspeed, array[1].uploadspeed, array[2].uploadspeed)
  const medianul = Math.max(array[0].medianul, array[1].medianul, array[2].medianul)
  const maxul = Math.max(array[0].maxul, array[1].maxul, array[2].maxul)
  const fileavgtime = Math.min(array[0].fileavgtime, array[1].fileavgtime, array[2].fileavgtime)
  const filemediantime = Math.min(array[0].filemediantime, array[1].filemediantime, array[2].filemediantime)
  const mindltime = Math.min(array[0].mindltime, array[1].mindltime, array[2].mindltime)
  const fileavgspeed = Math.max(array[0].fileavgspeed, array[1].fileavgspeed, array[2].fileavgspeed)
  const torrent1Speed = Math.max(array[0].torrent1Speed, array[1].torrent1Speed, array[2].torrent1Speed)
  const torrent2Speed = Math.max(array[0].torrent2Speed, array[1].torrent2Speed, array[2].torrent2Speed)
  const torrent3Speed = Math.max(array[0].torrent3Speed, array[1].torrent3Speed, array[2].torrent3Speed)
  const torrentavgspeed = Math.max(array[0].torrentavgspeed, array[1].torrentavgspeed, array[2].torrentavgspeed)

  return (
    <>
      <SummarySnippet>
        <p>See how {array[1].vpnfamily} performed against the other top VPNs in the <Link to="/comparisons/what-is-the-fastest-vpn/">speed tests.</Link></p>
      </SummarySnippet>

      <h3>Network Bandwidth Tests</h3>

      <SummarySnippet>
        <p>{array[1].vpnfamily} was {percentageChange(array[1].downloadspeed, array[0].downloadspeed)}% slower than my connection without a VPN, which is excellent.</p>
      </SummarySnippet>

      <h4>No VPN Network Bandwidth Speed Tests</h4>
      <p>As a benchmark I did {array[1].notests} tests through speedtest.net and openspeedtest.com with no VPN connected. Here are the average results.</p>
      <SpeedTest ping={array[0].latency} down={array[0].downloadspeed} up={array[0].uploadspeed} />
      <h4>{array[1].protocol} Network Bandwidth Speed Tests</h4>
      <p>Here are the results of {array[1].notests} tests with {array[1].vpnfamily}' {array[1].protocol} protocol.</p>
      <SpeedTest ping={array[1].latency} down={array[1].downloadspeed} up={array[1].uploadspeed} />
      <h4>{array[2].protocol} Network Bandwidth Speed Tests</h4>
      <p>Here are the {array[2].protocol} protocol results from {array[2].notests} speed tests.</p>
      <SpeedTest ping={array[2].latency} down={array[2].downloadspeed} up={array[2].uploadspeed} />

      <SummarySnippet>
        <p><em>I would often have problems connecting to the speedtest.net servers using Private Internet Access' OpenVPN TCP protocol, so this protocol was not used in the speed tests.</em></p>
      </SummarySnippet>

      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Network Bandwidth Results</h4>
        <div class="bt b--gray2">
          <div class="flex flex-column flex-row-ns">
            <div class="pa3 w-100 pr3-ns order-2 order-1-ns">

              <h5>Latency</h5>
              <p className="f7 lh-copy mv0">* A lower score is better.</p>
              <h6 className="mb0">Average</h6>
              {array.map((node) => (
                showDataLower(node.id, node.vpnaff, node.vpnname, node.latency, latency, "ms")
              ))}
              <h6 className="mb0">Median</h6>
              {array.map((node) => (
                showDataLower(node.id, node.vpnaff, node.vpnname, node.latencymedian, latencymedian, "ms")
              ))}
              <h6 className="mb0">Minimum</h6>
              {array.map((node) => (
                showDataLower(node.id, node.vpnaff, node.vpnname, node.latencymin, latencymin, "ms")
              ))}

              <h5>Download Speed</h5>
              <p className="f7 lh-copy mv0">* A higher score is better.</p>
              <h6 className="mb0">Average</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.downloadspeed, downloadspeed, "Mbps")
              ))}
              <h6 className="mb0">Median</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.mediandl, mediandl, "Mbps")
              ))}
              <h6 className="mb0">Maximum</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.maxdl, maxdl, "Mbps")
              ))}
              <h5>Upload Speed</h5>
              <p className="f7 lh-copy mv0">* A higher score is better.</p>
              <h6 className="mb0">Average</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.uploadspeed, uploadspeed, "Mbps")
              ))}
              <h6 className="mb0">Median</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.medianul, medianul, "Mbps")
              ))}
              <h6 className="mb0">Maximum</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.maxul, maxul, "Mbps")
              ))}
            </div>
          </div>

        </div>
      </section>

      <h3>File Download Tests</h3>

      <SummarySnippet>
        <p>{array[1].vpnfamily} download speed was between {percentageChange(array[2].fileavgspeed, array[0].fileavgspeed)}% and {percentageChange(array[1].fileavgspeed, array[0].fileavgspeed)}% slower downloading a 100mb file than my connection without a VPN, which is fairly average.</p>
        <p>The average time to download the file was considerably slower: between {percentageChange(array[2].fileavgtime, array[0].fileavgtime)}% and {percentageChange(array[1].fileavgtime, array[0].fileavgtime)}% slower.</p>
      </SummarySnippet>

      <p>For the file download tests I downloaded a 100MB file from an Amazon AWS server, again this test was run {array[1].notests} times.</p>

      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">File Download Test Results</h4>
        <div class="bt b--gray2">
          <div class="flex flex-column flex-row-ns">
            <div class="pa3 w-100 pr3-ns order-2 order-1-ns">
              <h5>Download Speed</h5>
              <p className="f7 lh-copy mv0">* A higher score is better.</p>
              <h6 className="mb0">Average</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.fileavgspeed, fileavgspeed, "MBps")
              ))}
              <h5>Download Times</h5>
              <p className="f7 lh-copy mv0">* A lower score is better.</p>
              <h6 className="mb0">Average</h6>
              {array.map((node) => (
                showDataLower(node.id, node.vpnaff, node.vpnname, node.fileavgtime, fileavgtime, "seconds")
              ))}
              <h6 className="mb0">Median</h6>
              {array.map((node) => (
                showDataLower(node.id, node.vpnaff, node.vpnname, node.filemediantime, filemediantime, "seconds")
              ))}
              <h6 className="mb0">Minimum</h6>
              {array.map((node) => (
                showDataLower(node.id, node.vpnaff, node.vpnname, node.mindltime, mindltime, "seconds")
              ))}
            </div>
          </div>
        </div>
      </section>

      <h3>Torrent Download Test Results</h3>

      <SummarySnippet>
        <p>{array[1].vpnfamily} torrenting speeds were on average between {percentageChange(array[2].torrentavgspeed, array[0].torrentavgspeed)}% and {percentageChange(array[1].torrentavgspeed, array[0].torrentavgspeed)}% slower than my connection without a VPN. These speeds were above average to good.</p>
        <p>{array[2].protocol} was better at downloading torrents than the {array[1].protocol} protocol.</p>
      </SummarySnippet>

      <p>To test P2P torrenting speeds I chose to download 3 .torrent files using my torrent client qBittorent.</p>

      <SummarySnippet>
        <ul>
          <li>A small torrent: downloading the art application 'GIMP' at 163.4MB.</li>
          <li>A medium torrent: downloading the 4K version of the animated film 'Big Buck Bunny' at 642MB.</li>
          <li>A large torrent: downloading the operating system 'Ubuntu 20' at 2.52GB.</li>
        </ul>
      </SummarySnippet>

      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Torrent Download Test Results</h4>
        <div class="bt b--gray2">
          <div class="flex flex-column flex-row-ns">
            <div class="pa3 w-100 pr3-ns order-2 order-1-ns">
              <h5>Average Download Speed</h5>
              <p className="f7 lh-copy mv0">* A higher score is better.</p>
              <h6 className="mb0">Average</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.torrentavgspeed, torrentavgspeed, "MBps")
              ))}
              <h5>Torrent Download Times</h5>
              <p className="f7 lh-copy mv0">* A higher speed score is better, a lower time is better.</p>
              <h6 className="mb0">Torrent 1 - Small File</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.torrent1Speed, torrent1Speed, `MBps, ${node.torrent1Time}`)
              ))}
              <h6 className="mb0">Torrent 2 - Medium File</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.torrent2Speed, torrent2Speed, `MBps, ${node.torrent2Time}`)
              ))}
              <h6 className="mb0">Torrent 3 - Large File</h6>
              {array.map((node) => (
                showDataHigher(node.id, node.vpnaff, node.vpnname, node.torrent3Speed, torrent3Speed, `MBps, ${node.torrent3Time}`)
              ))}
            </div>
          </div>

        </div>
      </section>

    </>
  )
}

export default PureSpeedScores


