import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const CompareVerdict = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          reputationscore
          functionalityscore
          speedscore
          totalscore
          pricescore
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getPrivacyScore(aff) {
    switch (aff) {
      case "pia": return pia.privacyscore
      case "ivacy": return ivacy.privacyscore
      case "namecheap": return namecheap.privacyscore
      case "vypr": return vypr.privacyscore
      case "pure": return pure.privacyscore
      case "nord": return nord.privacyscore
      case "surfshark": return surfshark.privacyscore
      case "cyberghost": return cyberghost.privacyscore
      case "windscribe": return windscribe.privacyscore
      case "torguard": return torguard.privacyscore
      case "mullvad": return mullvad.privacyscore
      case "express": return express.privacyscore
      case "proton": return proton.privacyscore
      default: return pia.privacyscore
    }
  }

  function getSpeedScore(aff) {
    switch (aff) {
      case "pia": return pia.speedscore
      case "ivacy": return ivacy.speedscore
      case "namecheap": return namecheap.speedscore
      case "vypr": return vypr.speedscore
      case "pure": return pure.speedscore
      case "nord": return nord.speedscore
      case "surfshark": return surfshark.speedscore
      case "cyberghost": return cyberghost.speedscore
      case "windscribe": return windscribe.speedscore
      case "torguard": return torguard.speedscore
      case "mullvad": return mullvad.speedscore
      case "express": return express.speedscore
      case "proton": return proton.speedscore
      default: return pia.speedscore
    }
  }

  function getFunctionalityScore(aff) {
    switch (aff) {
      case "pia": return pia.functionalityscore
      case "ivacy": return ivacy.functionalityscore
      case "namecheap": return namecheap.functionalityscore
      case "vypr": return vypr.functionalityscore
      case "pure": return pure.functionalityscore
      case "nord": return nord.functionalityscore
      case "surfshark": return surfshark.functionalityscore
      case "cyberghost": return cyberghost.functionalityscore
      case "windscribe": return windscribe.functionalityscore
      case "torguard": return torguard.functionalityscore
      case "mullvad": return mullvad.functionalityscore
      case "express": return express.functionalityscore
      case "proton": return proton.functionalityscore
      default: return pia.functionalityscore
    }
  }

  function getReputationScore(aff) {
    switch (aff) {
      case "pia": return pia.reputationscore
      case "ivacy": return ivacy.reputationscore
      case "namecheap": return namecheap.reputationscore
      case "vypr": return vypr.reputationscore
      case "pure": return pure.reputationscore
      case "nord": return nord.reputationscore
      case "surfshark": return surfshark.reputationscore
      case "cyberghost": return cyberghost.reputationscore
      case "windscribe": return windscribe.reputationscore
      case "torguard": return torguard.reputationscore
      case "mullvad": return mullvad.reputationscore
      case "express": return express.reputationscore
      case "proton": return proton.reputationscore
      default: return pia.reputationscore
    }
  }

  function getPricingScore(aff) {
    switch (aff) {
      case "pia": return pia.pricescore
      case "ivacy": return ivacy.pricescore
      case "namecheap": return namecheap.pricescore
      case "vypr": return vypr.pricescore
      case "pure": return pure.pricescore
      case "nord": return nord.pricescore
      case "surfshark": return surfshark.pricescore
      case "cyberghost": return cyberghost.pricescore
      case "windscribe": return windscribe.pricescore
      case "torguard": return torguard.pricescore
      case "mullvad": return mullvad.pricescore
      case "express": return express.pricescore
      case "proton": return proton.pricescore
      default: return pia.pricescore
    }
  }

  function getTotalScore(aff) {
    switch (aff) {
      case "pia": return pia.totalscore
      case "ivacy": return ivacy.totalscore
      case "namecheap": return namecheap.totalscore
      case "vypr": return vypr.totalscore
      case "pure": return pure.totalscore
      case "nord": return nord.totalscore
      case "surfshark": return surfshark.totalscore
      case "cyberghost": return cyberghost.totalscore
      case "windscribe": return windscribe.totalscore
      case "torguard": return torguard.totalscore
      case "mullvad": return mullvad.totalscore
      case "express": return express.totalscore
      case "proton": return proton.totalscore
      default: return pia.totalscore
    }
  }

  const colors = ["red4", "orange4", "purple4", "indigo4", "blue4"]

  const vpn = {
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
  }

  function scoreTimesTen(score) {
    return score * 10;
  }

  return (
    <>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Comparison Results</h4>

        <div className="bt b--gray2">
          <div className="flex flex-column flex-row-ns">
            <div className="pa3 w-100 w-50-ns pr3-ns">
              <h5 className={"f5 mv0 " + colors[0]}>{vpn.criteria[0]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[0]}>({getPrivacyScore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[0]} style={{ width: scoreTimesTen(getPrivacyScore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[0]}>({getPrivacyScore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[0]} style={{ width: scoreTimesTen(getPrivacyScore(props.second)) + "%" }}></div>
                </div>
              </div>
              <h5 className={"f5 mv1 " + colors[1]}>{vpn.criteria[1]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[1]}>({getSpeedScore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[1]} style={{ width: scoreTimesTen(getSpeedScore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[1]}>({getSpeedScore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[1]} style={{ width: scoreTimesTen(getSpeedScore(props.second)) + "%" }}></div>
                </div>
              </div>
              <h5 className={"f5 mv1 " + colors[2]}>{vpn.criteria[2]}</h5>
              <div className="mt0 mb0">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[2]}>({getFunctionalityScore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[2]} style={{ width: scoreTimesTen(getFunctionalityScore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[2]}>({getFunctionalityScore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[2]} style={{ width: scoreTimesTen(getFunctionalityScore(props.second)) + "%" }}></div>
                </div>
              </div>
            </div>
            <div className="pa3 w-100 w-50-ns pr3-ns">
              <h5 className={"f5 mv0 " + colors[3]}>{vpn.criteria[3]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[3]}>({getReputationScore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[3]} style={{ width: scoreTimesTen(getReputationScore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[3]}>({getReputationScore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[3]} style={{ width: scoreTimesTen(getReputationScore(props.second)) + "%" }}></div>
                </div>
              </div>
              <h5 className={"f5 mv1 " + colors[4]}>{vpn.criteria[4]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[4]}>({getPricingScore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[4]} style={{ width: scoreTimesTen(getPricingScore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[4]}>({getPricingScore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[4]} style={{ width: scoreTimesTen(getPricingScore(props.second)) + "%" }}></div>
                </div>
              </div>
              <h5 className={"f5 mv1 primary4"}>Total Score</h5>
              <div className="mt0 mb1">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr primary4"}>({getTotalScore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-primary4"} style={{ width: scoreTimesTen(getTotalScore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr primary4"}>({getTotalScore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-primary4"} style={{ width: scoreTimesTen(getTotalScore(props.second)) + "%" }}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div>
        {props.children}
      </div>
    </>
  )
}

export default CompareVerdict