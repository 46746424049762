import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AffiliateLink from './affiliateLink.js'

const CompareSpeed = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        novpn: googleSheetResultsRow(vpnid: {eq: 0}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn1: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn2: googleSheetResultsRow(vpnid: {eq: 2}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn3: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn4: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn5: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn6: googleSheetResultsRow(vpnid: {eq: 6}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn7: googleSheetResultsRow(vpnid: {eq: 7}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn8: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn9: googleSheetResultsRow(vpnid: {eq: 9}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn10: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn11: googleSheetResultsRow(vpnid: {eq: 11}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn12: googleSheetResultsRow(vpnid: {eq: 12}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn13: googleSheetResultsRow(vpnid: {eq: 13}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn14: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn15: googleSheetResultsRow(vpnid: {eq: 15}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn16: googleSheetResultsRow(vpnid: {eq: 16}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn17: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn18: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn19: googleSheetResultsRow(vpnid: {eq: 19}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn20: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn21: googleSheetResultsRow(vpnid: {eq: 21}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn22: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn23: googleSheetResultsRow(vpnid: {eq: 23}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn24: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn25: googleSheetResultsRow(vpnid: {eq: 25}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn26: googleSheetResultsRow(vpnid: {eq: 26}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn27: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn28: googleSheetResultsRow(vpnid: {eq: 28}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn29: googleSheetResultsRow(vpnid: {eq: 29}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn30: googleSheetResultsRow(vpnid: {eq: 30}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn31: googleSheetResultsRow(vpnid: {eq: 31}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn32: googleSheetResultsRow(vpnid: {eq: 32}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        vpn33: googleSheetResultsRow(vpnid: {eq: 33}) {
          id
          vpnfamily
          vpnaff
          vpnid
          vpnname
          speedscore
          downloadspeed
          fileavgspeed
          fileavgtime
          filemediantime
          maxdl
          mediandl
          maxul
          medianul
          mindltime
          notests
          protocol
          torrentavgspeed
          torrenttests
          uploadspeed
        }
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
        celo: googleSheetResultsRow(vpnid: {eq: 28}) {
          id
          vpnfamily
          vpnaff
          speedscore
        }
      }
    `
  )

  const novpn = data.novpn
  const vpn1 = data.vpn1
  const vpn2 = data.vpn2
  const vpn3 = data.vpn3
  const vpn4 = data.vpn4
  const vpn5 = data.vpn5
  const vpn6 = data.vpn6
  const vpn7 = data.vpn7
  const vpn8 = data.vpn8
  const vpn9 = data.vpn9
  const vpn10 = data.vpn10
  const vpn11 = data.vpn11
  const vpn12 = data.vpn12
  const vpn13 = data.vpn13
  const vpn14 = data.vpn14
  const vpn15 = data.vpn15
  const vpn16 = data.vpn16
  const vpn17 = data.vpn17
  const vpn18 = data.vpn18
  const vpn19 = data.vpn19
  const vpn20 = data.vpn20
  const vpn21 = data.vpn21
  const vpn22 = data.vpn22
  const vpn23 = data.vpn23
  const vpn24 = data.vpn24
  const vpn25 = data.vpn25
  const vpn26 = data.vpn26
  const vpn27 = data.vpn27
  const vpn28 = data.vpn28
  const vpn29 = data.vpn29
  const vpn30 = data.vpn30
  const vpn31 = data.vpn31
  const vpn32 = data.vpn32
  const vpn33 = data.vpn33

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton
  const celo = data.celo

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      case "celo": return celo.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getvpnname(aff) {
    switch (aff) {
      case "vpn1": return vpn1.vpnname
      case "vpn2": return vpn2.vpnname
      case "vpn3": return vpn3.vpnname
      case "vpn4": return vpn4.vpnname
      case "vpn5": return vpn5.vpnname
      case "vpn6": return vpn6.vpnname
      case "vpn7": return vpn7.vpnname
      case "vpn8": return vpn8.vpnname
      case "vpn9": return vpn9.vpnname
      case "vpn10": return vpn10.vpnname
      case "vpn11": return vpn11.vpnname
      case "vpn12": return vpn12.vpnname
      case "vpn13": return vpn13.vpnname
      case "vpn14": return vpn14.vpnname
      case "vpn15": return vpn15.vpnname
      case "vpn16": return vpn16.vpnname
      case "vpn17": return vpn17.vpnname
      case "vpn18": return vpn18.vpnname
      case "vpn19": return vpn19.vpnname
      case "vpn20": return vpn20.vpnname
      case "vpn21": return vpn21.vpnname
      case "vpn22": return vpn22.vpnname
      case "vpn23": return vpn23.vpnname
      case "vpn24": return vpn24.vpnname
      case "vpn25": return vpn25.vpnname
      case "vpn26": return vpn26.vpnname
      case "vpn27": return vpn27.vpnname
      case "vpn28": return vpn28.vpnname
      case "vpn29": return vpn29.vpnname
      case "vpn30": return vpn30.vpnname
      case "vpn31": return vpn31.vpnname
      case "vpn32": return vpn32.vpnname
      case "vpn33": return vpn33.vpnname
      default: return novpn.vpnname
    }
  }

  function getid(id) {
    switch (id) {
      case "vpn1": return vpn1.id
      case "vpn2": return vpn2.id
      case "vpn3": return vpn3.id
      case "vpn4": return vpn4.id
      case "vpn5": return vpn5.id
      case "vpn6": return vpn6.id
      case "vpn7": return vpn7.id
      case "vpn8": return vpn8.id
      case "vpn9": return vpn9.id
      case "vpn10": return vpn10.id
      case "vpn11": return vpn11.id
      case "vpn12": return vpn12.id
      case "vpn13": return vpn13.id
      case "vpn14": return vpn14.id
      case "vpn15": return vpn15.id
      case "vpn16": return vpn16.id
      case "vpn17": return vpn17.id
      case "vpn18": return vpn18.id
      case "vpn19": return vpn19.id
      case "vpn20": return vpn20.id
      case "vpn21": return vpn21.id
      case "vpn22": return vpn22.id
      case "vpn23": return vpn23.id
      case "vpn24": return vpn24.id
      case "vpn25": return vpn25.id
      case "vpn26": return vpn26.id
      case "vpn27": return vpn27.id
      case "vpn28": return vpn28.id
      case "vpn29": return vpn29.id
      case "vpn30": return vpn30.id
      case "vpn31": return vpn31.id
      case "vpn32": return vpn32.id
      case "vpn33": return vpn33.id
      default: return novpn.id
    }
  }

  function getvpnaff(id) {
    switch (id) {
      case "vpn1": return vpn1.vpnaff
      case "vpn2": return vpn2.vpnaff
      case "vpn3": return vpn3.vpnaff
      case "vpn4": return vpn4.vpnaff
      case "vpn5": return vpn5.vpnaff
      case "vpn6": return vpn6.vpnaff
      case "vpn7": return vpn7.vpnaff
      case "vpn8": return vpn8.vpnaff
      case "vpn9": return vpn9.vpnaff
      case "vpn10": return vpn10.vpnaff
      case "vpn11": return vpn11.vpnaff
      case "vpn12": return vpn12.vpnaff
      case "vpn13": return vpn13.vpnaff
      case "vpn14": return vpn14.vpnaff
      case "vpn15": return vpn15.vpnaff
      case "vpn16": return vpn16.vpnaff
      case "vpn17": return vpn17.vpnaff
      case "vpn18": return vpn18.vpnaff
      case "vpn19": return vpn19.vpnaff
      case "vpn20": return vpn20.vpnaff
      case "vpn21": return vpn21.vpnaff
      case "vpn22": return vpn22.vpnaff
      case "vpn23": return vpn23.vpnaff
      case "vpn24": return vpn24.vpnaff
      case "vpn25": return vpn25.vpnaff
      case "vpn26": return vpn26.vpnaff
      case "vpn27": return vpn27.vpnaff
      case "vpn28": return vpn28.vpnaff
      case "vpn29": return vpn29.vpnaff
      case "vpn30": return vpn30.vpnaff
      case "vpn31": return vpn31.vpnaff
      case "vpn32": return vpn32.vpnaff
      case "vpn33": return vpn33.vpnaff
      default: return novpn.vpnaff
    }
  }

  function getspeedscore(aff) {
    switch (aff) {
      case "pia": return pia.speedscore
      case "ivacy": return ivacy.speedscore
      case "namecheap": return namecheap.speedscore
      case "vypr": return vypr.speedscore
      case "pure": return pure.speedscore
      case "nord": return nord.speedscore
      case "surfshark": return surfshark.speedscore
      case "cyberghost": return cyberghost.speedscore
      case "windscribe": return windscribe.speedscore
      case "torguard": return torguard.speedscore
      case "mullvad": return mullvad.speedscore
      case "express": return express.speedscore
      case "proton": return proton.speedscore
      case "celo": return celo.speedscore
      default: return pia.speedscore
    }
  }

  function getdownloadspeed(id) {
    switch (id) {
      case "vpn1": return vpn1.downloadspeed
      case "vpn2": return vpn2.downloadspeed
      case "vpn3": return vpn3.downloadspeed
      case "vpn4": return vpn4.downloadspeed
      case "vpn5": return vpn5.downloadspeed
      case "vpn6": return vpn6.downloadspeed
      case "vpn7": return vpn7.downloadspeed
      case "vpn8": return vpn8.downloadspeed
      case "vpn9": return vpn9.downloadspeed
      case "vpn10": return vpn10.downloadspeed
      case "vpn11": return vpn11.downloadspeed
      case "vpn12": return vpn12.downloadspeed
      case "vpn13": return vpn13.downloadspeed
      case "vpn14": return vpn14.downloadspeed
      case "vpn15": return vpn15.downloadspeed
      case "vpn16": return vpn16.downloadspeed
      case "vpn17": return vpn17.downloadspeed
      case "vpn18": return vpn18.downloadspeed
      case "vpn19": return vpn19.downloadspeed
      case "vpn20": return vpn20.downloadspeed
      case "vpn21": return vpn21.downloadspeed
      case "vpn22": return vpn22.downloadspeed
      case "vpn23": return vpn23.downloadspeed
      case "vpn24": return vpn24.downloadspeed
      case "vpn25": return vpn25.downloadspeed
      case "vpn26": return vpn26.downloadspeed
      case "vpn27": return vpn27.downloadspeed
      case "vpn28": return vpn28.downloadspeed
      case "vpn29": return vpn29.downloadspeed
      case "vpn30": return vpn30.downloadspeed
      case "vpn31": return vpn31.downloadspeed
      case "vpn32": return vpn32.downloadspeed
      case "vpn33": return vpn33.downloadspeed
      default: return novpn.downloadspeed
    }
  }

  function getfileavgspeed(id) {
    switch (id) {
      case "vpn1": return vpn1.fileavgspeed
      case "vpn2": return vpn2.fileavgspeed
      case "vpn3": return vpn3.fileavgspeed
      case "vpn4": return vpn4.fileavgspeed
      case "vpn5": return vpn5.fileavgspeed
      case "vpn6": return vpn6.fileavgspeed
      case "vpn7": return vpn7.fileavgspeed
      case "vpn8": return vpn8.fileavgspeed
      case "vpn9": return vpn9.fileavgspeed
      case "vpn10": return vpn10.fileavgspeed
      case "vpn11": return vpn11.fileavgspeed
      case "vpn12": return vpn12.fileavgspeed
      case "vpn13": return vpn13.fileavgspeed
      case "vpn14": return vpn14.fileavgspeed
      case "vpn15": return vpn15.fileavgspeed
      case "vpn16": return vpn16.fileavgspeed
      case "vpn17": return vpn17.fileavgspeed
      case "vpn18": return vpn18.fileavgspeed
      case "vpn19": return vpn19.fileavgspeed
      case "vpn20": return vpn20.fileavgspeed
      case "vpn21": return vpn21.fileavgspeed
      case "vpn22": return vpn22.fileavgspeed
      case "vpn23": return vpn23.fileavgspeed
      case "vpn24": return vpn24.fileavgspeed
      case "vpn25": return vpn25.fileavgspeed
      case "vpn26": return vpn26.fileavgspeed
      case "vpn27": return vpn27.fileavgspeed
      case "vpn28": return vpn28.fileavgspeed
      case "vpn29": return vpn29.fileavgspeed
      case "vpn30": return vpn30.fileavgspeed
      case "vpn31": return vpn31.fileavgspeed
      case "vpn32": return vpn32.fileavgspeed
      case "vpn33": return vpn33.fileavgspeed
      default: return novpn.fileavgspeed
    }
  }

  function gettorrentavgspeed(id) {
    switch (id) {
      case "vpn1": return vpn1.torrentavgspeed
      case "vpn2": return vpn2.torrentavgspeed
      case "vpn3": return vpn3.torrentavgspeed
      case "vpn4": return vpn4.torrentavgspeed
      case "vpn5": return vpn5.torrentavgspeed
      case "vpn6": return vpn6.torrentavgspeed
      case "vpn7": return vpn7.torrentavgspeed
      case "vpn8": return vpn8.torrentavgspeed
      case "vpn9": return vpn9.torrentavgspeed
      case "vpn10": return vpn10.torrentavgspeed
      case "vpn11": return vpn11.torrentavgspeed
      case "vpn12": return vpn12.torrentavgspeed
      case "vpn13": return vpn13.torrentavgspeed
      case "vpn14": return vpn14.torrentavgspeed
      case "vpn15": return vpn15.torrentavgspeed
      case "vpn16": return vpn16.torrentavgspeed
      case "vpn17": return vpn17.torrentavgspeed
      case "vpn18": return vpn18.torrentavgspeed
      case "vpn19": return vpn19.torrentavgspeed
      case "vpn20": return vpn20.torrentavgspeed
      case "vpn21": return vpn21.torrentavgspeed
      case "vpn22": return vpn22.torrentavgspeed
      case "vpn23": return vpn23.torrentavgspeed
      case "vpn24": return vpn24.torrentavgspeed
      case "vpn25": return vpn25.torrentavgspeed
      case "vpn26": return vpn26.torrentavgspeed
      case "vpn27": return vpn27.torrentavgspeed
      case "vpn28": return vpn28.torrentavgspeed
      case "vpn29": return vpn29.torrentavgspeed
      case "vpn30": return vpn30.torrentavgspeed
      case "vpn31": return vpn31.torrentavgspeed
      case "vpn32": return vpn32.torrentavgspeed
      case "vpn33": return vpn33.torrentavgspeed
      default: return novpn.torrentavgspeed
    }
  }

  const getmaxdownloadspeed = Math.max(novpn.downloadspeed, vpn1.downloadspeed, vpn2.downloadspeed, vpn3.downloadspeed, vpn4.downloadspeed, vpn5.downloadspeed, vpn6.downloadspeed, vpn7.downloadspeed, vpn8.downloadspeed, vpn9.downloadspeed, vpn10.downloadspeed, vpn11.downloadspeed, vpn12.downloadspeed, vpn13.downloadspeed, vpn14.downloadspeed, vpn15.downloadspeed, vpn16.downloadspeed, vpn17.downloadspeed, vpn18.downloadspeed, vpn19.downloadspeed, vpn20.downloadspeed, vpn21.downloadspeed, vpn22.downloadspeed, vpn23.downloadspeed, vpn24.downloadspeed, vpn25.downloadspeed, vpn26.downloadspeed, vpn27.downloadspeed)

  const getmaxfileavgspeed = Math.max(novpn.fileavgspeed, vpn1.fileavgspeed, vpn2.fileavgspeed, vpn3.fileavgspeed, vpn4.fileavgspeed, vpn5.fileavgspeed, vpn6.fileavgspeed, vpn7.fileavgspeed, vpn8.fileavgspeed, vpn9.fileavgspeed, vpn10.fileavgspeed, vpn11.fileavgspeed, vpn12.fileavgspeed, vpn13.fileavgspeed, vpn14.fileavgspeed, vpn15.fileavgspeed, vpn16.fileavgspeed, vpn17.fileavgspeed, vpn18.fileavgspeed, vpn19.fileavgspeed, vpn20.fileavgspeed, vpn21.fileavgspeed, vpn22.fileavgspeed, vpn23.fileavgspeed, vpn24.fileavgspeed, vpn25.fileavgspeed, vpn26.fileavgspeed, vpn27.fileavgspeed)

  const getmaxtorrentavgspeed = Math.max(novpn.torrentavgspeed, vpn1.torrentavgspeed, vpn2.torrentavgspeed, vpn3.torrentavgspeed, vpn4.torrentavgspeed, vpn5.torrentavgspeed, vpn6.torrentavgspeed, vpn7.torrentavgspeed, vpn8.torrentavgspeed, vpn9.torrentavgspeed, vpn10.torrentavgspeed, vpn11.torrentavgspeed, vpn12.torrentavgspeed, vpn13.torrentavgspeed, vpn14.torrentavgspeed, vpn15.torrentavgspeed, vpn16.torrentavgspeed, vpn17.torrentavgspeed, vpn18.torrentavgspeed, vpn19.torrentavgspeed, vpn20.torrentavgspeed, vpn21.torrentavgspeed, vpn22.torrentavgspeed, vpn23.torrentavgspeed, vpn24.torrentavgspeed, vpn25.torrentavgspeed, vpn26.torrentavgspeed, vpn27.torrentavgspeed)

  const colors = ["red4", "orange4", "purple4", "indigo4", "blue4"]

  const vpn = {
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
  }

  function scoreTimesTen(score) {
    return score * 10;
  }

  function getWinner(first, first_score, second, second_score) {
    if (first_score > second_score) {
      return displayWinner(first)
    } else {
      return displayWinner(second)
    }
  }

  function displayWinner(vpnaff) {
    return (
      <AffiliateLink type="naked" affiliate={vpnaff}>
        <div class="great v-mid w-100" styles="min-width:50%;">
          <p class="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">Winner</p>
          <p class="mv0 avenir fw6 f4 tc white pb3">{getName(vpnaff)}</p>
        </div>
      </AffiliateLink>
    )
  }

  function getColors(vpnaff, first, second) {
    switch(vpnaff) {
      case first: return `blue4`;
      case second: return `green4`;
      case 'zeroeightthree': return `indigo4`;
      default: return `red4`;
    }
  }

  // function scoreAsPercentageLower(score, min_score) {
  //   return (((((score - min_score) / score) * 100) - 100) * -1)
  // }
  function scoreAsPercentageHigher(score, max_score) {
    return (((((max_score - score) / max_score) * 100) - 100) * -1)
  }

  // function percentageChange(final, initial) {
  //   const result = ((((final - initial) / initial) * 100).toFixed(2))
  //   if (result > 0) {
  //     return result
  //   }
  //   else {
  //     return result * -1
  //   }
  // }

  // function showDataLower(id, vpnname, node_score, max_score, measurement, vpnaff, first, second) {
  //   return (
  //     <>
  //       <div class="flex justify-between">
  //         <div class="w-70"><h5 key={id} class={`mb1 ${getColors(vpnaff, first, second)}`}>{vpnname}</h5></div>
  //         <div class="w-30"><h5 class={`mb1 tr ${getColors(vpnaff, first, second)}`}>{node_score} {measurement}</h5></div>
  //       </div>
  //       <div class="bg-gray1 br-pill h025 overflow-y-hidden"><div class={`br-pill h025 shadow-1 bg-${getColors(vpnaff, first, second)}`} style={{ width: scoreAsPercentageLower(node_score, max_score) + "%" }}></div></div>
  //     </>
  //   )
  // }

  function showDataHigher(id, vpnname, node_score, max_score, measurement, vpnaff, first, second) {
    return (
      <>
        <div class="flex justify-between">
          <div class="w-70"><h5 key={id} class={`mb1 ${getColors(vpnaff, first, second)}`}>{vpnname}</h5></div>
          <div class="w-30"><h5 class={`mb1 tr ${getColors(vpnaff, first, second)}`}>{node_score} {measurement}</h5></div>
        </div>
        <div class="bg-gray1 br-pill h025 overflow-y-hidden"><div class={`br-pill h025 shadow-1 bg-${getColors(vpnaff, first, second)}`} style={{ width: scoreAsPercentageHigher(node_score, max_score) + "%" }}></div></div>
      </>
    )
  }

  const all_protocols = props.vpns.concat(novpn.vpnaff)

  return (
    <>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class={`bg-${colors[1]} br0 br--top primary0 mv0 pa3 f5 ttu tracked`}>{vpn.criteria[1]} Comparison</h4>

        <div className="bt b--gray2">
          <div className="flex flex-column flex-row-ns">
            <div className="pa3 w-100 w-50-ns pr3-ns">
              <h5 className={"f5 mv0 " + colors[1]}>{vpn.criteria[1]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[1]}>({getspeedscore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[1]} style={{ width: scoreTimesTen(getspeedscore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[1]}>({getspeedscore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[1]} style={{ width: scoreTimesTen(getspeedscore(props.second)) + "%" }}></div>
                </div>
              </div>
            </div>
            <div class="v-mid pa3-ns pa4-l order-1 order-2-ns mb0 w-100 w-50-ns">
              {getWinner(props.first, getspeedscore(props.first), props.second, getspeedscore(props.second))}
            </div>
          </div>

          <div class="overflow-auto">
            <section class="center br0 hidden">
              <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Speed Test Results</h4>
              <div class="flex flex-column flex-row-ns">
                <div class="pa3 w-100 pr3-ns order-2 order-1-ns">
                  <p className="f7 lh-copy mt2 mb0">* Higher scores are better. Progress bar versus best test result amongst all VPNs.</p>
                  <h5 className="mt4 mb1">Network Bandwidth Download Speed (Average) {vpn1.notests} Tests</h5>
                  {all_protocols.map((node) => (
                    showDataHigher(getid(node), getvpnname(node), getdownloadspeed(node), getmaxdownloadspeed, "Mbps", getvpnaff(node), props.first, props.second)
                  ))}
                  <h5 className="mt4 mb1">100MB File Download Speed (Average) {vpn1.notests} Tests</h5>
                  {all_protocols.map((node) => (
                    showDataHigher(getid(node), getvpnname(node), getfileavgspeed(node), getmaxfileavgspeed, "MBps", getvpnaff(node), props.first, props.second)
                  ))}
                  <h5 className="mt4 mb1">Torrent Download Speed (Average) {vpn1.torrenttests} Tests</h5>
                  {all_protocols.map((node) => (
                    showDataHigher(getid(node), getvpnname(node), gettorrentavgspeed(node), getmaxtorrentavgspeed, "MBps", getvpnaff(node), props.first, props.second)
                  ))}
                </div>
              </div>
            </section>
          </div>
        </div>
      </section>
      <div>
        {props.children}
      </div>
    </>
  )
}

export default CompareSpeed