import React from "react"

const TableOfContents = ({headings}) => {
  return (
    <nav>
      <div class="bg-gray0 pa4-ns pa2 avenir mv1-ns ba b--gray2">
        <div className="center ph4-ns ph2">
          <h3 className="f5 ttu tracked dark-gray">Table of Contents</h3>
        </div>
        <ol>
          {headings.items.map(heading => (
            <li key={heading.url} className="avenir silver mv2">
              <a href={heading.url} key={heading.url} className="ml3 primary5 no-underline underline-hover hover-primary7">
                {heading.title}
              </a>
            </li>
          ))}
        </ol>
      </div>
    </nav>
  )
}

export default TableOfContents
