import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

const StrapiReviewGoodForStreaming = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: strapiReviews(strapiId: {eq: 1}) {
          good_for_streaming
        }
        ivacy: strapiReviews(strapiId: {eq: 2}) {
          good_for_streaming
        }
        namecheap: strapiReviews(strapiId: {eq: 3}) {
          good_for_streaming
        }
        vypr: strapiReviews(strapiId: {eq: 4}) {
          good_for_streaming
        }
        pure: strapiReviews(strapiId: {eq: 5}) {
          good_for_streaming
        }
        nord: strapiReviews(strapiId: {eq: 6}) {
          good_for_streaming
        }
        surfshark: strapiReviews(strapiId: {eq: 7}) {
          good_for_streaming
        }
        cyberghost: strapiReviews(strapiId: {eq: 8}) {
          good_for_streaming
        }
        windscribe: strapiReviews(strapiId: {eq: 9}) {
          good_for_streaming
        }
        torguard: strapiReviews(strapiId: {eq: 10}) {
          good_for_streaming
        }
        mullvad: strapiReviews(strapiId: {eq: 11}) {
          good_for_streaming
        }
        express: strapiReviews(strapiId: {eq: 12}) {
          good_for_streaming
        }
        proton: strapiReviews(strapiId: {eq: 13}) {
          good_for_streaming
        }
        celo: strapiReviews(strapiId: {eq: 14}) {
          good_for_streaming
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton
  const celo = data.celo

  function getElement(aff) {
    switch (aff) {
      case "pia": return pia.good_for_streaming
      case "ivacy": return ivacy.good_for_streaming
      case "namecheap": return namecheap.good_for_streaming
      case "vypr": return vypr.good_for_streaming
      case "pure": return pure.good_for_streaming
      case "nord": return nord.good_for_streaming
      case "surfshark": return surfshark.good_for_streaming
      case "cyberghost": return cyberghost.good_for_streaming
      case "windscribe": return windscribe.good_for_streaming
      case "torguard": return torguard.good_for_streaming
      case "mullvad": return mullvad.good_for_streaming
      case "express": return express.good_for_streaming
      case "proton": return proton.good_for_streaming
      case "celo": return celo.good_for_streaming
      default: return pia.good_for_streaming
    }
  }

  return (
    <ReactMarkdown source={getElement(props.aff)} />
  )
}

export default StrapiReviewGoodForStreaming

