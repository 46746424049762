/**
 * Bio component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 * 
 * res = str.split("/", 1);
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 100, height: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  const { author } = data.site.siteMetadata
  return (
    <aside className="bg-primary0 bt b--gray2">
      <div className="flex justify-between-ns flex-column flex-row-ns pa4 ph7-l mw9-l center" itemscope itemtype="http://schema.org/Person">
        <div className="w-100 w-25-ns v-mid center tc">
          <Image
            fixed={data.avatar.childImageSharp.fixed}
            alt={author.name}
            className="br-100 h3 w3 dib"
            itemprop="image"
          />
          <h6 className="mv2 f6 dark-gray" itemprop="name">{author.name}</h6>
        </div>
        <div className="w-100 w-75-ns v-mid">
          <h5 className="f6 ttu tracked dark-gray">Author</h5>
          <p className="avenir lh-copy center f6 gray">
            I'm a tech enthusiast and an ardent supporter of privacy.
          </p>
          <p className="avenir lh-copy center f6 gray">
            If you ever need any advice regarding which VPNs, feel free to email me at <a className="avenir primary5 no-underline underline-hover hover-primary7" href="mailto:hello@083.org">hello@083.org</a> and I'll do my best to help.
          </p>
        </div>
      </div>
    </aside>
  )
}

export default Bio
