import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SummarySnippet from "./summarySnippet.js"

const GetNetflixResults = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
        celo: googleSheetResultsRow(vpnid: {eq: 28}) {
          vpnname
          vpnfamily
          vpnaff
          netflixau
          netflixbr
          netflixca
          netflixcountries
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixint
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixpercentage
          netflixscore
          netflixse
          netflixuk
          netflixus
        }
      }
    `
  )

  const showau = ["Dragons: Race to the Edge (2017)", "Black Books (2000)", "Star Trek: Deep Space Nine (1999)", "Schitt’s Creek (2015)"]
  const showbr = ["I, Daniel Blake (2016)"]
  const showca = ["This Is Us (2016)", "Kill Bill: Vol. 1 (2003)", "Cinderella Man (2005)"]
  const showfr = ["Whiplash (2014)", "The Girl with the Dragon Tattoo (2009)", "The Wrestler (2008)", "Walk the Line (2005)"]
  const showde = ["24 (2001)", "X-Men (2000)", "Terminator 2: Judgment Day (1991)", "Green Book (2018)", "Alien (1979)", "Logan (2017)"]
  const showhk = ["Hunt for the Wilderpeople (2016)"]
  const showin = ["Masaan (2015)", "Munna Bhai MBBS (2003)", "Scarface (1983)"]
  const showil = ["Cast Away (2000)", "Donnie Brasco (1997)"]
  const showjp = ["The Mentalist (2014)", "iZombie (2017)", "Law & Order: Special Victims Unit (2015)", "Cowboy Bebop (1998)", "Cloud Atlas (2012)", "Cinema Paradiso (1988)", "Trainspotting (1996)"]
  const showkr = ["Oldboy (2003)", "Revenge (2014)", "Guardians of the Galaxy (2014)", "The Usual Suspects (1995)", "The Lion King (1994)", "Black Swan (2010)", "Star Wars: The Force Awakens (2015)", "Rogue One: A Star Wars Story (2016)"]
  const showmx = ["The Gentlemen (2020)"]
  const shownl = ["Once Upon a Time in Hollywood (2019)"]
  const showse = ["The Hunt (2012)", "Blade Runner: Theatrical Cut (1982)", "Kramer vs. Kramer (1979)"]
  const showuk = ["It’s Always Sunny in Philadelphia (2005)", "Doctor Who (2005)", "Fawlty Towers (1979)", "Blackadder (1989)", "Absolutely Fabulous (2012)", "Mr. Bean (1990)"]
  const showus = ["Parks and Recreation (2015)", "Twin Peaks (1990)", "Dexter (2013)", "The West Wing (2005)", "Criminal Minds (2017)", "The Hateful Eight: Extended Version (2015)", "Sin City (2005)", "Indiana Jones and the Temple of Doom (1984)"]

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton
  const celo = data.celo

  const vpn_countries = ["Australia", "Brazil", "Canada", "France", "Germany", "Hong Kong", "India", "Israel", "Japan", "Korea", "Mexico", "Netherlands", "Sweden", "United Kingdom", "USA"]

  function getVpnName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      case "celo": return celo.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getNetflixInt(aff) {
    switch (aff) {
      case "pia": return pia.netflixint
      case "ivacy": return ivacy.netflixint
      case "namecheap": return namecheap.netflixint
      case "vypr": return vypr.netflixint
      case "pure": return pure.netflixint
      case "nord": return nord.netflixint
      case "surfshark": return surfshark.netflixint
      case "cyberghost": return cyberghost.netflixint
      case "windscribe": return windscribe.netflixint
      case "torguard": return torguard.netflixint
      case "mullvad": return mullvad.netflixint
      case "express": return express.netflixint
      case "proton": return proton.netflixint
      case "celo": return celo.netflixint
      default: return pia.netflixint
    }
  }

  function getNetflixCountries(aff) {
    switch (aff) {
      case "pia": return pia.netflixcountries
      case "ivacy": return ivacy.netflixcountries
      case "namecheap": return namecheap.netflixcountries
      case "vypr": return vypr.netflixcountries
      case "pure": return pure.netflixcountries
      case "nord": return nord.netflixcountries
      case "surfshark": return surfshark.netflixcountries
      case "cyberghost": return cyberghost.netflixcountries
      case "windscribe": return windscribe.netflixcountries
      case "torguard": return torguard.netflixcountries
      case "mullvad": return mullvad.netflixcountries
      case "express": return express.netflixcountries
      case "proton": return proton.netflixcountries
      case "celo": return celo.netflixcountries
      default: return pia.netflixcountries
    }
  }

  function getVpnResultsArray(aff) {
    switch (aff) {
      case "pia": return [pia.netflixau, pia.netflixbr, pia.netflixca, pia.netflixfr, pia.netflixde, pia.netflixhk, pia.netflixin, pia.netflixil, pia.netflixjp, pia.netflixkr, pia.netflixmx, pia.netflixnl, pia.netflixse, pia.netflixuk, pia.netflixus]
      case "ivacy": return [ivacy.netflixau, ivacy.netflixbr, ivacy.netflixca, ivacy.netflixfr, ivacy.netflixde, ivacy.netflixhk, ivacy.netflixin, ivacy.netflixil, ivacy.netflixjp, ivacy.netflixkr, ivacy.netflixmx, ivacy.netflixnl, ivacy.netflixse, ivacy.netflixuk, ivacy.netflixus]
      case "namecheap": return [namecheap.netflixau, namecheap.netflixbr, namecheap.netflixca, namecheap.netflixfr, namecheap.netflixde, namecheap.netflixhk, namecheap.netflixin, namecheap.netflixil, namecheap.netflixjp, namecheap.netflixkr, namecheap.netflixmx, namecheap.netflixnl, namecheap.netflixse, namecheap.netflixuk, namecheap.netflixus]
      case "vypr": return [vypr.netflixau, vypr.netflixbr, vypr.netflixca, vypr.netflixfr, vypr.netflixde, vypr.netflixhk, vypr.netflixin, vypr.netflixil, vypr.netflixjp, vypr.netflixkr, vypr.netflixmx, vypr.netflixnl, vypr.netflixse, vypr.netflixuk, vypr.netflixus]
      case "pure": return [pure.netflixau, pure.netflixbr, pure.netflixca, pure.netflixfr, pure.netflixde, pure.netflixhk, pure.netflixin, pure.netflixil, pure.netflixjp, pure.netflixkr, pure.netflixmx, pure.netflixnl, pure.netflixse, pure.netflixuk, pure.netflixus]
      case "nord": return [nord.netflixau, nord.netflixbr, nord.netflixca, nord.netflixfr, nord.netflixde, nord.netflixhk, nord.netflixin, nord.netflixil, nord.netflixjp, nord.netflixkr, nord.netflixmx, nord.netflixnl, nord.netflixse, nord.netflixuk, nord.netflixus]
      case "surfshark": return [surfshark.netflixau, surfshark.netflixbr, surfshark.netflixca, surfshark.netflixfr, surfshark.netflixde, surfshark.netflixhk, surfshark.netflixin, surfshark.netflixil, surfshark.netflixjp, surfshark.netflixkr, surfshark.netflixmx, surfshark.netflixnl, surfshark.netflixse, surfshark.netflixuk, surfshark.netflixus]
      case "cyberghost": return [cyberghost.netflixau, cyberghost.netflixbr, cyberghost.netflixca, cyberghost.netflixfr, cyberghost.netflixde, cyberghost.netflixhk, cyberghost.netflixin, cyberghost.netflixil, cyberghost.netflixjp, cyberghost.netflixkr, cyberghost.netflixmx, cyberghost.netflixnl, cyberghost.netflixse, cyberghost.netflixuk, cyberghost.netflixus]
      case "windscribe": return [windscribe.netflixau, windscribe.netflixbr, windscribe.netflixca, windscribe.netflixfr, windscribe.netflixde, windscribe.netflixhk, windscribe.netflixin, windscribe.netflixil, windscribe.netflixjp, windscribe.netflixkr, windscribe.netflixmx, windscribe.netflixnl, windscribe.netflixse, windscribe.netflixuk, windscribe.netflixus]
      case "torguard": return [torguard.netflixau, torguard.netflixbr, torguard.netflixca, torguard.netflixfr, torguard.netflixde, torguard.netflixhk, torguard.netflixin, torguard.netflixil, torguard.netflixjp, torguard.netflixkr, torguard.netflixmx, torguard.netflixnl, torguard.netflixse, torguard.netflixuk, torguard.netflixus]
      case "mullvad": return [mullvad.netflixau, mullvad.netflixbr, mullvad.netflixca, mullvad.netflixfr, mullvad.netflixde, mullvad.netflixhk, mullvad.netflixin, mullvad.netflixil, mullvad.netflixjp, mullvad.netflixkr, mullvad.netflixmx, mullvad.netflixnl, mullvad.netflixse, mullvad.netflixuk, mullvad.netflixus]
      case "express": return [express.netflixau, express.netflixbr, express.netflixca, express.netflixfr, express.netflixde, express.netflixhk, express.netflixin, express.netflixil, express.netflixjp, express.netflixkr, express.netflixmx, express.netflixnl, express.netflixse, express.netflixuk, express.netflixus]
      case "proton": return [proton.netflixau, proton.netflixbr, proton.netflixca, proton.netflixfr, proton.netflixde, proton.netflixhk, proton.netflixin, proton.netflixil, proton.netflixjp, proton.netflixkr, proton.netflixmx, proton.netflixnl, proton.netflixse, proton.netflixuk, proton.netflixus]
      case "celo": return [celo.netflixau, celo.netflixbr, celo.netflixca, celo.netflixfr, celo.netflixde, celo.netflixhk, celo.netflixin, celo.netflixil, celo.netflixjp, celo.netflixkr, celo.netflixmx, celo.netflixnl, celo.netflixse, celo.netflixuk, celo.netflixus]
      default: return [pia.netflixau, pia.netflixbr, pia.netflixca, pia.netflixfr, pia.netflixde, pia.netflixhk, pia.netflixin, pia.netflixil, pia.netflixjp, pia.netflixkr, pia.netflixmx, pia.netflixnl, pia.netflixse, pia.netflixuk, pia.netflixus]
    }
  }

  function getNetflixAu(aff) {
    switch (aff) {
      case "pia": return pia.netflixau
      case "ivacy": return ivacy.netflixau
      case "namecheap": return namecheap.netflixau
      case "vypr": return vypr.netflixau
      case "pure": return pure.netflixau
      case "nord": return nord.netflixau
      case "surfshark": return surfshark.netflixau
      case "cyberghost": return cyberghost.netflixau
      case "windscribe": return windscribe.netflixau
      case "torguard": return torguard.netflixau
      case "mullvad": return mullvad.netflixau
      case "express": return express.netflixau
      case "proton": return proton.netflixau
      case "celo": return celo.netflixau
      default: return pia.netflixau
    }
  }

  function getNetflixBr(aff) {
    switch (aff) {
      case "pia": return pia.netflixbr
      case "ivacy": return ivacy.netflixbr
      case "namecheap": return namecheap.netflixbr
      case "vypr": return vypr.netflixbr
      case "pure": return pure.netflixbr
      case "nord": return nord.netflixbr
      case "surfshark": return surfshark.netflixbr
      case "cyberghost": return cyberghost.netflixbr
      case "windscribe": return windscribe.netflixbr
      case "torguard": return torguard.netflixbr
      case "mullvad": return mullvad.netflixbr
      case "express": return express.netflixbr
      case "proton": return proton.netflixbr
      case "celo": return celo.netflixbr
      default: return pia.netflixbr
    }
  }  

  function getNetflixCa(aff) {
    switch (aff) {
      case "pia": return pia.netflixca
      case "ivacy": return ivacy.netflixca
      case "namecheap": return namecheap.netflixca
      case "vypr": return vypr.netflixca
      case "pure": return pure.netflixca
      case "nord": return nord.netflixca
      case "surfshark": return surfshark.netflixca
      case "cyberghost": return cyberghost.netflixca
      case "windscribe": return windscribe.netflixca
      case "torguard": return torguard.netflixca
      case "mullvad": return mullvad.netflixca
      case "express": return express.netflixca
      case "proton": return proton.netflixca
      case "celo": return celo.netflixca
      default: return pia.netflixca
    }
  }

  function getNetflixFr(aff) {
    switch (aff) {
      case "pia": return pia.netflixfr
      case "ivacy": return ivacy.netflixfr
      case "namecheap": return namecheap.netflixfr
      case "vypr": return vypr.netflixfr
      case "pure": return pure.netflixfr
      case "nord": return nord.netflixfr
      case "surfshark": return surfshark.netflixfr
      case "cyberghost": return cyberghost.netflixfr
      case "windscribe": return windscribe.netflixfr
      case "torguard": return torguard.netflixfr
      case "mullvad": return mullvad.netflixfr
      case "express": return express.netflixfr
      case "proton": return proton.netflixfr
      case "celo": return celo.netflixfr
      default: return pia.netflixfr
    }
  }

  function getNetflixDe(aff) {
    switch (aff) {
      case "pia": return pia.netflixde
      case "ivacy": return ivacy.netflixde
      case "namecheap": return namecheap.netflixde
      case "vypr": return vypr.netflixde
      case "pure": return pure.netflixde
      case "nord": return nord.netflixde
      case "surfshark": return surfshark.netflixde
      case "cyberghost": return cyberghost.netflixde
      case "windscribe": return windscribe.netflixde
      case "torguard": return torguard.netflixde
      case "mullvad": return mullvad.netflixde
      case "express": return express.netflixde
      case "proton": return proton.netflixde
      case "celo": return celo.netflixde
      default: return pia.netflixde
    }
  }

  function getNetflixHk(aff) {
    switch (aff) {
      case "pia": return pia.netflixhk
      case "ivacy": return ivacy.netflixhk
      case "namecheap": return namecheap.netflixhk
      case "vypr": return vypr.netflixhk
      case "pure": return pure.netflixhk
      case "nord": return nord.netflixhk
      case "surfshark": return surfshark.netflixhk
      case "cyberghost": return cyberghost.netflixhk
      case "windscribe": return windscribe.netflixhk
      case "torguard": return torguard.netflixhk
      case "mullvad": return mullvad.netflixhk
      case "express": return express.netflixhk
      case "proton": return proton.netflixhk
      case "celo": return celo.netflixhk
      default: return pia.netflixhk
    }
  }

  function getNetflixIn(aff) {
    switch (aff) {
      case "pia": return pia.netflixin
      case "ivacy": return ivacy.netflixin
      case "namecheap": return namecheap.netflixin
      case "vypr": return vypr.netflixin
      case "pure": return pure.netflixin
      case "nord": return nord.netflixin
      case "surfshark": return surfshark.netflixin
      case "cyberghost": return cyberghost.netflixin
      case "windscribe": return windscribe.netflixin
      case "torguard": return torguard.netflixin
      case "mullvad": return mullvad.netflixin
      case "express": return express.netflixin
      case "proton": return proton.netflixin
      case "celo": return celo.netflixin
      default: return pia.netflixin
    }
  }

  function getNetflixIl(aff) {
    switch (aff) {
      case "pia": return pia.netflixil
      case "ivacy": return ivacy.netflixil
      case "namecheap": return namecheap.netflixil
      case "vypr": return vypr.netflixil
      case "pure": return pure.netflixil
      case "nord": return nord.netflixil
      case "surfshark": return surfshark.netflixil
      case "cyberghost": return cyberghost.netflixil
      case "windscribe": return windscribe.netflixil
      case "torguard": return torguard.netflixil
      case "mullvad": return mullvad.netflixil
      case "express": return express.netflixil
      case "proton": return proton.netflixil
      case "celo": return celo.netflixil
      default: return pia.netflixil
    }
  }

  function getNetflixJp(aff) {
    switch (aff) {
      case "pia": return pia.netflixjp
      case "ivacy": return ivacy.netflixjp
      case "namecheap": return namecheap.netflixjp
      case "vypr": return vypr.netflixjp
      case "pure": return pure.netflixjp
      case "nord": return nord.netflixjp
      case "surfshark": return surfshark.netflixjp
      case "cyberghost": return cyberghost.netflixjp
      case "windscribe": return windscribe.netflixjp
      case "torguard": return torguard.netflixjp
      case "mullvad": return mullvad.netflixjp
      case "express": return express.netflixjp
      case "proton": return proton.netflixjp
      case "celo": return celo.netflixjp
      default: return pia.netflixjp
    }
  }

  function getNetflixKr(aff) {
    switch (aff) {
      case "pia": return pia.netflixkr
      case "ivacy": return ivacy.netflixkr
      case "namecheap": return namecheap.netflixkr
      case "vypr": return vypr.netflixkr
      case "pure": return pure.netflixkr
      case "nord": return nord.netflixkr
      case "surfshark": return surfshark.netflixkr
      case "cyberghost": return cyberghost.netflixkr
      case "windscribe": return windscribe.netflixkr
      case "torguard": return torguard.netflixkr
      case "mullvad": return mullvad.netflixkr
      case "express": return express.netflixkr
      case "proton": return proton.netflixkr
      case "celo": return celo.netflixkr
      default: return pia.netflixkr
    }
  }

  function getNetflixMx(aff) {
    switch (aff) {
      case "pia": return pia.netflixmx
      case "ivacy": return ivacy.netflixmx
      case "namecheap": return namecheap.netflixmx
      case "vypr": return vypr.netflixmx
      case "pure": return pure.netflixmx
      case "nord": return nord.netflixmx
      case "surfshark": return surfshark.netflixmx
      case "cyberghost": return cyberghost.netflixmx
      case "windscribe": return windscribe.netflixmx
      case "torguard": return torguard.netflixmx
      case "mullvad": return mullvad.netflixmx
      case "express": return express.netflixmx
      case "proton": return proton.netflixmx
      case "celo": return celo.netflixmx
      default: return pia.netflixmx
    }
  }

  function getNetflixNl(aff) {
    switch (aff) {
      case "pia": return pia.netflixnl
      case "ivacy": return ivacy.netflixnl
      case "namecheap": return namecheap.netflixnl
      case "vypr": return vypr.netflixnl
      case "pure": return pure.netflixnl
      case "nord": return nord.netflixnl
      case "surfshark": return surfshark.netflixnl
      case "cyberghost": return cyberghost.netflixnl
      case "windscribe": return windscribe.netflixnl
      case "torguard": return torguard.netflixnl
      case "mullvad": return mullvad.netflixnl
      case "express": return express.netflixnl
      case "proton": return proton.netflixnl
      case "celo": return celo.netflixnl
      default: return pia.netflixnl
    }
  }
  
  function getNetflixSe(aff) {
    switch (aff) {
      case "pia": return pia.netflixse
      case "ivacy": return ivacy.netflixse
      case "namecheap": return namecheap.netflixse
      case "vypr": return vypr.netflixse
      case "pure": return pure.netflixse
      case "nord": return nord.netflixse
      case "surfshark": return surfshark.netflixse
      case "cyberghost": return cyberghost.netflixse
      case "windscribe": return windscribe.netflixse
      case "torguard": return torguard.netflixse
      case "mullvad": return mullvad.netflixse
      case "express": return express.netflixse
      case "proton": return proton.netflixse
      case "celo": return celo.netflixse
      default: return pia.netflixse
    }
  }
  
  function getNetflixUk(aff) {
    switch (aff) {
      case "pia": return pia.netflixuk
      case "ivacy": return ivacy.netflixuk
      case "namecheap": return namecheap.netflixuk
      case "vypr": return vypr.netflixuk
      case "pure": return pure.netflixuk
      case "nord": return nord.netflixuk
      case "surfshark": return surfshark.netflixuk
      case "cyberghost": return cyberghost.netflixuk
      case "windscribe": return windscribe.netflixuk
      case "torguard": return torguard.netflixuk
      case "mullvad": return mullvad.netflixuk
      case "express": return express.netflixuk
      case "proton": return proton.netflixuk
      case "celo": return celo.netflixuk
      default: return pia.netflixuk
    }
  }

  function getNetflixUs(aff) {
    switch (aff) {
      case "pia": return pia.netflixus
      case "ivacy": return ivacy.netflixus
      case "namecheap": return namecheap.netflixus
      case "vypr": return vypr.netflixus
      case "pure": return pure.netflixus
      case "nord": return nord.netflixus
      case "surfshark": return surfshark.netflixus
      case "cyberghost": return cyberghost.netflixus
      case "windscribe": return windscribe.netflixus
      case "torguard": return torguard.netflixus
      case "mullvad": return mullvad.netflixus
      case "express": return express.netflixus
      case "proton": return proton.netflixus
      case "celo": return celo.netflixus
      default: return pia.netflixus
    }
  } 

  function getshowau(aff) {
    switch (aff) {
      case "pia": return showau[Math.floor(Math.random() * showau.length)];
      case "ivacy": return showau[Math.floor(Math.random() * showau.length)];
      case "namecheap": return showau[Math.floor(Math.random() * showau.length)];
      case "vypr": return showau[Math.floor(Math.random() * showau.length)];
      case "pure": return showau[Math.floor(Math.random() * showau.length)];
      case "nord": return showau[Math.floor(Math.random() * showau.length)];
      case "surfshark": return showau[Math.floor(Math.random() * showau.length)];
      case "cyberghost": return showau[Math.floor(Math.random() * showau.length)];
      case "windscribe": return showau[Math.floor(Math.random() * showau.length)];
      case "torguard": return showau[Math.floor(Math.random() * showau.length)];
      case "mullvad": return showau[Math.floor(Math.random() * showau.length)];
      case "express": return showau[Math.floor(Math.random() * showau.length)];
      case "proton": return showau[Math.floor(Math.random() * showau.length)];
      case "celo": return showau[Math.floor(Math.random() * showau.length)];
      default: return showau[Math.floor(Math.random() * showau.length)];
    }
  }

  function getshowbr(aff) {
    switch (aff) {
      case "pia": return showbr[Math.floor(Math.random() * showbr.length)];
      case "ivacy": return showbr[Math.floor(Math.random() * showbr.length)];
      case "namecheap": return showbr[Math.floor(Math.random() * showbr.length)];
      case "vypr": return showbr[Math.floor(Math.random() * showbr.length)];
      case "pure": return showbr[Math.floor(Math.random() * showbr.length)];
      case "nord": return showbr[Math.floor(Math.random() * showbr.length)];
      case "surfshark": return showbr[Math.floor(Math.random() * showbr.length)];
      case "cyberghost": return showbr[Math.floor(Math.random() * showbr.length)];
      case "windscribe": return showbr[Math.floor(Math.random() * showbr.length)];
      case "torguard": return showbr[Math.floor(Math.random() * showbr.length)];
      case "mullvad": return showbr[Math.floor(Math.random() * showbr.length)];
      case "express": return showbr[Math.floor(Math.random() * showbr.length)];
      case "proton": return showbr[Math.floor(Math.random() * showbr.length)];
      case "celo": return showbr[Math.floor(Math.random() * showbr.length)];
      default: return showbr[Math.floor(Math.random() * showbr.length)];
    }
  }

  function getshowca(aff) {
    switch (aff) {
      case "pia": return showca[Math.floor(Math.random() * showca.length)];
      case "ivacy": return "Better Call Saul (2015)";
      case "namecheap": return showca[Math.floor(Math.random() * showca.length)];
      case "vypr": return showca[Math.floor(Math.random() * showca.length)];
      case "pure": return showca[Math.floor(Math.random() * showca.length)];
      case "nord": return showca[Math.floor(Math.random() * showca.length)];
      case "surfshark": return showca[Math.floor(Math.random() * showca.length)];
      case "cyberghost": return showca[Math.floor(Math.random() * showca.length)];
      case "windscribe": return showca[Math.floor(Math.random() * showca.length)];
      case "torguard": return showca[Math.floor(Math.random() * showca.length)];
      case "mullvad": return showca[Math.floor(Math.random() * showca.length)];
      case "express": return showca[Math.floor(Math.random() * showca.length)];
      case "proton": return showca[Math.floor(Math.random() * showca.length)];
      case "celo": return showca[Math.floor(Math.random() * showca.length)];
      default: return showca[Math.floor(Math.random() * showca.length)];
    }
  }
  
  function getshowfr(aff) {
    switch (aff) {
      case "pia": return showfr[Math.floor(Math.random() * showfr.length)];
      case "ivacy": return showfr[Math.floor(Math.random() * showfr.length)];
      case "namecheap": return showfr[Math.floor(Math.random() * showfr.length)];
      case "vypr": return showfr[Math.floor(Math.random() * showfr.length)];
      case "pure": return showfr[Math.floor(Math.random() * showfr.length)];
      case "nord": return showfr[Math.floor(Math.random() * showfr.length)];
      case "surfshark": return showfr[Math.floor(Math.random() * showfr.length)];
      case "cyberghost": return showfr[Math.floor(Math.random() * showfr.length)];
      case "windscribe": return showfr[Math.floor(Math.random() * showfr.length)];
      case "torguard": return showfr[Math.floor(Math.random() * showfr.length)];
      case "mullvad": return showfr[Math.floor(Math.random() * showfr.length)];
      case "express": return showfr[Math.floor(Math.random() * showfr.length)];
      case "proton": return showfr[Math.floor(Math.random() * showfr.length)];
      case "celo": return showfr[Math.floor(Math.random() * showfr.length)];
      default: return showfr[Math.floor(Math.random() * showfr.length)];
    }
  }
  
  function getshowde(aff) {
    switch (aff) {
      case "pia": return showde[Math.floor(Math.random() * showde.length)];
      case "ivacy": return showde[Math.floor(Math.random() * showde.length)];
      case "namecheap": return showde[Math.floor(Math.random() * showde.length)];
      case "vypr": return "24 (2001)";
      case "pure": return showde[Math.floor(Math.random() * showde.length)];
      case "nord": return showde[Math.floor(Math.random() * showde.length)];
      case "surfshark": return showde[Math.floor(Math.random() * showde.length)];
      case "cyberghost": return showde[Math.floor(Math.random() * showde.length)];
      case "windscribe": return showde[Math.floor(Math.random() * showde.length)];
      case "torguard": return showde[Math.floor(Math.random() * showde.length)];
      case "mullvad": return showde[Math.floor(Math.random() * showde.length)];
      case "express": return showde[Math.floor(Math.random() * showde.length)];
      case "proton": return showde[Math.floor(Math.random() * showde.length)];
      case "celo": return showde[Math.floor(Math.random() * showde.length)];
      default: return showde[Math.floor(Math.random() * showde.length)];
    }
  }

  function getshowhk(aff) {
    switch (aff) {
      case "pia": return showhk[Math.floor(Math.random() * showhk.length)];
      case "ivacy": return showhk[Math.floor(Math.random() * showhk.length)];
      case "namecheap": return showhk[Math.floor(Math.random() * showhk.length)];
      case "vypr": return showhk[Math.floor(Math.random() * showhk.length)];
      case "pure": return showhk[Math.floor(Math.random() * showhk.length)];
      case "nord": return showhk[Math.floor(Math.random() * showhk.length)];
      case "surfshark": return showhk[Math.floor(Math.random() * showhk.length)];
      case "cyberghost": return showhk[Math.floor(Math.random() * showhk.length)];
      case "windscribe": return showhk[Math.floor(Math.random() * showhk.length)];
      case "torguard": return showhk[Math.floor(Math.random() * showhk.length)];
      case "mullvad": return showhk[Math.floor(Math.random() * showhk.length)];
      case "express": return showhk[Math.floor(Math.random() * showhk.length)];
      case "proton": return showhk[Math.floor(Math.random() * showhk.length)];
      case "celo": return showhk[Math.floor(Math.random() * showhk.length)];
      default: return showhk[Math.floor(Math.random() * showhk.length)];
    }
  }
  
  function getshowin(aff) {
    switch (aff) {
      case "pia": return showin[Math.floor(Math.random() * showin.length)];
      case "ivacy": return showin[Math.floor(Math.random() * showin.length)];
      case "namecheap": return showin[Math.floor(Math.random() * showin.length)];
      case "vypr": return showin[Math.floor(Math.random() * showin.length)];
      case "pure": return showin[Math.floor(Math.random() * showin.length)];
      case "nord": return showin[Math.floor(Math.random() * showin.length)];
      case "surfshark": return showin[Math.floor(Math.random() * showin.length)];
      case "cyberghost": return showin[Math.floor(Math.random() * showin.length)];
      case "windscribe": return showin[Math.floor(Math.random() * showin.length)];
      case "torguard": return showin[Math.floor(Math.random() * showin.length)];
      case "mullvad": return showin[Math.floor(Math.random() * showin.length)];
      case "express": return showin[Math.floor(Math.random() * showin.length)];
      case "proton": return showin[Math.floor(Math.random() * showin.length)];
      case "celo": return showin[Math.floor(Math.random() * showin.length)];
      default: return showin[Math.floor(Math.random() * showin.length)];
    }
  }

  function getshowil(aff) {
    switch (aff) {
      case "pia": return showil[Math.floor(Math.random() * showil.length)];
      case "ivacy": return showil[Math.floor(Math.random() * showil.length)];
      case "namecheap": return showil[Math.floor(Math.random() * showil.length)];
      case "vypr": return showil[Math.floor(Math.random() * showil.length)];
      case "pure": return showil[Math.floor(Math.random() * showil.length)];
      case "nord": return showil[Math.floor(Math.random() * showil.length)];
      case "surfshark": return showil[Math.floor(Math.random() * showil.length)];
      case "cyberghost": return showil[Math.floor(Math.random() * showil.length)];
      case "windscribe": return showil[Math.floor(Math.random() * showil.length)];
      case "torguard": return showil[Math.floor(Math.random() * showil.length)];
      case "mullvad": return showil[Math.floor(Math.random() * showil.length)];
      case "express": return showil[Math.floor(Math.random() * showil.length)];
      case "proton": return showil[Math.floor(Math.random() * showil.length)];
      case "celo": return showil[Math.floor(Math.random() * showil.length)];
      default: return showil[Math.floor(Math.random() * showil.length)];
    }
  }

  function getshowjp(aff) {
    switch (aff) {
      case "pia": return showjp[Math.floor(Math.random() * showjp.length)];
      case "ivacy": return showjp[Math.floor(Math.random() * showjp.length)];
      case "namecheap": return "The Dark Knight (2008)";
      case "vypr": return showjp[Math.floor(Math.random() * showjp.length)];
      case "pure": return showjp[Math.floor(Math.random() * showjp.length)];
      case "nord": return showjp[Math.floor(Math.random() * showjp.length)];
      case "surfshark": return showjp[Math.floor(Math.random() * showjp.length)];
      case "cyberghost": return showjp[Math.floor(Math.random() * showjp.length)];
      case "windscribe": return showjp[Math.floor(Math.random() * showjp.length)];
      case "torguard": return showjp[Math.floor(Math.random() * showjp.length)];
      case "mullvad": return showjp[Math.floor(Math.random() * showjp.length)];
      case "express": return showjp[Math.floor(Math.random() * showjp.length)];
      case "proton": return showjp[Math.floor(Math.random() * showjp.length)];
      case "celo": return showjp[Math.floor(Math.random() * showjp.length)];
      default: return showjp[Math.floor(Math.random() * showjp.length)];
    }
  }

  function getshowkr(aff) {
    switch (aff) {
      case "pia": return showkr[Math.floor(Math.random() * showkr.length)];
      case "ivacy": return showkr[Math.floor(Math.random() * showkr.length)];
      case "namecheap": return showkr[Math.floor(Math.random() * showkr.length)];
      case "vypr": return showkr[Math.floor(Math.random() * showkr.length)];
      case "pure": return showkr[Math.floor(Math.random() * showkr.length)];
      case "nord": return showkr[Math.floor(Math.random() * showkr.length)];
      case "surfshark": return showkr[Math.floor(Math.random() * showkr.length)];
      case "cyberghost": return showkr[Math.floor(Math.random() * showkr.length)];
      case "windscribe": return showkr[Math.floor(Math.random() * showkr.length)];
      case "torguard": return showkr[Math.floor(Math.random() * showkr.length)];
      case "mullvad": return showkr[Math.floor(Math.random() * showkr.length)];
      case "express": return showkr[Math.floor(Math.random() * showkr.length)];
      case "proton": return showkr[Math.floor(Math.random() * showkr.length)];
      case "celo": return showkr[Math.floor(Math.random() * showkr.length)];
      default: return showkr[Math.floor(Math.random() * showkr.length)];
    }
  }

  function getshowmx(aff) {
    switch (aff) {
      case "pia": return showmx[Math.floor(Math.random() * showmx.length)];
      case "ivacy": return showmx[Math.floor(Math.random() * showmx.length)];
      case "namecheap": return showmx[Math.floor(Math.random() * showmx.length)];
      case "vypr": return showmx[Math.floor(Math.random() * showmx.length)];
      case "pure": return showmx[Math.floor(Math.random() * showmx.length)];
      case "nord": return showmx[Math.floor(Math.random() * showmx.length)];
      case "surfshark": return showmx[Math.floor(Math.random() * showmx.length)];
      case "cyberghost": return showmx[Math.floor(Math.random() * showmx.length)];
      case "windscribe": return showmx[Math.floor(Math.random() * showmx.length)];
      case "torguard": return showmx[Math.floor(Math.random() * showmx.length)];
      case "mullvad": return showmx[Math.floor(Math.random() * showmx.length)];
      case "express": return showmx[Math.floor(Math.random() * showmx.length)];
      case "proton": return showmx[Math.floor(Math.random() * showmx.length)];
      case "celo": return showmx[Math.floor(Math.random() * showmx.length)];
      default: return showmx[Math.floor(Math.random() * showmx.length)];
    }
  }

  function getshownl(aff) {
    switch (aff) {
      case "pia": return "The Bridge (2011)";
      case "ivacy": return shownl[Math.floor(Math.random() * shownl.length)];
      case "namecheap": return shownl[Math.floor(Math.random() * shownl.length)];
      case "vypr": return shownl[Math.floor(Math.random() * shownl.length)];
      case "pure": return shownl[Math.floor(Math.random() * shownl.length)];
      case "nord": return shownl[Math.floor(Math.random() * shownl.length)];
      case "surfshark": return shownl[Math.floor(Math.random() * shownl.length)];
      case "cyberghost": return shownl[Math.floor(Math.random() * shownl.length)];
      case "windscribe": return shownl[Math.floor(Math.random() * shownl.length)];
      case "torguard": return shownl[Math.floor(Math.random() * shownl.length)];
      case "mullvad": return shownl[Math.floor(Math.random() * shownl.length)];
      case "express": return shownl[Math.floor(Math.random() * shownl.length)];
      case "proton": return shownl[Math.floor(Math.random() * shownl.length)];
      case "celo": return shownl[Math.floor(Math.random() * shownl.length)];
      default: return shownl[Math.floor(Math.random() * shownl.length)];
    }
  }

  function getshowse(aff) {
    switch (aff) {
      case "pia": return showse[Math.floor(Math.random() * showse.length)];
      case "ivacy": return showse[Math.floor(Math.random() * showse.length)];
      case "namecheap": return showse[Math.floor(Math.random() * showse.length)];
      case "vypr": return showse[Math.floor(Math.random() * showse.length)];
      case "pure": return showse[Math.floor(Math.random() * showse.length)];
      case "nord": return showse[Math.floor(Math.random() * showse.length)];
      case "surfshark": return showse[Math.floor(Math.random() * showse.length)];
      case "cyberghost": return showse[Math.floor(Math.random() * showse.length)];
      case "windscribe": return showse[Math.floor(Math.random() * showse.length)];
      case "torguard": return showse[Math.floor(Math.random() * showse.length)];
      case "mullvad": return showse[Math.floor(Math.random() * showse.length)];
      case "express": return showse[Math.floor(Math.random() * showse.length)];
      case "proton": return showse[Math.floor(Math.random() * showse.length)];
      case "celo": return showse[Math.floor(Math.random() * showse.length)];
      default: return showse[Math.floor(Math.random() * showse.length)];
    }
  }
  
  function getshowuk(aff) {
    switch (aff) {
      case "pia": return showuk[Math.floor(Math.random() * showuk.length)];
      case "ivacy": return "It’s Always Sunny in Philadelphia (2005)";
      case "namecheap": return showuk[Math.floor(Math.random() * showuk.length)];
      case "vypr": return showuk[Math.floor(Math.random() * showuk.length)];
      case "pure": return showuk[Math.floor(Math.random() * showuk.length)];
      case "nord": return showuk[Math.floor(Math.random() * showuk.length)];
      case "surfshark": return showuk[Math.floor(Math.random() * showuk.length)];
      case "cyberghost": return showuk[Math.floor(Math.random() * showuk.length)];
      case "windscribe": return showuk[Math.floor(Math.random() * showuk.length)];
      case "torguard": return showuk[Math.floor(Math.random() * showuk.length)];
      case "mullvad": return showuk[Math.floor(Math.random() * showuk.length)];
      case "express": return showuk[Math.floor(Math.random() * showuk.length)];
      case "proton": return showuk[Math.floor(Math.random() * showuk.length)];
      case "celo": return showuk[Math.floor(Math.random() * showuk.length)];
      default: return showuk[Math.floor(Math.random() * showuk.length)];
    }
  }

  function getshowus(aff) {
    switch (aff) {
      case "pia": return "The Office (US Version) (2005)";
      case "ivacy": return showus[Math.floor(Math.random() * showus.length)];
      case "namecheap": return showus[Math.floor(Math.random() * showus.length)];
      case "vypr": return showus[Math.floor(Math.random() * showus.length)];
      case "pure": return showus[Math.floor(Math.random() * showus.length)];
      case "nord": return showus[Math.floor(Math.random() * showus.length)];
      case "surfshark": return showus[Math.floor(Math.random() * showus.length)];
      case "cyberghost": return showus[Math.floor(Math.random() * showus.length)];
      case "windscribe": return showus[Math.floor(Math.random() * showus.length)];
      case "torguard": return showus[Math.floor(Math.random() * showus.length)];
      case "mullvad": return showus[Math.floor(Math.random() * showus.length)];
      case "express": return showus[Math.floor(Math.random() * showus.length)];
      case "proton": return showus[Math.floor(Math.random() * showus.length)];
      case "celo": return showus[Math.floor(Math.random() * showus.length)];
      default: return showus[Math.floor(Math.random() * showus.length)];
    }
  }

  function countryString(vpn_array) {
    let array = []
    for (let i = 0; i < vpn_array.length; i++) {
      if (vpn_array[i] === "Success") {
        array.push(vpn_countries[i])
      }
    }
    let delimiter = ", ";
    let string = array.join(delimiter);
    let index = string.lastIndexOf(delimiter);
    let output = string.substring(0, index) + ' and ' + string.substring(index + delimiter.length);
    if (string.length === 0) {
      return "I couldn't watch any geo-restricted Netflix content."
    } else {

      return "I could watch Netflix content exclusive to " + output + "."
    }

  }

  function getColors(result) {
    switch (result) {
      case 'Success': return `green4`;
      case 'Fail': return `red4`;
      case 'No access': return `blue4`;
      default: return `indigo4`;
    }
  }

  return (
    <>
      <SummarySnippet>
        <p>{getVpnName(props.aff)} was able to successfully unblocked geo-restricted Netflix content in <strong>{getNetflixInt(props.aff)} out of {getNetflixCountries(props.aff)} countries</strong>. {countryString(getVpnResultsArray(props.aff))}</p>
      </SummarySnippet>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Netflix Unblock Test - {getVpnName(props.aff)}</h4>
        <div class="bt b--gray2">

          <div class="overflow-auto">
            <table class="f6 w-100 mw8 center" cellspacing="0">
              <thead>
                <tr>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">Country</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">Result</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">Example Movie / TV Show</th>
                </tr>
              </thead>
              <tbody class="lh-copy">
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Australia</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixAu(props.aff))}`}>{getNetflixAu(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowau(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Brazil</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixBr(props.aff))}`}>{getNetflixBr(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowbr(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Canada</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixCa(props.aff))}`}>{getNetflixCa(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowca(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">France</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixFr(props.aff))}`}>{getNetflixFr(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowfr(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Germany</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixDe(props.aff))}`}>{getNetflixDe(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowde(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Hong Kong</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixHk(props.aff))}`}>{getNetflixHk(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowhk(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">India</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixIn(props.aff))}`}>{getNetflixIn(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowin(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Israel</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixIl(props.aff))}`}>{getNetflixIl(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowil(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Japan</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixJp(props.aff))}`}>{getNetflixJp(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowjp(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Korea</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixKr(props.aff))}`}>{getNetflixKr(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowkr(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Mexico</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixMx(props.aff))}`}>{getNetflixMx(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowmx(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Netherlands</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixNl(props.aff))}`}>{getNetflixNl(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshownl(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Sweden</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixSe(props.aff))}`}>{getNetflixSe(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowse(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">United Kingdom</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixUk(props.aff))}`}>{getNetflixUk(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowuk(props.aff)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">USA</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNetflixUs(props.aff))}`}>{getNetflixUs(props.aff)}</td>
                  <td class="avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white">{getshowus(props.aff)}</td>
                </tr>
              </tbody>
            </table>
          </div>


        </div>
      </section>
    </>
  )
}

export default GetNetflixResults