import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Hero from "../components/hero"
import Article from "../components/article"

const LearnIndex = ({ data }) => {
  const posts = data.allMdx.edges

  return (
    <Layout>
      <SEO 
        title="Learn About VPNs - VPN Knowledgebase"
        description="083.org can help you learn about VPNs. Browse our articles and guides. Understand VPN technology and use cases."
      />
      <Hero
        heading="Learn about VPNs"
        byline="Everything you need to know to help you understand Virtual Private Networks"
      />
      <section className="center mw8-l">
        <div className="pv4 ph4 mv0">
          <h2 className="avenir">Latest Articles</h2>
        </div>
        {posts.map(({ node }) => {
          return (
            <Article
              key={node.fields.slug}
              title={node.frontmatter.heading1}
              link={node.fields.slug}
              excerpt={node.frontmatter.excerpt}
              last_modified={node.frontmatter.last_modified_date}
              image={node.frontmatter.image.childImageSharp.fluid}
              time_to_read={node.fields.readingTime.text}
              alt={node.frontmatter.alt}
            />
          )
        })}
      </section>
    </Layout>
  )
}

export default LearnIndex

export const pageQuery = graphql`
  query {
    allMdx(filter: {frontmatter: {status: {eq: "active"}}, fileAbsolutePath: {regex: "\\/learn/"}}, sort: {fields: frontmatter___last_modified_date, order: DESC}) {
      edges {
        node {
          excerpt
          fields {
            slug
          }
          frontmatter {
            published_date(formatString: "MMMM DD, YYYY")
            last_modified_date(formatString: "MMMM DD, YYYY")
            title
            heading1
            description
            excerpt
            status
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
              }
            }
          }
          fields {
            readingTime {
              text
            }
          }
        }
      }
    }
  }
`
