import React from "react"
import { Link, graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Bio from "../components/bio.js"
import Layout from "../components/layout.js"
import SEO from "../components/seo.js"
import Hero from "../components/hero.js"
import Breadcrumb from "../components/breadcrumb.js"
import AffiliateDisclaimer from "../components/affiliateDisclosure.js"

const BlogPostTemplate = ({ data, pageContext, location }) => {
  const post = data.mdx
  const siteTitle = data.site.siteMetadata.title
  const { previous, next } = pageContext
  const slug = data.mdx.fields.slug.split("/")[1]
  const slugCapitalized = slug.charAt(0).toUpperCase() + slug.slice(1)
  const headings = data.mdx.tableOfContents
 
  return (
    <Layout location={location} title={siteTitle}>
      <SEO
        title={post.frontmatter.title}
        description={post.frontmatter.description}
        robots={post.frontmatter.status}
      />
      <article>
        <Hero
          heading={post.frontmatter.heading1}
          byline={post.frontmatter.excerpt}
        />
        <main className="pa4 ph7-l mw9-l center" id="post">
          <Breadcrumb heading={post.frontmatter.heading1} slug={slug} category={slugCapitalized} />
          <MDXRenderer mx={16} my={4} headings={headings}>{post.body}</MDXRenderer>
        </main>
        <footer>
          <Bio />
          <AffiliateDisclaimer />
        </footer>
      </article>

      <nav className="bg-gray1 bt b--gray2">
        <ul className="flex justify-between-ns flex-column flex-row-ns pa4 ph7-l mw9-l center mv0 pt2 list">
          <li>
            {previous && (
              <div>
                <h5 className="f6 ttu tracked dark-gray">&larr; Previous</h5>
                <Link to={previous.fields.slug} rel="prev" className="avenir primary5 no-underline underline-hover hover-primary7">
                  {previous.frontmatter.heading1}
                </Link>
              </div>
            )}
          </li>
          <li>
            {next && (
              <div>
                <h5 className="f6 ttu tracked dark-gray">Next &rarr;</h5>
                <Link to={next.fields.slug} rel="next" className="avenir primary5 no-underline underline-hover hover-primary7">
                  {next.frontmatter.heading1}
                </Link>
              </div>
            )}
          </li>
        </ul>
      </nav>
    </Layout>
  )
}

export default BlogPostTemplate

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    mdx(fields: { slug: { eq: $slug } }) {
      id
      excerpt(pruneLength: 160)
      tableOfContents
      body
      fields {
        slug
      }
      frontmatter {
        title
        heading1
        published_date(formatString: "MMMM DD, YYYY")
        description
        excerpt
        status
        image {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    }
  }
`
