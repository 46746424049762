import React from "react"
import { OutboundLink } from 'gatsby-plugin-google-analytics'

const AffiliateLink = (props) => {

  let url = props.url
  let affiliate = props.affiliate
  let type = props.type

  function renderAffiliateLink(affiliate) {
    switch(affiliate) {
      case 'pia': return `https://www.privateinternetaccess.com/?aff=083.org`;
      case 'ivacy': return `https://billing.ivacy.com/page/92811`;
      case 'namecheap': return `https://namecheap.pxf.io/2ANx7`;
      case 'vypr': return `https://www.dpbolvw.net/click-100183195-13886392`;
      case 'pure': return `https://www.dpbolvw.net/click-100183195-13842739`;
      case 'nord': return `https://www.jdoqocy.com/click-100183195-13609939`;
      case 'surfshark': return `https://get.surfshark.net/aff_c?offer_id=6&aff_id=4842`;
      case 'cyberghost': return `https://www.anrdoezrs.net/click-100183195-13820894`;
      case 'windscribe': return `https://windscribe.com/?affid=32pq8rz9`;
      case 'torguard': return `https://torguard.net/aff.php?aff=6572`;
      case 'mullvad': return `https://mullvad.net/`;
      case 'express': return `https://www.xvbelink.com/?a_fid=083org`;
      case 'proton': return `https://proton.go2cloud.org/aff_c?offer_id=10&aff_id=1904`;
      default: return url;
    }
  }

  function renderAffiliateStyles(style) {
    switch(style) {
      case 'button': return `f6 link br2 pa3-ns pa2 mb0 dib hover-bg-primary7 white bg-primary5 avenir`;
      case 'sticky': return `sticky-sidelink f6 link br2 pa3-ns pa2 mb0 dib hover-bg-primary7 white bg-primary5 avenir tc cb`;
      case 'link': return `primary5 no-underline underline-hover hover-primary7`;
      case 'naked': return `no-underline no-underline-hover`;
      case 'table': return `black no-underline underline-hover`;
      default: return ``
    }
  }

  return (
    <OutboundLink
      href={renderAffiliateLink(affiliate)} 
      rel="sponsored noopener noreferrer" 
      target="_blank"
      className={renderAffiliateStyles(type)}
    >
      {props.children}
    </OutboundLink>
  )
}

export default AffiliateLink