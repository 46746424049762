import React from "react"

const ThemeReviewIntro = (props) => {

  return (
    <>
      <div className="mid-gray">
        <h2 className="f5 ttu tracked dark-gray">Introduction</h2>
        {props.children}
      </div>
    </>
  )
}

export default ThemeReviewIntro