import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const AllNetflixResults = () => {

  const data = useStaticQuery(
    graphql`
      query {
        allGoogleSheetResultsRow(filter: {netflixau: {ne: "skip"}}) {
          nodes {
            netflixau
            netflixbr
            netflixca
            netflixde
            netflixfr
            netflixhk
            netflixil
            netflixin
            netflixjp
            netflixkr
            netflixmx
            netflixnl
            netflixse
            netflixuk
            netflixus
            vpnfamily
            vpnid
          }
        }
      }
    `
  )

  const vpns = data.allGoogleSheetResultsRow.nodes

  function getColors(result) {
    switch (result) {
      case 'Success': return `green4`;
      case 'Fail': return `red4`;
      case 'No access': return `blue4`;
      default: return `indigo4`;
    }
  }

  return (
    <section class="center br0 hidden ba b--gray2 mv4">
      <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Netflix Australia Unblock Test</h4>
      <div class="bt b--gray2">
        <div class="flex flex-column flex-row-ns">
          <div class="pa3 w-100 w-60-ns pr3-ns order-2 order-1-ns">

            {vpns.map((node) => (
              <>
                <div class="flex justify-between">
                  <div class="w-75"><h5 key={node.id} class={`mb1`}>{node.vpnfamily}</h5></div>
                  <div class="w-25"><h5 class={`mb1 tr ${getColors(node.netflixau)}`}>{node.netflixau}</h5></div>
                </div>
              </>
            ))}

          </div>
        </div>
      </div>
    </section>
  )
}

export default AllNetflixResults