import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AffiliateLink from './affiliateLink.js'

const CompareReputation = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnfamily
          vpnaff
          vpnid
          reputationscore
          reputation
          supportquality
          documentation
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getreputationscore(aff) {
    switch (aff) {
      case "pia": return pia.reputationscore
      case "ivacy": return ivacy.reputationscore
      case "namecheap": return namecheap.reputationscore
      case "vypr": return vypr.reputationscore
      case "pure": return pure.reputationscore
      case "nord": return nord.reputationscore
      case "surfshark": return surfshark.reputationscore
      case "cyberghost": return cyberghost.reputationscore
      case "windscribe": return windscribe.reputationscore
      case "torguard": return torguard.reputationscore
      case "mullvad": return mullvad.reputationscore
      case "express": return express.reputationscore
      case "proton": return proton.reputationscore
      default: return pia.reputationscore
    }
  }

  function getreputation(aff) {
    switch (aff) {
      case "pia": return pia.reputation
      case "ivacy": return ivacy.reputation
      case "namecheap": return namecheap.reputation
      case "vypr": return vypr.reputation
      case "pure": return pure.reputation
      case "nord": return nord.reputation
      case "surfshark": return surfshark.reputation
      case "cyberghost": return cyberghost.reputation
      case "windscribe": return windscribe.reputation
      case "torguard": return torguard.reputation
      case "mullvad": return mullvad.reputation
      case "express": return express.reputation
      case "proton": return proton.reputation
      default: return pia.reputation
    }
  }

  function getsupportquality(aff) {
    switch (aff) {
      case "pia": return pia.supportquality
      case "ivacy": return ivacy.supportquality
      case "namecheap": return namecheap.supportquality
      case "vypr": return vypr.supportquality
      case "pure": return pure.supportquality
      case "nord": return nord.supportquality
      case "surfshark": return surfshark.supportquality
      case "cyberghost": return cyberghost.supportquality
      case "windscribe": return windscribe.supportquality
      case "torguard": return torguard.supportquality
      case "mullvad": return mullvad.supportquality
      case "express": return express.supportquality
      case "proton": return proton.supportquality
      default: return pia.supportquality
    }
  }

  function getdocumentation(aff) {
    switch (aff) {
      case "pia": return pia.documentation
      case "ivacy": return ivacy.documentation
      case "namecheap": return namecheap.documentation
      case "vypr": return vypr.documentation
      case "pure": return pure.documentation
      case "nord": return nord.documentation
      case "surfshark": return surfshark.documentation
      case "cyberghost": return cyberghost.documentation
      case "windscribe": return windscribe.documentation
      case "torguard": return torguard.documentation
      case "mullvad": return mullvad.documentation
      case "express": return express.documentation
      case "proton": return proton.documentation
      default: return pia.documentation
    }
  }

  const colors = ["red4", "orange4", "purple4", "indigo4", "blue4"]

  const vpn = {
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
  }

  function scoreTimesTen(score) {
    return score * 10;
  }

  function getWinner(first, first_score, second, second_score) {
    if (first_score > second_score) {
      return displayWinner(first)
    } else {
      return displayWinner(second)
    }
  }

  function displayWinner(vpnaff) {
    return (
      <AffiliateLink type="naked" affiliate={vpnaff}>
        <div class="great v-mid w-100" styles="min-width:50%;">
          <p class="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">Winner</p>
          <p class="mv0 avenir fw6 f4 tc white pb3">{getName(vpnaff)}</p>
        </div>
      </AffiliateLink>
    )
  }

  function getScoreInWords(score) {
    switch (true) {
      case (score >= 0.0 && score < 4.0): return `Poor`;
      case (score >= 4.0 && score < 6.0): return `Average`;
      case (score >= 6.0 && score < 7.0): return `Good`;
      case (score >= 7.0 && score < 8.0): return `Very Good`;
      case (score >= 8.0 && score < 9.0): return `Great`;
      case (score >= 9.0 && score < 10.1): return `Excellent`;
      default: return `Perfect`;
    }
  }

  return (
    <>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class={`bg-${colors[3]} br0 br--top primary0 mv0 pa3 f5 ttu tracked`}>{vpn.criteria[3]} Comparison</h4>

        <div className="bt b--gray2">
          <div className="flex flex-column flex-row-ns">
            <div className="pa3 w-100 w-50-ns pr3-ns">
              <h5 className={"f5 mv0 " + colors[3]}>{vpn.criteria[3]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[3]}>({getreputationscore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[3]} style={{ width: scoreTimesTen(getreputationscore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[3]}>({getreputationscore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[3]} style={{ width: scoreTimesTen(getreputationscore(props.second)) + "%" }}></div>
                </div>
              </div>
            </div>
            <div class="v-mid pa3-ns pa4-l order-1 order-2-ns mb0 w-100 w-50-ns">
              {getWinner(props.first, getreputationscore(props.first), props.second, getreputationscore(props.second))}
            </div>
          </div>

          <div class="overflow-auto">
            <table class="f6 w-100 mw8 center" cellspacing="0">
              <thead>
                <tr>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">Consideration</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.first)}</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.second)}</th>
                </tr>
              </thead>
              <tbody class="lh-copy">
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Reputation</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getreputation(props.first))}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getreputation(props.second))}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Support Response and Quality</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getsupportquality(props.first))}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getsupportquality(props.second))}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Support Documentation</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getdocumentation(props.first))}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getScoreInWords(getdocumentation(props.second))}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <div>
        {props.children}
      </div>
    </>
  )
}

export default CompareReputation