import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AffiliateLink from './affiliateLink.js'

const AllTotalSpeedScore = () => {

  const data = useStaticQuery(
    graphql`
      query {
        allGoogleSheetResultsRow(sort: {order: DESC, fields: score}, filter: {vpnid: {ne: 0}}) {
          nodes {
            vpnname
            vpnfamily
            vpnaff
            score
            vpnid
            id
            enddate
          }
        }
      }
    `
  )

  const vpns = data.allGoogleSheetResultsRow.nodes

  const max_score = vpns[0].score

  function scoreAsPercentage(score) {
    return (((((max_score - score) / max_score) * 100) - 100) * -1)
  }

  function getColors(vpnaff) {
    switch (vpnaff) {
      case 'zeroeightthree': return `indigo4`;
      // case 'ivacy': return `red4`;
      // case 'nord': return `blue4`;
      default: return `green4`;
    }
  }

  function getMonth(date) {
    const month = date.split(" ")[1]
    return (
      month
    )  
  }

  function getWinner(winner) {
    if (winner === "zeroeightthree") {
      return (
        <AffiliateLink type="naked" affiliate={vpns[1].vpnaff}>
          <div class="great v-mid w-100" styles="min-width:50%;">
            <p class="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">Winner</p>
            <p class="mv0 avenir fw6 f4 tc white pb3">{vpns[1].vpnfamily}</p>
          </div>
        </AffiliateLink>
      )
    } else {
      return (
        <AffiliateLink type="naked" affiliate={vpns[0].vpnaff}>
          <div class="great v-mid w-100" styles="min-width:50%;">
            <p class="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">Winner</p>
            <p class="mv0 avenir fw6 f4 tc white pb3">{vpns[0].vpnfamily}</p>
          </div>
        </AffiliateLink>
      )
    }
  }

  return (
    <>
      <p>I can recommend {vpns[0].vpnfamily} as the fastest VPN from my speed tests in {getMonth(vpns[0].enddate)}. {vpns[1].vpnfamily} was also highly commended.</p>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Total Speed Score /10</h4>
        <div class="bt b--gray2">
          <div class="flex flex-column flex-row-ns">
            <div class="pa3 w-100 w-60-ns pr3-ns order-2 order-1-ns">
              <p className="f7 lh-copy mv0">* A higher score is better.</p>

              {vpns.map((node) => (
                <>
                  <div class="flex justify-between">
                    <div class="w-75"><h5 key={node.id} class={`mb1 ${getColors(node.vpnaff)}`}>{node.vpnname}</h5></div>
                    <div class="w-25"><h5 class={`mb1 tr ${getColors(node.vpnaff)}`}>{node.score} / 10</h5></div>
                  </div>
                  <div class="bg-gray1 br-pill h025 overflow-y-hidden"><div class={`br-pill h025 shadow-1 bg-${getColors(node.vpnaff)}`} style={{ width: scoreAsPercentage(node.score) + "%" }}></div></div>
                </>
              ))}

            </div>
            <div class="v-mid pa3-ns pa4-l order-1 order-2-ns mb0 w-100 w-40-ns">
              {getWinner(vpns[0].vpnaff)}
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default AllTotalSpeedScore