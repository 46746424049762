import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import 'tachyons-sass/tachyons.scss'
import { MDXProvider } from "@mdx-js/react"

import Header from './header'
import Footer from './footer'

import ReviewIntro from './reviewIntro.js'
import ReviewStats from './reviewStats.js'
import ReviewCriteria from './reviewCriteria.js'
import ReviewBox from './reviewBox.js'
import ReviewClick from './reviewClick.js'
import Pros from './pros.js'
import Cons from './cons.js'
import TableOfContents from './tableOfContents.js'
import BottomLine from './bottomLine.js'
import AffiliateLink from './affiliateLink.js'
import GalleryWrapper from './galleryWrapper.js'
import ImageWrapper from './imageWrapper.js'
import SpeedTest from './speedTest.js'
import TestProgressBar from './testProgressBar.js'
import SummarySnippet from './summarySnippet.js'

// Data 
import FastestVpnWinner from './fastestVpnWinner.js'
import NetflixVpnWinner from './netflixVpnWinner.js'
import AllAverageDownloadSpeed from './allAverageDownloadSpeed.js'
import AllAverageUploadSpeed from './allAverageUploadSpeed.js'
import AllMaxDownloadSpeed from './allMaxDownloadSpeed.js'
import AllFileAverageDownloadSpeed from './allFileAverageDownloadSpeed.js'
import AllFileQuickestDownloadTime from './allFileQuickestDownloadTime.js'
import AllTorrent1TestSpeed from './allTorrent1TestSpeed.js'
import AllTorrent2TestSpeed from './allTorrent2TestSpeed.js'
import AllTorrent3TestSpeed from './allTorrent3TestSpeed.js'
import AllTorrentAverageSpeed from './allTorrentAverageSpeed.js'
import AllTotalSpeedScore from './allTotalSpeedScore.js'
import AllNetflixResultsAu from './allNetflixResultsAu.js'
import AllNetflixResultsBr from './allNetflixResultsBr.js' 
import AllNetflixResultsCa from './allNetflixResultsCa.js' 
import AllNetflixResultsFr from './allNetflixResultsFr.js' 
import AllNetflixResultsDe from './allNetflixResultsDe.js' 
import AllNetflixResultsHk from './allNetflixResultsHk.js' 
import AllNetflixResultsIn from './allNetflixResultsIn.js' 
import AllNetflixResultsIl from './allNetflixResultsIl.js' 
import AllNetflixResultsJp from './allNetflixResultsJp.js' 
import AllNetflixResultsMx from './allNetflixResultsMx.js' 
import AllNetflixResultsKr from './allNetflixResultsKr.js' 
import AllNetflixResultsSe from './allNetflixResultsSe.js' 
import AllNetflixResultsNl from './allNetflixResultsNl.js' 
import AllNetflixResultsUk from './allNetflixResultsUk.js' 
import AllNetflixResultsUs from './allNetflixResultsUs.js'
import AllNetflixResults from './allNetflixResults.js'
import SpeedScoresPia from './speedScoresPia.js'
import SpeedScoresIvacy from './speedScoresIvacy.js'
import SpeedScoresNamecheap from './speedScoresNamecheap.js'
import SpeedScoresVypr from './speedScoresVypr.js'
import SpeedScoresPure from './speedScoresPure.js'

import GetScoreBox from './getScoreBox.js'
import GetTestDates from './getTestDates.js'
import GetNetflixResults from './getNetflixResults.js'

import ReviewClickPia from './reviewClickPia.js'
import ReviewClickIvacy from './reviewClickIvacy.js'
import ReviewClickNamecheap from './reviewClickNamecheap.js'
import ReviewClickVypr from './reviewClickVypr.js'
import ReviewClickPure from './reviewClickPure.js'
import ReviewClickNord from './reviewClickNord.js'
import ReviewClickSurfshark from './reviewClickSurfshark.js'
import ReviewClickCyberghost from './reviewClickCyberghost.js'
import ReviewClickWindscribe from './reviewClickWindscribe.js'
import ReviewClickTorguard from './reviewClickTorguard.js'
import ReviewClickMullvad from './reviewClickMullvad.js'
import ReviewClickExpress from './reviewClickExpress.js'
import ReviewClickProton from './reviewClickProton.js'

import CaseTest from './caseTest.js'
import CompareTimePeriod from './compareTimePeriod.js'
import CompareNetflix from './compareNetflix.js'
import CompareVerdict from './compareVerdict.js'
import ComparePrivacy from './comparePrivacy.js'
import CompareFunctionality from './compareFunctionality.js'
import CompareReputation from './compareReputation.js'
import ComparePricing from './comparePricing.js'
import CompareSpeed from './compareSpeed.js'

import StrapiReviewOverview from './strapiReviewOverview.js'
import StrapiReviewPros from './strapiReviewPros.js'
import StrapiReviewCons from './strapiReviewCons.js'
import StrapiReviewBottomLine from './strapiReviewBottomLine.js'
import StrapiReviewPrivacyAnonymitySummary from './strapiReviewPrivacyAnonymitySummary.js'
import StrapiReviewSpeedPerformanceSummary from './strapiReviewSpeedPerformanceSummary.js'
import StrapiReviewFunctionalityApplicationSummary from './strapiReviewFunctionalityApplicationSummary.js'
import StrapiReviewReputationSupportSummary from './strapiReviewReputationSupportSummary.js'
import StrapiReviewPriceValueSummary from './strapiReviewPriceValueSummary.js'
import StrapiReviewLoggingPolicy from './strapiReviewLoggingPolicy.js'
import StrapiReviewKillswitch from './strapiReviewKillswitch.js'
import StrapiReviewSecurityProtocols from './strapiReviewSecurityProtocols.js'
import StrapiReviewIsFast from './strapiReviewIsFast.js'
import StrapiReviewConsistentSpeeds from './strapiReviewConsistentSpeeds.js'
import StrapiReviewEasyToUse from './strapiReviewEasyToUse.js'
import StrapiReviewSplitTunneling from './strapiReviewSplitTunneling.js'
import StrapiReviewGoodForTorrenting from './strapiReviewGoodForTorrenting.js'
import StrapiReviewGoodForBbcIplayer from './strapiReviewGoodForBbcIplayer.js'
import StrapiReviewGoodForStreaming from './strapiReviewGoodForStreaming.js'
import StrapiReviewGoodFeatures from './strapiReviewGoodFeatures.js'
import StrapiReviewTrustworthy from './strapiReviewTrustworthy.js'
import StrapiReviewLiveSupport from './strapiReviewLiveSupport.js'
import StrapiReviewJurisdiction from './strapiReviewJurisdiction.js'
import StrapiReviewParentCompany from './strapiReviewParentCompany.js'
import StrapiReviewControversies from './strapiReviewControversies.js'
import StrapiReviewCustomersLike from './strapiReviewCustomersLike.js'
import StrapiReviewCustomersDislike from './strapiReviewCustomersDislike.js'
import StrapiReviewCostPerMonth from './strapiReviewCostPerMonth.js'
import StrapiReviewWorthTheMoney from './strapiReviewWorthTheMoney.js'
import StrapiReviewPaymentForms from './strapiReviewPaymentForms.js'
import StrapiReviewTrialGuarantee from './strapiReviewTrialGuarantee.js'
import StrapiReviewWhoGoodFor from './strapiReviewWhoGoodFor.js'
import StrapiReviewWhoBadFor from './strapiReviewWhoBadFor.js'

import StrapiComparisonRecommendation from './strapiComparisonRecommendation.js'
import StrapiComparisonVpn1Better from './strapiComparisonVpn1Better.js'
import StrapiComparisonVpn2Better from './strapiComparisonVpn2Better.js'
import StrapiComparisonBetterPrivacy from './strapiComparisonBetterPrivacy.js'
import StrapiComparisonBetterFaster from './strapiComparisonBetterFaster.js'
import StrapiComparisonBetterTorrents from './strapiComparisonBetterTorrents.js'
import StrapiComparisonBetterNetflix from './strapiComparisonBetterNetflix.js'
import StrapiComparisonBetterFeatures from './strapiComparisonBetterFeatures.js'
import StrapiComparisonBetterSupport from './strapiComparisonBetterSupport.js'
import StrapiComparisonBetterTrust from './strapiComparisonBetterTrust.js'
import StrapiComparisonBetterValue from './strapiComparisonBetterValue.js'

const shortcodes = { 
  ReviewIntro, 
  ReviewStats, 
  ReviewCriteria, 
  ReviewClick, 
  ReviewBox, 
  Pros, 
  Cons,
  TableOfContents,
  BottomLine,
  AffiliateLink,
  GalleryWrapper,
  ImageWrapper,
  SpeedTest,
  TestProgressBar,
  SummarySnippet,
  FastestVpnWinner,
  NetflixVpnWinner,
  AllAverageDownloadSpeed,
  AllAverageUploadSpeed,
  AllMaxDownloadSpeed,
  AllFileAverageDownloadSpeed,
  AllFileQuickestDownloadTime,
  AllTorrent1TestSpeed,
  AllTorrent2TestSpeed,
  AllTorrent3TestSpeed,
  AllTorrentAverageSpeed,
  AllTotalSpeedScore,
  AllNetflixResultsAu,
  AllNetflixResultsBr,
  AllNetflixResultsCa,
  AllNetflixResultsFr,
  AllNetflixResultsDe,
  AllNetflixResultsHk,
  AllNetflixResultsIn,
  AllNetflixResultsIl,
  AllNetflixResultsJp,
  AllNetflixResultsMx,
  AllNetflixResultsKr,
  AllNetflixResultsSe,
  AllNetflixResultsNl,
  AllNetflixResultsUk,
  AllNetflixResultsUs,
  AllNetflixResults,
  SpeedScoresPia,
  SpeedScoresIvacy,
  SpeedScoresNamecheap,
  SpeedScoresVypr,
  SpeedScoresPure,
  GetScoreBox,
  GetTestDates,
  GetNetflixResults,
  ReviewClickPia,
  ReviewClickIvacy,
  ReviewClickNamecheap,
  ReviewClickVypr,
  ReviewClickPure,
  ReviewClickNord,
  ReviewClickSurfshark,
  ReviewClickCyberghost,
  ReviewClickWindscribe,
  ReviewClickTorguard,
  ReviewClickMullvad,
  ReviewClickExpress,
  ReviewClickProton,
  CaseTest,
  CompareTimePeriod,
  CompareNetflix,
  CompareVerdict,
  ComparePrivacy,
  CompareFunctionality,
  CompareReputation,
  ComparePricing,
  CompareSpeed,
  StrapiReviewOverview,
  StrapiReviewPros,
  StrapiReviewCons,
  StrapiReviewBottomLine,
  StrapiReviewPrivacyAnonymitySummary,
  StrapiReviewSpeedPerformanceSummary,
  StrapiReviewFunctionalityApplicationSummary,
  StrapiReviewReputationSupportSummary,
  StrapiReviewPriceValueSummary,
  StrapiReviewLoggingPolicy,
  StrapiReviewKillswitch,
  StrapiReviewSecurityProtocols,
  StrapiReviewIsFast,
  StrapiReviewConsistentSpeeds,
  StrapiReviewEasyToUse,
  StrapiReviewSplitTunneling,
  StrapiReviewGoodForTorrenting,
  StrapiReviewGoodForBbcIplayer,
  StrapiReviewGoodForStreaming,
  StrapiReviewGoodFeatures,
  StrapiReviewTrustworthy,
  StrapiReviewLiveSupport,
  StrapiReviewJurisdiction,
  StrapiReviewParentCompany,
  StrapiReviewControversies,
  StrapiReviewCustomersLike,
  StrapiReviewCustomersDislike,
  StrapiReviewCostPerMonth,
  StrapiReviewWorthTheMoney,
  StrapiReviewPaymentForms,
  StrapiReviewTrialGuarantee,
  StrapiReviewWhoGoodFor,
  StrapiReviewWhoBadFor,
  StrapiComparisonRecommendation,
  StrapiComparisonVpn1Better,
  StrapiComparisonVpn2Better,
  StrapiComparisonBetterPrivacy,
  StrapiComparisonBetterFaster,
  StrapiComparisonBetterTorrents,
  StrapiComparisonBetterNetflix,
  StrapiComparisonBetterFeatures,
  StrapiComparisonBetterSupport,
  StrapiComparisonBetterTrust,
  StrapiComparisonBetterValue,
}

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <MDXProvider components={shortcodes}>
        <Header siteTitle={data.site.siteMetadata.title} />
        {children}
        <Footer siteTitle={data.site.siteMetadata.title} />
      </MDXProvider>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
