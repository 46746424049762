import React from "react"

const Cons = (props) => {
  return (
    <div className="ph3 w-100 w-50-ns pr3-ns">
      <h5 className="red4">Cons +</h5>
      <div className="athelas gray f6">
        {props.children}
      </div>
    </div>
  )
}

export default Cons