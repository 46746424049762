import React from "react"
import { Link } from 'gatsby'
import Img from "gatsby-image"

const Article = (props) => {
  return (
    <article key={props.slug} className="pv4 bt b--gray2 ph4">
      <div className="flex flex-column flex-row-ns">
        <div className="w-100 w-60-ns pr3-ns order-2 order-1-ns">
          <Link to={props.link} className="primary5 no-underline underline-hover hover-primary7">
            <h3 className="f4 fw5 f3-l avenir mt0 lh-title">{props.title}</h3>
          </Link>
          <p className="f5 fw5 mid-gray lh-copy athelas">
            {props.excerpt}
          </p>
          <div class="flex justify-between items-center">
            <div class="w-75 pv2">
              <p className="f6 lh-copy gray mv0 athelas">
                Last Updated:
                <time className="pl3">
                  {props.last_modified}
                </time>
              </p>
            </div>
            <div class="w-25 pv2">
              <p className="f6 lh-copy primary7 mv0 avenir">
                {props.time_to_read}
              </p>
            </div>
          </div>
        </div>
        <div className="pl3-ns order-1 order-2-ns mb4 mb0-ns w-100 w-40-ns">
          <Link to={props.link} className="link black dim db">
            <Img fluid={props.image} alt={props.alt} />
          </Link>
        </div>
      </div>
    </article>
  )
}

export default Article