import React from "react"

const AffiliateDisclosure = () => {
  return (
    <aside className="bg-primary6 bt b--gray2">
      <div className="white flex justify-between-ns flex-column flex-row-ns pa4 ph7-l mw9-l center">
        <div className="w-100 w-25-ns v-mid center tc">
          <svg className="pt5-ns dib h2 w2" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" /></svg>
        </div>
        <div className="w-100 w-75-ns v-mid">
          <h5 className="f6 ttu tracked white">Affiliate Disclosure</h5>
          <p className="lh-copy center f6 white avenir">
            083.org is a professional review website that may receive compensation from the companies whose Virtual Private Network services I review. 
            However, this does not impact my guides, reviews, and comparisons.
          </p>
          <p className="lh-copy center f6 white avenir">
            VPN providers and services are thoroughly reviewed and I give positive reviews only to the best.
            This website is independently owned and all views are my own.
          </p>
          <p className="lh-copy center f6 white avenir">
            Unlike many other VPN review sites, I personally buy each service with my own money and test them thoroughly before recommending any.
          </p>
        </div>
      </div>
    </aside>
  )
}

export default AffiliateDisclosure
