import React from "react"

const SummarySnippet = (props) => {

  const vpn = {
    colors: ["red4", "orange4", "purple4", "indigo4", "blue4"],
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
  }

  function showVPNInfo(index) {
      return (
        <h4 className={vpn.colors[index]}>{vpn.criteria[index]} Summary</h4>
      )
    }

  function getHeading(heading) {
    switch (heading) {
      case 0: return showVPNInfo(0);
      case 1: return showVPNInfo(1);
      case 2: return showVPNInfo(2);
      case 3: return showVPNInfo(3);
      case 4: return showVPNInfo(4);
      default: return "";
    }
  }

  function getColor(heading) {
    switch (heading) {
      case 0: return "b--red4";
      case 1: return "b--orange4";
      case 2: return "b--purple4";
      case 3: return "b--indigo4";
      case 4: return "b--blue4";
      default: return "b--green4";
    }
  }

  return (
    <div className={"mv4-ns mv2 pa2 pl3 pl4-ns avenir bl " + getColor(props.heading)}>
      {getHeading(props.heading)}
      {props.children}
    </div>
  )
}

export default SummarySnippet