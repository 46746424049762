import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReviewClickPia from './reviewClickPia.js'
import ReviewClickIvacy from './reviewClickIvacy.js'
import ReviewClickNamecheap from './reviewClickNamecheap.js'
import ReviewClickVypr from './reviewClickVypr.js'
import ReviewClickPure from './reviewClickPure.js'
import ReviewClickNord from './reviewClickNord.js'
import ReviewClickSurfshark from './reviewClickSurfshark.js'
import ReviewClickCyberghost from './reviewClickCyberghost.js'
import ReviewClickWindscribe from './reviewClickWindscribe.js'
import ReviewClickTorguard from './reviewClickTorguard.js'
import ReviewClickMullvad from './reviewClickMullvad.js'
import ReviewClickExpress from './reviewClickExpress.js'
import ReviewClickProton from './reviewClickProton.js'

const FastestVpnWinner = () => {

  const data = useStaticQuery(
    graphql`
      query {
        allGoogleSheetResultsRow(sort: {order: DESC, fields: score}, filter: {vpnid: {ne: 0}}) {
          nodes {
            vpnname
            vpnfamily
            vpnaff
            score
            vpnid
            id
          }
        }
      }
    `
  )

  const vpns = data.allGoogleSheetResultsRow.nodes

  const winner = vpns[0].vpnaff
  const score = vpns[0].score
  const second = vpns[1].vpnaff
  const second_score = vpns[1].score
  const third = vpns[2].vpnaff
  const third_score = vpns[2].score

  function getWinner(vpn, score, text) {
    switch (vpn) {
      case 'pia': return (
        <ReviewClickPia text={`${text} - ${score}/10 for Speed`} />
      )
      case 'ivacy': return (
        <ReviewClickIvacy text={`${text} - ${score}/10 for Speed`} />
      )
      case 'namecheap': return (
        <ReviewClickNamecheap text={`${text} - ${score}/10 for Speed`} />
      )
      case 'vypr': return (
        <ReviewClickVypr text={`${text} - ${score}/10 for Speed`} />
      )
      case 'pure': return (
        <ReviewClickPure text={`${text} - ${score}/10 for Speed`} />
      )
      case 'nord': return (
        <ReviewClickNord text={`${text} - ${score}/10 for Speed`} />
      )
      case 'surfshark': return (
        <ReviewClickSurfshark text={`${text} - ${score}/10 for Speed`} />
      )
      case 'cyberghost': return (
        <ReviewClickCyberghost text={`${text} - ${score}/10 for Speed`} />
      )
      case 'windscribe': return (
        <ReviewClickWindscribe text={`${text} - ${score}/10 for Speed`} />
      )
      case 'torguard': return (
        <ReviewClickTorguard text={`${text} - ${score}/10 for Speed`} />
      )
      case 'mullvad': return (
        <ReviewClickMullvad text={`${text} - ${score}/10 for Speed`} />
      )
      case 'express': return (
        <ReviewClickExpress text={`${text} - ${score}/10 for Speed`} />
      )
      case 'proton': return (
        <ReviewClickProton text={`${text} - ${score}/10 for Speed`} />
      )
      default: return (
        <ReviewClickPia text={`${text} - ${score}/10 for Speed`} />
      );
    }
  }

  return (
    <>
      <h4>1st Place</h4>
      {getWinner(winner, score, "Fastest VPN")}
      <h4>2nd Place</h4>
      {getWinner(second, second_score, "Second Fastest")}
      <h4>3rd Place</h4>
      {getWinner(third, third_score, "Third Place")}
    </>
  )
}

export default FastestVpnWinner