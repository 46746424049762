import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AffiliateLink from './affiliateLink.js'

const ComparePrivacy = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnfamily
          vpnaff
          vpnid
          privacyscore
          dnsleak
          webrtcleak
          ipleak
          protocolswindows
          protocolsmac
          protocolsandroid
          protocolsios
          killswitchwindows
          killswitchmac
          killswitchandroid
          killswitchios
          logskept
          jurisdiction
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getPrivacyScore(aff) {
    switch (aff) {
      case "pia": return pia.privacyscore
      case "ivacy": return ivacy.privacyscore
      case "namecheap": return namecheap.privacyscore
      case "vypr": return vypr.privacyscore
      case "pure": return pure.privacyscore
      case "nord": return nord.privacyscore
      case "surfshark": return surfshark.privacyscore
      case "cyberghost": return cyberghost.privacyscore
      case "windscribe": return windscribe.privacyscore
      case "torguard": return torguard.privacyscore
      case "mullvad": return mullvad.privacyscore
      case "express": return express.privacyscore
      case "proton": return proton.privacyscore
      default: return pia.privacyscore
    }
  }

  function getdnsleak(aff) {
    switch (aff) {
      case "pia": return pia.dnsleak
      case "ivacy": return ivacy.dnsleak
      case "namecheap": return namecheap.dnsleak
      case "vypr": return vypr.dnsleak
      case "pure": return pure.dnsleak
      case "nord": return nord.dnsleak
      case "surfshark": return surfshark.dnsleak
      case "cyberghost": return cyberghost.dnsleak
      case "windscribe": return windscribe.dnsleak
      case "torguard": return torguard.dnsleak
      case "mullvad": return mullvad.dnsleak
      case "express": return express.dnsleak
      case "proton": return proton.dnsleak
      default: return pia.dnsleak
    }
  }

  function getwebrtcleak(aff) {
    switch (aff) {
      case "pia": return pia.webrtcleak
      case "ivacy": return ivacy.webrtcleak
      case "namecheap": return namecheap.webrtcleak
      case "vypr": return vypr.webrtcleak
      case "pure": return pure.webrtcleak
      case "nord": return nord.webrtcleak
      case "surfshark": return surfshark.webrtcleak
      case "cyberghost": return cyberghost.webrtcleak
      case "windscribe": return windscribe.webrtcleak
      case "torguard": return torguard.webrtcleak
      case "mullvad": return mullvad.webrtcleak
      case "express": return express.webrtcleak
      case "proton": return proton.webrtcleak
      default: return pia.webrtcleak
    }
  }

  function getipleak(aff) {
    switch (aff) {
      case "pia": return pia.ipleak
      case "ivacy": return ivacy.ipleak
      case "namecheap": return namecheap.ipleak
      case "vypr": return vypr.ipleak
      case "pure": return pure.ipleak
      case "nord": return nord.ipleak
      case "surfshark": return surfshark.ipleak
      case "cyberghost": return cyberghost.ipleak
      case "windscribe": return windscribe.ipleak
      case "torguard": return torguard.ipleak
      case "mullvad": return mullvad.ipleak
      case "express": return express.ipleak
      case "proton": return proton.ipleak
      default: return pia.ipleak
    }
  }

  function getprotocolswindows(aff) {
    switch (aff) {
      case "pia": return pia.protocolswindows
      case "ivacy": return ivacy.protocolswindows
      case "namecheap": return namecheap.protocolswindows
      case "vypr": return vypr.protocolswindows
      case "pure": return pure.protocolswindows
      case "nord": return nord.protocolswindows
      case "surfshark": return surfshark.protocolswindows
      case "cyberghost": return cyberghost.protocolswindows
      case "windscribe": return windscribe.protocolswindows
      case "torguard": return torguard.protocolswindows
      case "mullvad": return mullvad.protocolswindows
      case "express": return express.protocolswindows
      case "proton": return proton.protocolswindows
      default: return pia.protocolswindows
    }
  }

  function getprotocolsmac(aff) {
    switch (aff) {
      case "pia": return pia.protocolsmac
      case "ivacy": return ivacy.protocolsmac
      case "namecheap": return namecheap.protocolsmac
      case "vypr": return vypr.protocolsmac
      case "pure": return pure.protocolsmac
      case "nord": return nord.protocolsmac
      case "surfshark": return surfshark.protocolsmac
      case "cyberghost": return cyberghost.protocolsmac
      case "windscribe": return windscribe.protocolsmac
      case "torguard": return torguard.protocolsmac
      case "mullvad": return mullvad.protocolsmac
      case "express": return express.protocolsmac
      case "proton": return proton.protocolsmac
      default: return pia.protocolsmac
    }
  }

  function getprotocolsandroid(aff) {
    switch (aff) {
      case "pia": return pia.protocolsandroid
      case "ivacy": return ivacy.protocolsandroid
      case "namecheap": return namecheap.protocolsandroid
      case "vypr": return vypr.protocolsandroid
      case "pure": return pure.protocolsandroid
      case "nord": return nord.protocolsandroid
      case "surfshark": return surfshark.protocolsandroid
      case "cyberghost": return cyberghost.protocolsandroid
      case "windscribe": return windscribe.protocolsandroid
      case "torguard": return torguard.protocolsandroid
      case "mullvad": return mullvad.protocolsandroid
      case "express": return express.protocolsandroid
      case "proton": return proton.protocolsandroid
      default: return pia.protocolsandroid
    }
  }

  function getprotocolsios(aff) {
    switch (aff) {
      case "pia": return pia.protocolsios
      case "ivacy": return ivacy.protocolsios
      case "namecheap": return namecheap.protocolsios
      case "vypr": return vypr.protocolsios
      case "pure": return pure.protocolsios
      case "nord": return nord.protocolsios
      case "surfshark": return surfshark.protocolsios
      case "cyberghost": return cyberghost.protocolsios
      case "windscribe": return windscribe.protocolsios
      case "torguard": return torguard.protocolsios
      case "mullvad": return mullvad.protocolsios
      case "express": return express.protocolsios
      case "proton": return proton.protocolsios
      default: return pia.protocolsios
    }
  }

  function getkillswitchwindows(aff) {
    switch (aff) {
      case "pia": return pia.killswitchwindows
      case "ivacy": return ivacy.killswitchwindows
      case "namecheap": return namecheap.killswitchwindows
      case "vypr": return vypr.killswitchwindows
      case "pure": return pure.killswitchwindows
      case "nord": return nord.killswitchwindows
      case "surfshark": return surfshark.killswitchwindows
      case "cyberghost": return cyberghost.killswitchwindows
      case "windscribe": return windscribe.killswitchwindows
      case "torguard": return torguard.killswitchwindows
      case "mullvad": return mullvad.killswitchwindows
      case "express": return express.killswitchwindows
      case "proton": return proton.killswitchwindows
      default: return pia.killswitchwindows
    }
  }

  function getkillswitchmac(aff) {
    switch (aff) {
      case "pia": return pia.killswitchmac
      case "ivacy": return ivacy.killswitchmac
      case "namecheap": return namecheap.killswitchmac
      case "vypr": return vypr.killswitchmac
      case "pure": return pure.killswitchmac
      case "nord": return nord.killswitchmac
      case "surfshark": return surfshark.killswitchmac
      case "cyberghost": return cyberghost.killswitchmac
      case "windscribe": return windscribe.killswitchmac
      case "torguard": return torguard.killswitchmac
      case "mullvad": return mullvad.killswitchmac
      case "express": return express.killswitchmac
      case "proton": return proton.killswitchmac
      default: return pia.killswitchmac
    }
  }

  function getkillswitchandroid(aff) {
    switch (aff) {
      case "pia": return pia.killswitchandroid
      case "ivacy": return ivacy.killswitchandroid
      case "namecheap": return namecheap.killswitchandroid
      case "vypr": return vypr.killswitchandroid
      case "pure": return pure.killswitchandroid
      case "nord": return nord.killswitchandroid
      case "surfshark": return surfshark.killswitchandroid
      case "cyberghost": return cyberghost.killswitchandroid
      case "windscribe": return windscribe.killswitchandroid
      case "torguard": return torguard.killswitchandroid
      case "mullvad": return mullvad.killswitchandroid
      case "express": return express.killswitchandroid
      case "proton": return proton.killswitchandroid
      default: return pia.killswitchandroid
    }
  }

  function getkillswitchios(aff) {
    switch (aff) {
      case "pia": return pia.killswitchios
      case "ivacy": return ivacy.killswitchios
      case "namecheap": return namecheap.killswitchios
      case "vypr": return vypr.killswitchios
      case "pure": return pure.killswitchios
      case "nord": return nord.killswitchios
      case "surfshark": return surfshark.killswitchios
      case "cyberghost": return cyberghost.killswitchios
      case "windscribe": return windscribe.killswitchios
      case "torguard": return torguard.killswitchios
      case "mullvad": return mullvad.killswitchios
      case "express": return express.killswitchios
      case "proton": return proton.killswitchios
      default: return pia.killswitchios
    }
  }

  function getlogskept(aff) {
    switch (aff) {
      case "pia": return pia.logskept
      case "ivacy": return ivacy.logskept
      case "namecheap": return namecheap.logskept
      case "vypr": return vypr.logskept
      case "pure": return pure.logskept
      case "nord": return nord.logskept
      case "surfshark": return surfshark.logskept
      case "cyberghost": return cyberghost.logskept
      case "windscribe": return windscribe.logskept
      case "torguard": return torguard.logskept
      case "mullvad": return mullvad.logskept
      case "express": return express.logskept
      case "proton": return proton.logskept
      default: return pia.logskept
    }
  }

  function getjurisdiction(aff) {
    switch (aff) {
      case "pia": return pia.jurisdiction
      case "ivacy": return ivacy.jurisdiction
      case "namecheap": return namecheap.jurisdiction
      case "vypr": return vypr.jurisdiction
      case "pure": return pure.jurisdiction
      case "nord": return nord.jurisdiction
      case "surfshark": return surfshark.jurisdiction
      case "cyberghost": return cyberghost.jurisdiction
      case "windscribe": return windscribe.jurisdiction
      case "torguard": return torguard.jurisdiction
      case "mullvad": return mullvad.jurisdiction
      case "express": return express.jurisdiction
      case "proton": return proton.jurisdiction
      default: return pia.jurisdiction
    }
  }

  function getColors(result) {
    switch (result) {
      case 'Success': return `green4`;
      case 'Pass': return `green4`;
      case 'Yes': return `green4`;
      case 'Fail': return `red4`;
      case 'No': return `red4`;
      case 'No access': return `blue4`;
      default: return `indigo4`;
    }
  }

  const colors = ["red4", "orange4", "purple4", "indigo4", "blue4"]

  const vpn = {
    criteria: ["Privacy & Anonymity", "Speed & Performance", "Functionality & Application", "Reputation & Support", "Price & Value"],
  }

  function scoreTimesTen(score) {
    return score * 10;
  }

  function getWinner(first, first_score, second, second_score) {
    if (first_score > second_score) {
      return displayWinner(first)
    } else {
      return displayWinner(second)
    }
  }

  function displayWinner(vpnaff) {
    return (
      <AffiliateLink type="naked" affiliate={vpnaff}>
        <div class="great v-mid w-100" styles="min-width:50%;">
          <p class="pt3 mv0 avenir f5 fw3 ttu tracked primary1 tc">Winner</p>
          <p class="mv0 avenir fw6 f4 tc white pb3">{getName(vpnaff)}</p>
        </div>
      </AffiliateLink>
    )
  }

  return (
    <>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class={`bg-${colors[0]} br0 br--top primary0 mv0 pa3 f5 ttu tracked`}>{vpn.criteria[0]} Comparison</h4>

        <div className="bt b--gray2">
          <div className="flex flex-column flex-row-ns">
            <div className="pa3 w-100 w-50-ns pr3-ns">
              <h5 className={"f5 mv0 " + colors[0]}>{vpn.criteria[0]}</h5>
              <div className="mt0 mb4">
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.first)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[0]}>({getPrivacyScore(props.first)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[0]} style={{ width: scoreTimesTen(getPrivacyScore(props.first)) + "%" }}></div>
                </div>
                <div className="flex justify-between">
                  <div className="w-75"><h5 className="mb1 gray">{getName(props.second)}</h5></div>
                  <div className="w-25"><h5 className={"mb1 tr " + colors[0]}>({getPrivacyScore(props.second)})</h5></div>
                </div>
                <div className="bg-gray1 br-pill h025 overflow-y-hidden">
                  <div className={"br-pill h025 shadow-1 bg-" + colors[0]} style={{ width: scoreTimesTen(getPrivacyScore(props.second)) + "%" }}></div>
                </div>
              </div>
            </div>
            <div class="v-mid pa3-ns pa4-l order-1 order-2-ns mb0 w-100 w-50-ns">
              {getWinner(props.first, getPrivacyScore(props.first), props.second, getPrivacyScore(props.second))}
            </div>
          </div>

          <div class="overflow-auto">
            <table class="f6 w-100 mw8 center" cellspacing="0">
              <thead>
                <tr>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">Consideration</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.first)}</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.second)}</th>
                </tr>
              </thead>
              <tbody class="lh-copy">
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">DNS Leak Test</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getdnsleak(props.first))}`}>{getdnsleak(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getdnsleak(props.second))}`}>{getdnsleak(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">WebRTC Leak Test</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getwebrtcleak(props.first))}`}>{getwebrtcleak(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getwebrtcleak(props.second))}`}>{getwebrtcleak(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">IP Leak Test</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getipleak(props.first))}`}>{getipleak(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getipleak(props.second))}`}>{getipleak(props.second)}</td>
                </tr> 
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Protocols Windows</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getprotocolswindows(props.first)}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getprotocolswindows(props.second)}</td>
                </tr> 
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Protocols Mac</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getprotocolsmac(props.first)}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getprotocolsmac(props.second)}</td>
                </tr> 
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Protocols Android</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getprotocolsandroid(props.first)}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getprotocolsandroid(props.second)}</td>
                </tr> 
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Protocols iOS</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getprotocolsios(props.second))}`}>{getprotocolsios(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getprotocolsios(props.second))}`}>{getprotocolsios(props.second)}</td>
                </tr> 
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Killswitch Windows</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchwindows(props.first))}`}>{getkillswitchwindows(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchwindows(props.second))}`}>{getkillswitchwindows(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Killswitch Mac</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchmac(props.first))}`}>{getkillswitchmac(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchmac(props.second))}`}>{getkillswitchmac(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Killswitch Android</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchandroid(props.first))}`}>{getkillswitchandroid(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchandroid(props.second))}`}>{getkillswitchandroid(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Killswitch iOS</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchios(props.first))}`}>{getkillswitchios(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getkillswitchios(props.second))}`}>{getkillswitchios(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Logs Policy</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getlogskept(props.first))}`}>{getlogskept(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getlogskept(props.second))}`}>{getlogskept(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Jurisdiction</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getjurisdiction(props.first)}</td>
                  <td class={`avenir fw4 gray bb b--gray2 tl ph3 pv4 bg-white`}>{getjurisdiction(props.second)}</td>
                </tr> 
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <div>
        {props.children}
      </div>
    </>
  )
}

export default ComparePrivacy