import React from "react"

const BottomLine = (props) => {
  return (
    <div className="ph3 w-100 pr3-ns">
      <h5 className="primary4">Bottom Line</h5>
      <div className="athelas gray f6">
        {props.children}
      </div>
    </div>
  )
}

export default BottomLine