import React from "react"

const ThemeReviewStats = (props) => {
  return (
    <div className="bg-primary0 pa4-ns pa2 avenir mv1-ns ba b--gray2 mb3">
      <div className="center ph4-ns ph2">
        <h3 className="f5 ttu tracked dark-gray">Review Stats</h3>
        <div className="flex justify-between flex-wrap items-start">
          <div className="w-25-ns lh-title w-50">
            <h6 className="f6 fw4 mt2-ns ml0 primary4 mb2">Curated</h6>
            <p className="avenir mv0 f3 fw6 ml0 mid-gray">{props.reviewed_no}</p>
          </div>
          <div className="w-25-ns lh-title w-50">
            <h6 className="f6 fw4 mt2-ns ml0 primary4 mb2">Shortlisted</h6>
            <p className="avenir mv0 f3 fw6 ml0 mid-gray">{props.curated_no}</p>
          </div>
          <div className="w-25-ns lh-title w-50">
            <h6 className="f6 fw4 mt2-ns ml0 primary4 mb2">Recommended</h6>
            <p className="avenir mv0 f3 fw6 ml0 mid-gray">{props.recommended_no}</p>
          </div>
          <div className="w-25-ns lh-title w-50">
            <h6 className="f6 fw4 mt2-ns ml0 primary4 mb2">Hours Spent Reviewing</h6>
            <p className="avenir mv0 f3 fw6 ml0 mid-gray">{props.hours_no}</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ThemeReviewStats