import React from "react"

const SpeedTest = (props) => {
  return (
    <div class="flex justify-between">
      <div class="avenir w-33 pa3 white bg-silver">
        Latency: <span className="fw6">{props.ping}</span> ms
      </div>
      <div class="avenir outline w-33 pa3 white bg-indigo4">
        Download: <span className="fw6">{props.down}</span> Mbps
      </div>
      <div class="avenir outline w-33 pa3 white bg-purple4">
        Upload: <span className="fw6">{props.up}</span> Mbps
      </div>
    </div>
  )
}

export default SpeedTest