import React from "react"

const GalleryWrapper = (props) => {

  return (
    <>
      <div class="flex justify-between bg-primary0 b--primary2 ba">
        {props.children}
      </div>
    </>
  )
}

export default GalleryWrapper