import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const CompareNetflix = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          id
          vpnname
          uploadspeed
          vpnfamily
          vpnaff
          netflixscore
          netflixpercentage
          netflixint
          netflixcountries
          netflixau
          netflixbr
          netflixca
          netflixde
          netflixfr
          netflixhk
          netflixil
          netflixin
          netflixjp
          netflixkr
          netflixmx
          netflixnl
          netflixse
          netflixuk
          netflixus
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton

  // const vpn_array = [vpn.netflixau, vpn.netflixbr, vpn.netflixca, vpn.netflixfr, vpn.netflixde, vpn.netflixhk, vpn.netflixin, vpn.netflixil, vpn.netflixjp, vpn.netflixkr, vpn.netflixmx, vpn.netflixnl, vpn.netflixse, vpn.netflixuk, vpn.netflixus]

  // const vpn_countries = ["Australia", "Brazil", "Canada", "France", "Germany", "Hong Kong", "India", "Israel", "Japan", "Korea", "Mexico", "Netherlands", "Sweden", "United Kingdom", "USA"]

  // function countryString() {
  //   let array = []
  //   for (let i = 0; i < vpn_array.length; i++) {
  //     if (vpn_array[i] === "Success") {
  //       array.push(vpn_countries[i])
  //     }
  //   }
  //   let delimiter = ", ";
  //   let string = array.join(delimiter);
  //   let index = string.lastIndexOf(delimiter);
  //   let output = string.substring(0, index) + ' and ' + string.substring(index + delimiter.length);
  //   if (string.length === 0) {
  //     return "I couldn't watch any geo-restricted Netflix content."
  //   } else {

  //     return "I could watch Netflix content exclusive to " + output + "."
  //   }

  // }

  function getColors(result) {
    switch (result) {
      case 'Success': return `green4`;
      case 'Fail': return `red4`;
      case 'No access': return `blue4`;
      default: return `indigo4`;
    }
  }

  function getName(aff) {
    switch (aff) {
      case "pia": return pia.vpnfamily
      case "ivacy": return ivacy.vpnfamily
      case "namecheap": return namecheap.vpnfamily
      case "vypr": return vypr.vpnfamily
      case "pure": return pure.vpnfamily
      case "nord": return nord.vpnfamily
      case "surfshark": return surfshark.vpnfamily
      case "cyberghost": return cyberghost.vpnfamily
      case "windscribe": return windscribe.vpnfamily
      case "torguard": return torguard.vpnfamily
      case "mullvad": return mullvad.vpnfamily
      case "express": return express.vpnfamily
      case "proton": return proton.vpnfamily
      default: return pia.vpnfamily
    }
  }

  function getAu(aff) {
    switch (aff) {
      case "pia": return pia.netflixau
      case "ivacy": return ivacy.netflixau
      case "namecheap": return namecheap.netflixau
      case "vypr": return vypr.netflixau
      case "pure": return pure.netflixau
      case "nord": return nord.netflixau
      case "surfshark": return surfshark.netflixau
      case "cyberghost": return cyberghost.netflixau
      case "windscribe": return windscribe.netflixau
      case "torguard": return torguard.netflixau
      case "mullvad": return mullvad.netflixau
      case "express": return express.netflixau
      case "proton": return proton.netflixau
      default: return pia.netflixau
    }
  }

  function getBr(aff) {
    switch (aff) {
      case "pia": return pia.netflixbr
      case "ivacy": return ivacy.netflixbr
      case "namecheap": return namecheap.netflixbr
      case "vypr": return vypr.netflixbr
      case "pure": return pure.netflixbr
      case "nord": return nord.netflixbr
      case "surfshark": return surfshark.netflixbr
      case "cyberghost": return cyberghost.netflixbr
      case "windscribe": return windscribe.netflixbr
      case "torguard": return torguard.netflixbr
      case "mullvad": return mullvad.netflixbr
      case "express": return express.netflixbr
      case "proton": return proton.netflixbr
      default: return pia.netflixbr
    }
  }

  function getCa(aff) {
    switch (aff) {
      case "pia": return pia.netflixca
      case "ivacy": return ivacy.netflixca
      case "namecheap": return namecheap.netflixca
      case "vypr": return vypr.netflixca
      case "pure": return pure.netflixca
      case "nord": return nord.netflixca
      case "surfshark": return surfshark.netflixca
      case "cyberghost": return cyberghost.netflixca
      case "windscribe": return windscribe.netflixca
      case "torguard": return torguard.netflixca
      case "mullvad": return mullvad.netflixca
      case "express": return express.netflixca
      case "proton": return proton.netflixca
      default: return pia.netflixca
    }
  }

  function getFr(aff) {
    switch (aff) {
      case "pia": return pia.netflixfr
      case "ivacy": return ivacy.netflixfr
      case "namecheap": return namecheap.netflixfr
      case "vypr": return vypr.netflixfr
      case "pure": return pure.netflixfr
      case "nord": return nord.netflixfr
      case "surfshark": return surfshark.netflixfr
      case "cyberghost": return cyberghost.netflixfr
      case "windscribe": return windscribe.netflixfr
      case "torguard": return torguard.netflixfr
      case "mullvad": return mullvad.netflixfr
      case "express": return express.netflixfr
      case "proton": return proton.netflixfr
      default: return pia.netflixfr
    }
  }
  
  function getDe(aff) {
    switch (aff) {
      case "pia": return pia.netflixde
      case "ivacy": return ivacy.netflixde
      case "namecheap": return namecheap.netflixde
      case "vypr": return vypr.netflixde
      case "pure": return pure.netflixde
      case "nord": return nord.netflixde
      case "surfshark": return surfshark.netflixde
      case "cyberghost": return cyberghost.netflixde
      case "windscribe": return windscribe.netflixde
      case "torguard": return torguard.netflixde
      case "mullvad": return mullvad.netflixde
      case "express": return express.netflixde
      case "proton": return proton.netflixde
      default: return pia.netflixde
    }
  }

  function getHk(aff) {
    switch (aff) {
      case "pia": return pia.netflixhk
      case "ivacy": return ivacy.netflixhk
      case "namecheap": return namecheap.netflixhk
      case "vypr": return vypr.netflixhk
      case "pure": return pure.netflixhk
      case "nord": return nord.netflixhk
      case "surfshark": return surfshark.netflixhk
      case "cyberghost": return cyberghost.netflixhk
      case "windscribe": return windscribe.netflixhk
      case "torguard": return torguard.netflixhk
      case "mullvad": return mullvad.netflixhk
      case "express": return express.netflixhk
      case "proton": return proton.netflixhk
      default: return pia.netflixhk
    }
  }

  function getIn(aff) {
    switch (aff) {
      case "pia": return pia.netflixin
      case "ivacy": return ivacy.netflixin
      case "namecheap": return namecheap.netflixin
      case "vypr": return vypr.netflixin
      case "pure": return pure.netflixin
      case "nord": return nord.netflixin
      case "surfshark": return surfshark.netflixin
      case "cyberghost": return cyberghost.netflixin
      case "windscribe": return windscribe.netflixin
      case "torguard": return torguard.netflixin
      case "mullvad": return mullvad.netflixin
      case "express": return express.netflixin
      case "proton": return proton.netflixin
      default: return pia.netflixin
    }
  }

  function getIl(aff) {
    switch (aff) {
      case "pia": return pia.netflixil
      case "ivacy": return ivacy.netflixil
      case "namecheap": return namecheap.netflixil
      case "vypr": return vypr.netflixil
      case "pure": return pure.netflixil
      case "nord": return nord.netflixil
      case "surfshark": return surfshark.netflixil
      case "cyberghost": return cyberghost.netflixil
      case "windscribe": return windscribe.netflixil
      case "torguard": return torguard.netflixil
      case "mullvad": return mullvad.netflixil
      case "express": return express.netflixil
      case "proton": return proton.netflixil
      default: return pia.netflixil
    }
  }

  function getJp(aff) {
    switch (aff) {
      case "pia": return pia.netflixjp
      case "ivacy": return ivacy.netflixjp
      case "namecheap": return namecheap.netflixjp
      case "vypr": return vypr.netflixjp
      case "pure": return pure.netflixjp
      case "nord": return nord.netflixjp
      case "surfshark": return surfshark.netflixjp
      case "cyberghost": return cyberghost.netflixjp
      case "windscribe": return windscribe.netflixjp
      case "torguard": return torguard.netflixjp
      case "mullvad": return mullvad.netflixjp
      case "express": return express.netflixjp
      case "proton": return proton.netflixjp
      default: return pia.netflixjp
    }
  }

  function getKr(aff) {
    switch (aff) {
      case "pia": return pia.netflixkr
      case "ivacy": return ivacy.netflixkr
      case "namecheap": return namecheap.netflixkr
      case "vypr": return vypr.netflixkr
      case "pure": return pure.netflixkr
      case "nord": return nord.netflixkr
      case "surfshark": return surfshark.netflixkr
      case "cyberghost": return cyberghost.netflixkr
      case "windscribe": return windscribe.netflixkr
      case "torguard": return torguard.netflixkr
      case "mullvad": return mullvad.netflixkr
      case "express": return express.netflixkr
      case "proton": return proton.netflixkr
      default: return pia.netflixkr
    }
  }

  function getMx(aff) {
    switch (aff) {
      case "pia": return pia.netflixmx
      case "ivacy": return ivacy.netflixmx
      case "namecheap": return namecheap.netflixmx
      case "vypr": return vypr.netflixmx
      case "pure": return pure.netflixmx
      case "nord": return nord.netflixmx
      case "surfshark": return surfshark.netflixmx
      case "cyberghost": return cyberghost.netflixmx
      case "windscribe": return windscribe.netflixmx
      case "torguard": return torguard.netflixmx
      case "mullvad": return mullvad.netflixmx
      case "express": return express.netflixmx
      case "proton": return proton.netflixmx
      default: return pia.netflixmx
    }
  }

  function getNl(aff) {
    switch (aff) {
      case "pia": return pia.netflixnl
      case "ivacy": return ivacy.netflixnl
      case "namecheap": return namecheap.netflixnl
      case "vypr": return vypr.netflixnl
      case "pure": return pure.netflixnl
      case "nord": return nord.netflixnl
      case "surfshark": return surfshark.netflixnl
      case "cyberghost": return cyberghost.netflixnl
      case "windscribe": return windscribe.netflixnl
      case "torguard": return torguard.netflixnl
      case "mullvad": return mullvad.netflixnl
      case "express": return express.netflixnl
      case "proton": return proton.netflixnl
      default: return pia.netflixnl
    }
  }

  function getSe(aff) {
    switch (aff) {
      case "pia": return pia.netflixse
      case "ivacy": return ivacy.netflixse
      case "namecheap": return namecheap.netflixse
      case "vypr": return vypr.netflixse
      case "pure": return pure.netflixse
      case "nord": return nord.netflixse
      case "surfshark": return surfshark.netflixse
      case "cyberghost": return cyberghost.netflixse
      case "windscribe": return windscribe.netflixse
      case "torguard": return torguard.netflixse
      case "mullvad": return mullvad.netflixse
      case "express": return express.netflixse
      case "proton": return proton.netflixse
      default: return pia.netflixse
    }
  }

  function getUk(aff) {
    switch (aff) {
      case "pia": return pia.netflixuk
      case "ivacy": return ivacy.netflixuk
      case "namecheap": return namecheap.netflixuk
      case "vypr": return vypr.netflixuk
      case "pure": return pure.netflixuk
      case "nord": return nord.netflixuk
      case "surfshark": return surfshark.netflixuk
      case "cyberghost": return cyberghost.netflixuk
      case "windscribe": return windscribe.netflixuk
      case "torguard": return torguard.netflixuk
      case "mullvad": return mullvad.netflixuk
      case "express": return express.netflixuk
      case "proton": return proton.netflixuk
      default: return pia.netflixuk
    }
  }

  function getUs(aff) {
    switch (aff) {
      case "pia": return pia.netflixus
      case "ivacy": return ivacy.netflixus
      case "namecheap": return namecheap.netflixus
      case "vypr": return vypr.netflixus
      case "pure": return pure.netflixus
      case "nord": return nord.netflixus
      case "surfshark": return surfshark.netflixus
      case "cyberghost": return cyberghost.netflixus
      case "windscribe": return windscribe.netflixus
      case "torguard": return torguard.netflixus
      case "mullvad": return mullvad.netflixus
      case "express": return express.netflixus
      case "proton": return proton.netflixus
      default: return pia.netflixus
    }
  }

  return (
    <>
      <section class="center br0 hidden ba b--gray2 mv4">
        <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Netflix Unblock Tests</h4>
        <div class="bt b--gray2">

          <div class="overflow-auto">
            <table class="f6 w-100 mw8 center" cellspacing="0">
              <thead>
                <tr>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">Country</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.first)}</th>
                  <th class="avenir fw6 bb b--gray2 tl ph3 pv4 white bg-primary4">{getName(props.second)}</th>
                </tr>
              </thead>
              <tbody class="lh-copy">
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Australia</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getAu(props.first))}`}>{getAu(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getAu(props.second))}`}>{getAu(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Brazil</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getBr(props.first))}`}>{getBr(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getBr(props.second))}`}>{getBr(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Canada</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getCa(props.first))}`}>{getCa(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getCa(props.second))}`}>{getCa(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">France</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getFr(props.first))}`}>{getFr(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getFr(props.second))}`}>{getFr(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Germany</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getDe(props.first))}`}>{getDe(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getDe(props.second))}`}>{getDe(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Hong Kong</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getHk(props.first))}`}>{getHk(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getHk(props.second))}`}>{getHk(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">India</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getIn(props.first))}`}>{getIn(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getIn(props.second))}`}>{getIn(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Israel</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getIl(props.first))}`}>{getIl(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getIl(props.second))}`}>{getIl(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Japan</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getJp(props.first))}`}>{getJp(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getJp(props.second))}`}>{getJp(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Korea</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getKr(props.first))}`}>{getKr(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getKr(props.second))}`}>{getKr(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Mexico</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getMx(props.first))}`}>{getMx(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getMx(props.second))}`}>{getMx(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Netherlands</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNl(props.first))}`}>{getNl(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getNl(props.second))}`}>{getNl(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Sweden</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getSe(props.first))}`}>{getSe(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getSe(props.second))}`}>{getSe(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">United Kingdom</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getUk(props.first))}`}>{getUk(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getUk(props.second))}`}>{getUk(props.second)}</td>
                </tr>
                <tr class="">
                  <td class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">USA</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getUs(props.first))}`}>{getUs(props.first)}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(getUs(props.second))}`}>{getUs(props.second)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </section>
      <div>
        {props.children}
      </div>
    </>
  )
}

export default CompareNetflix