import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

const StrapiReviewPriceValueSummary = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: strapiReviews(strapiId: {eq: 1}) {
          price_value_summary
        }
        ivacy: strapiReviews(strapiId: {eq: 2}) {
          price_value_summary
        }
        namecheap: strapiReviews(strapiId: {eq: 3}) {
          price_value_summary
        }
        vypr: strapiReviews(strapiId: {eq: 4}) {
          price_value_summary
        }
        pure: strapiReviews(strapiId: {eq: 5}) {
          price_value_summary
        }
        nord: strapiReviews(strapiId: {eq: 6}) {
          price_value_summary
        }
        surfshark: strapiReviews(strapiId: {eq: 7}) {
          price_value_summary
        }
        cyberghost: strapiReviews(strapiId: {eq: 8}) {
          price_value_summary
        }
        windscribe: strapiReviews(strapiId: {eq: 9}) {
          price_value_summary
        }
        torguard: strapiReviews(strapiId: {eq: 10}) {
          price_value_summary
        }
        mullvad: strapiReviews(strapiId: {eq: 11}) {
          price_value_summary
        }
        express: strapiReviews(strapiId: {eq: 12}) {
          price_value_summary
        }
        proton: strapiReviews(strapiId: {eq: 13}) {
          price_value_summary
        }
        celo: strapiReviews(strapiId: {eq: 14}) {
          price_value_summary
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton
  const celo = data.celo

  function getElement(aff) {
    switch (aff) {
      case "pia": return pia.price_value_summary
      case "ivacy": return ivacy.price_value_summary
      case "namecheap": return namecheap.price_value_summary
      case "vypr": return vypr.price_value_summary
      case "pure": return pure.price_value_summary
      case "nord": return nord.price_value_summary
      case "surfshark": return surfshark.price_value_summary
      case "cyberghost": return cyberghost.price_value_summary
      case "windscribe": return windscribe.price_value_summary
      case "torguard": return torguard.price_value_summary
      case "mullvad": return mullvad.price_value_summary
      case "express": return express.price_value_summary
      case "proton": return proton.price_value_summary
      case "celo": return celo.price_value_summary
      default: return pia.price_value_summary
    }
  }

  return (
    <ReactMarkdown source={getElement(props.aff)} />
  )
}

export default StrapiReviewPriceValueSummary

