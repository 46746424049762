import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import ReactMarkdown from "react-markdown"

const StrapiComparisonVpn1Better = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        c01: strapiComparisons(strapiId: {eq: 1}) {
          vpn1_better
        }
        c02: strapiComparisons(strapiId: {eq: 2}) {
          vpn1_better
        }
        c03: strapiComparisons(strapiId: {eq: 3}) {
          vpn1_better
        }
        c04: strapiComparisons(strapiId: {eq: 4}) {
          vpn1_better
        }
        c05: strapiComparisons(strapiId: {eq: 5}) {
          vpn1_better
        }
        c06: strapiComparisons(strapiId: {eq: 6}) {
          vpn1_better
        }
        c07: strapiComparisons(strapiId: {eq: 7}) {
          vpn1_better
        }
        c08: strapiComparisons(strapiId: {eq: 8}) {
          vpn1_better
        }
        c09: strapiComparisons(strapiId: {eq: 9}) {
          vpn1_better
        }
        c10: strapiComparisons(strapiId: {eq: 10}) {
          vpn1_better
        }
        c11: strapiComparisons(strapiId: {eq: 11}) {
          vpn1_better
        }
        c12: strapiComparisons(strapiId: {eq: 12}) {
          vpn1_better
        }
        c13: strapiComparisons(strapiId: {eq: 13}) {
          vpn1_better
        }
        c14: strapiComparisons(strapiId: {eq: 14}) {
          vpn1_better
        }
        c15: strapiComparisons(strapiId: {eq: 15}) {
          vpn1_better
        }
        c16: strapiComparisons(strapiId: {eq: 16}) {
          vpn1_better
        }
      }
    `
  )

  const c01 = data.c01
  const c02 = data.c02
  const c03 = data.c03
  const c04 = data.c04
  const c05 = data.c05
  const c06 = data.c06
  const c07 = data.c07
  const c08 = data.c08
  const c09 = data.c09
  const c10 = data.c10
  const c11 = data.c11
  const c12 = data.c12
  const c13 = data.c13
  const c14 = data.c14
  const c15 = data.c15
  const c16 = data.c16

  function getElement(aff) {
    switch (aff) {
      case "c01": return c01.vpn1_better
      case "c02": return c02.vpn1_better
      case "c03": return c03.vpn1_better
      case "c04": return c04.vpn1_better
      case "c05": return c05.vpn1_better
      case "c06": return c06.vpn1_better
      case "c07": return c07.vpn1_better
      case "c08": return c08.vpn1_better
      case "c09": return c09.vpn1_better
      case "c10": return c10.vpn1_better
      case "c11": return c11.vpn1_better
      case "c12": return c12.vpn1_better
      case "c13": return c13.vpn1_better
      case "c14": return c14.vpn1_better
      case "c15": return c15.vpn1_better
      case "c16": return c16.vpn1_better
      default: return c01.vpn1_better
    }
  }

  return (
    <ReactMarkdown source={getElement(props.aff)} />
  )
}

export default StrapiComparisonVpn1Better
