import React from "react"

const ImageWrapper = (props) => {

  const number_of_images_in_gallery = props.no

  function get_width(score) {
    switch (score) {
      case (score === "3"): return (
        <>
          <div class="w-33 ph2 caption">
            {props.children}
          </div>
        </>
      );
      case (score === "2"): return (
        <>
          <div class="w-50 ph2 caption">
            {props.children}
          </div>
        </>
      );
      default: return (
        <>
          <div class="w-100 ph2 caption">
            {props.children}
          </div>
        </>
      );
    }
  }

  return (
    get_width(number_of_images_in_gallery)
  )

}

export default ImageWrapper