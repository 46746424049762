import React from 'react'
import { useStaticQuery, graphql } from "gatsby"
import ReviewClick from './reviewClick.js'
import AffiliateLink from './affiliateLink.js'
import Img from "gatsby-image"

const ReviewClickTorguard = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        vpn: googleSheetResultsRow(vpnid: {eq: 20}) {
          totalscore
          vpnfamily
          vpnaff
          slug
        }
        file(relativePath: { eq: "assets/torguard-thumb.png" }) {
          childImageSharp {
            fluid(maxWidth: 300, quality:90) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        }
      }
    `
  )

  const vpn = data.vpn

  function getGrade(score) {
    switch (true) {
      case (score >= 0.0 && score < 3.0): return `Poor`;
      case (score >= 3.0 && score < 5.0): return `Average`;
      case (score >= 5.0 && score < 6.5): return `Good`;
      case (score >= 6.0 && score < 8.0): return `Very Good`;
      case (score >= 8.0 && score < 9.0): return `Great`;
      case (score >= 9.0 && score < 10.0): return `Excellent`;
      default: return `Perfect`;
    }
  }

  function needSticky(sticky) {
    if (sticky) {
      return (
        <AffiliateLink type="sticky" affiliate={vpn.vpnaff}>
          Visit {vpn.vpnfamily}
        </AffiliateLink>
      )
    }
  }

  function needReviewLink(review) {
    if (review) {
      return (
        "otherPage"
      )
    }
  }

  function customText(text, score) {
    if (text) {
      return (
        text
      )
    } else {
      return (
        `${getGrade(score)} - ${score} / 10`
      )
    }
  }

  return (
    <>
      {needSticky(props.sticky)}

      <ReviewClick
        affiliate={vpn.vpnaff}
        themeName={vpn.vpnfamily}
        recommendation={customText(props.text, vpn.totalscore)}
        linkType={needReviewLink(props.review)}
        ourReviewSlug={vpn.slug}
      >

        <Img fluid={data.file.childImageSharp.fluid} alt={`${vpn.family} logo`} />

      </ReviewClick>
    </>
  )
}

export default ReviewClickTorguard