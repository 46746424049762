import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import AffiliateLink from './affiliateLink.js'

const AllNetflixResults = ( props ) => {

  const data = useStaticQuery(
    graphql`
      query {
        allGoogleSheetResultsRow(filter: {netflixau: {ne: "skip"}}, sort: {fields: netflixpercentage, order: DESC}) {
          nodes {
            netflixau
            netflixbr
            netflixca
            netflixde
            netflixfr
            netflixhk
            netflixil
            netflixin
            netflixjp
            netflixkr
            netflixmx
            netflixnl
            netflixse
            netflixuk
            netflixus
            netflixscore
            vpnfamily
            vpnaff
            vpnid
            netflixpercentage
          }
        }
      }
    `
  )

  const vpns = data.allGoogleSheetResultsRow.nodes

  function getColors(result) {
    switch (result) {
      case 'Success': return `green4`;
      case 'Fail': return `red4`;
      case 'No access': return `blue4`;
      default: return `indigo4`;
    }
  }

  return (
    <section class="center br0 hidden ba b--gray2 mv4">
      <h4 class="bg-primary8 br0 br--top primary0 mv0 pa3 f5 ttu tracked">Netflix Unblock Test - All Results</h4>
      <div class="bt b--gray2">

        <div class="overflow-auto">
          <table class="f6 w-100 mw8 center" cellspacing="0">
            <thead>
              <tr>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">VPN</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">Score</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">AUS</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">BRA</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">CAN</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">FRA</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">GER</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">HKG</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">IND</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">ISR</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">JAP</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">KOR</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">MEX</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">NED</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">SWE</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">UK</th>
                <th class="avenir fw6 bb b--gray2 tl ph3 pv4 bg-white">USA</th>
              </tr>
            </thead>
            <tbody class="lh-copy">

              {vpns.map((node) => (
                <tr class="stripe-primary0">
                  <td key={node.id} class="fw6 avenir ph3 pv4 bb b--gray2 black">
                    <AffiliateLink type="link" affiliate={node.vpnaff}>{node.vpnfamily}</AffiliateLink>
                  </td>
                  <td class={`fw7 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixscore)}`}>{node.netflixscore}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixau)}`}>{node.netflixau}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixbr)}`}>{node.netflixbr}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixca)}`}>{node.netflixca}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixfr)}`}>{node.netflixfr}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixde)}`}>{node.netflixde}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixhk)}`}>{node.netflixhk}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixin)}`}>{node.netflixin}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixil)}`}>{node.netflixil}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixjp)}`}>{node.netflixjp}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixkr)}`}>{node.netflixkr}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixmx)}`}>{node.netflixmx}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixnl)}`}>{node.netflixnl}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixse)}`}>{node.netflixse}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixuk)}`}>{node.netflixuk}</td>
                  <td class={`fw5 avenir ph3 pv4 bb b--gray2 ${getColors(node.netflixus)}`}>{node.netflixus}</td>
                </tr>
              ))}

            </tbody>
          </table>
        </div>


      </div>
    </section>
  )
}

export default AllNetflixResults