import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Navbar from "./navbar"

const Header = ({ siteTitle }) => {
  return (
    <div className="flex items-center justify-between border-box pa3 ph5-ns">
      <div class="pa2 v-mid">
        <Link to="/" className="f4 fw5 f3-l avenir mt0 lh-title primary5 no-underline hover-primary7" title="Home">
          {siteTitle}
        </Link>
      </div>
      <Navbar />
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: '',
}

export default Header