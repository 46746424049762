import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import SummarySnippet from "./summarySnippet.js"

const GetTestDates = (props) => {

  const data = useStaticQuery(
    graphql`
      query {
        pia: googleSheetResultsRow(vpnid: {eq: 1}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        ivacy: googleSheetResultsRow(vpnid: {eq: 3}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        namecheap: googleSheetResultsRow(vpnid: {eq: 4}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        vypr: googleSheetResultsRow(vpnid: {eq: 5}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        pure: googleSheetResultsRow(vpnid: {eq: 8}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        nord: googleSheetResultsRow(vpnid: {eq: 10}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        surfshark: googleSheetResultsRow(vpnid: {eq: 14}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        cyberghost: googleSheetResultsRow(vpnid: {eq: 17}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        windscribe: googleSheetResultsRow(vpnid: {eq: 18}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        torguard: googleSheetResultsRow(vpnid: {eq: 20}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        mullvad: googleSheetResultsRow(vpnid: {eq: 22}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        express: googleSheetResultsRow(vpnid: {eq: 24}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        proton: googleSheetResultsRow(vpnid: {eq: 27}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
        celo: googleSheetResultsRow(vpnid: {eq: 28}) {
          vpnname
          vpnfamily
          vpnaff
          enddate
          startdate
          notests
          torrenttests
        }
      }
    `
  )

  const pia = data.pia
  const ivacy = data.ivacy
  const namecheap = data.namecheap
  const vypr = data.vypr
  const pure = data.pure
  const nord = data.nord
  const surfshark = data.surfshark
  const cyberghost = data.cyberghost
  const windscribe = data.windscribe
  const torguard = data.torguard
  const mullvad = data.mullvad
  const express = data.express
  const proton = data.proton
  const celo = data.celo

  function getTestDates(aff) {
    switch (aff) {
      case "pia": return (
        <>
          <p>This review of {pia.vpnfamily} contains the results of tests between {pia.startdate} and {pia.enddate}.</p>
          <p><em>This document is continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      case "ivacy": return (
        <>
          <p>{ivacy.vpnfamily} was comprehensively reviewed from {ivacy.startdate} to {ivacy.enddate}, test results include {ivacy.notests} speed and {ivacy.torrenttests} torrent tests.</p>
          <p><em>This review is continuously updated with new data each month.</em></p>
        </>
      )
      case "namecheap": return (
        <>
          <p>{namecheap.vpnfamily} was reviewed between {namecheap.startdate} and {namecheap.enddate}, with {namecheap.notests} speed and {namecheap.torrenttests} torrent tests.</p>
          <p><em>Our reviews are refreshed with new test data each month, to keep the results up to date and relevant.</em></p>
        </>
      )
      case "vypr": return (
        <>
          <p>This review is based off reviewing {vypr.vpnfamily} between {vypr.startdate} and {vypr.enddate}, including {vypr.notests} bandwidth and download tests and {vypr.torrenttests} torrent tests of each protocol.</p>
          <p><em>This review is updated every month with new data to keep the results current and consistent.</em></p>
        </>
      )
      case "pure": return (
        <>
          <p>Our {pure.vpnfamily} review is based on the results of tests between {pure.startdate} and {pure.enddate}.</p>
          <p><em>Our reviews are updated with new data every month to keep them consistently up-to-date.</em></p>
        </>
      )
      case "nord": return (
        <>
          <p>{nord.vpnfamily} was thoroughly reviewed from {nord.startdate} to {nord.enddate}. Our review includes test results such as {nord.notests} speed and {nord.torrenttests} torrent tests.</p>
          <p><em>This document is continuously being updated with data each month in order to give you an accurate review.</em></p>
        </>
      )
      case "surfshark": return (
        <>
          <p>This review of {surfshark.vpnfamily} contains the results of thorough testing between {surfshark.startdate} and {surfshark.enddate}.</p>
          <p><em>To ensure accuracy, this document is continuously being updated with monthly updated test data.</em></p>
        </>
      )
      case "cyberghost": return (
        <>
          <p>This review of {cyberghost.vpnfamily} contains the results of tests between {cyberghost.startdate} and {cyberghost.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      case "windscribe": return (
        <>
          <p>This review of {windscribe.vpnfamily} contains the results of tests between {windscribe.startdate} and {windscribe.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      case "torguard": return (
        <>
          <p>This review of {torguard.vpnfamily} contains the results of tests between {torguard.startdate} and {torguard.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      case "mullvad": return (
        <>
          <p>This review of {mullvad.vpnfamily} contains the results of tests between {mullvad.startdate} and {mullvad.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      case "express": return (
        <>
          <p>This review of {express.vpnfamily} contains the results of tests between {express.startdate} and {express.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      case "proton": return (
        <>
          <p>This review of {proton.vpnfamily} contains the results of tests between {proton.startdate} and {proton.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      case "celo": return (
        <>
          <p>This review of {celo.vpnfamily} contains the results of tests between {celo.startdate} and {celo.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
      default: return (
        <>
          <p>This review of {pia.vpnfamily} contains the results of tests between {pia.startdate} and {pia.enddate}.</p>
          <p><em>This document is a continuously being updated with fresh data each month in order to be accurate and consistent.</em></p>
        </>
      )
    }
  }

  return (
    <>
      <SummarySnippet>
        {getTestDates(props.aff)}
      </SummarySnippet>
    </>
  )
}

export default GetTestDates